import { Injectable } from '@angular/core';
import { Conversion, DowncastWriter, Element, toWidget, ViewContainerElement } from 'ckeditor5';
import { ClausesPlugin } from '../../plugins/clause/clauses-plugin';
import { DataViewModel } from '../../models/base/data-view-model';

@Injectable({
    providedIn: 'root'
})
export class ClauseModelToEditorViewConverterService {

    public configureConverters(conversion: Conversion): void {
        conversion.for("editingDowncast").elementToElement({
            model: ClausesPlugin.MODEL_ENTITIES.class.model,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const editorViewModel = this.createEditorViewModel(modelItem);
                const widgetElement = this.createView(editorViewModel, viewWriter);
                return toWidget(widgetElement, viewWriter);
            },
        });
    }

    private createEditorViewModel(modelItem: Element): DataViewModel {
        return {
            id: modelItem.getAttribute(ClausesPlugin.ID) as string,
            class: ClausesPlugin.MODEL_ENTITIES.class.editonView,
            container: ClausesPlugin.DIV
        };
    }

    private createView(dataViewModel: DataViewModel, viewWriter: DowncastWriter): ViewContainerElement {
        return viewWriter.createContainerElement(dataViewModel.container,
            {
                id: dataViewModel.id,
                class: dataViewModel.class
            });
    }
}
