import { Injectable } from "@angular/core";
import { Schema } from "ckeditor5";
import { ClausesPlugin } from "../../plugins/clause/clauses-plugin";
import { ALLOW_CONTENT_OF_VALUES, INHERIT_ALL_FROM_VALUES } from "../schema-values";

@Injectable({
    providedIn: 'root'
})
export class ClauseSchemaService {

    public defineSchema(schema: Schema): void {
        const { ID, DESCRIPTION } = ClausesPlugin;

        schema.register(ClausesPlugin.MODEL_ENTITIES.class.model, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.BLOCK_OBJECT,
            allowContentOf: ALLOW_CONTENT_OF_VALUES.ROOT,
            allowAttributes: [ID, DESCRIPTION]
        });
    }
}
