import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, ViewChild, EventEmitter, HostBinding, Input, Output, Inject, OnDestroy } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import {
    ChangeDocumentClauseDTO, DocumentDTO, DocumentService, DocumentSignatureDTO, ClauseDTO,
    DocumentSignatureService, DocumentStatusService, ExpirationDateDTO, MasterData, DocumentClauseDTO, DocumentClauseService
} from 'src/app/api';
import { DocumentTypeCodes } from 'src/app/core/shared/enums/document-type-codes.enum';
import { DocumentStatusCodes } from 'src/app/core/standard/enums/documents/document-status.enum';
import { TypeUserService } from 'src/app/core/shared/services/type-user/type-user.service';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClauseDocumentRevisionPreviewComponent } from '../clause-document-revision-preview/clause-document-revision-preview.component';
import { ClauseDocumentRevisionSendToSignModalComponent } from '../clause-document-revision-send-to-sign-modal/clause-document-revision-send-to-sign-modal.component';
import { ClauseDocumentWarningChangesComponent } from '../clause-document-warning-changes/clause-document-warning-changes.component';
import { DocumentClauseRevisionSendToFirmComponent } from 'src/app/shared/components/document-clause-revision-send-to-firm/document-clause-revision-send-to-firm.component';
import { NotificationState } from 'src/app/shared/components/ctbox-notification/enums/notification-state.enum';
import { IClauseDocumentRevisionService } from 'src/app/core/shared/services/clauses/clause-document-revision/clause-document-revision.service.interface';
import { IClausesPreviewService } from 'src/app/core/shared/services/clauses/clauses-preview/clauses-preview.service.interface';
import { IClausesWarningService } from 'src/app/core/shared/services/clauses/clauses-warning/clauses-warning.service.interface';

@Component({
    selector: 'app-clauses-document-revision-list',
    templateUrl: './clauses-document-revision-list.component.html',
    styleUrls: ['./clauses-document-revision-list.component.scss']
})
export class ClausesDocumentRevisionListComponent implements OnInit, OnDestroy {

    @Input() clause: ClauseDTO;
    @Input() clauseId: string;
    @Input() keepDocuments: boolean;

    @Output() previewClosed = new EventEmitter<boolean>(false);

    public closeIfFinished = new BehaviorSubject<boolean>(false);

    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.sort = ms;
        this.setDataSourceAttributes();
    }

    @HostBinding('class') componentClass: string;

    public get documentTypeCodes(): typeof DocumentTypeCodes {
        return DocumentTypeCodes;
    }
    public get notificationState(): typeof NotificationState {
        return NotificationState;
    }

    public displayedColumns: string[] = ['select', 'icon', 'name', 'modificationTimeStamp', 'lastModificationUser', 'documentStatus', 'actionsColumn'];
    public dataSource: MatTableDataSource<DocumentClauseDTO>;

    public initialSelection = [];
    public allowMultiSelect = true;
    public selection: SelectionModel<DocumentClauseDTO>;

    public DEFAULT_EXPIRATION_DAYS = 30;
    public pendingDocuments = 0;
    public totalDocuments = 0;
    public expirationDays: number;
    public expirationDate: Date;
    public newExpirationDate: Date;
    public showSendToSign = false;
    public numDocumentsSendToSign = 0;
    public numDocumentsNotReadyToSign = 0;
    public numDocumentsReadyToSign = 0;
    public documentStatuses: MasterData[];
    public havePendingDocuments: boolean;

    public isSecondaryButtonSubscription = new BehaviorSubject<boolean>(true);

    public forbidEditMessage = $localize`:@@PlantillaDocumentoNoTienesPermisoRevisionMensaje:No tienes permisos de edición en este documento`;

    public sort: MatSort;

    private currentStateSubscription: Subscription;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public typeUserService: TypeUserService,
                public genericDialogService: GenericDialogService,
                public clauseWarningService: IClausesWarningService,
                private documentsService: DocumentClauseService,
                private clausesPreviewService: IClausesPreviewService,
                private clauseDocumentRevisionService: IClauseDocumentRevisionService,
                private documentService: DocumentService,
                private documentSignatureService: DocumentSignatureService,
                private inputRoute: ActivatedRoute,
                private documentStatusService: DocumentStatusService) {
        this.selection = new SelectionModel<DocumentClauseDTO>(this.allowMultiSelect, this.initialSelection);
        this.clause = data.dataSourceComponent;
        this.clauseId = this.clause.id;
    }

    public ngOnInit(): void {
        this.componentClass = 'generic-dialog-content-inherit';
        if (!this.clauseId) {
            this.clauseId = this.inputRoute.snapshot.paramMap.get('clauseId');
        }

        if (!this.keepDocuments) {
            this.keepDocuments = this.inputRoute.snapshot.paramMap.get('keepDocuments') !== null;
        }
        this.totalDocuments = this.clauseDocumentRevisionService.getCurrentStateDocuments().size;

        this.subscribeToStateDocumentChange();
        if (this.keepDocuments && this.totalDocuments > 0) {
            this.loadCBDocuments(this.clauseId);
        } else {
            this.loadPendingCBDocuments(this.clauseId);
        }

        this.documentStatusService.masterdataManagementDocumentStatusGet().subscribe((result: MasterData[]) => {
            this.documentStatuses = result;
        });

        this.havePendingDocuments = this.pendingDocuments > 0;
    }

    ngOnDestroy(): void {
        if (this.currentStateSubscription) {
            this.currentStateSubscription.unsubscribe();
        }
    }

    public setDataSourceAttributes() {
        if (this.dataSource === undefined) {
            return;
        }

        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            return this.clausesPreviewService.orderBy(data, sortHeaderId);
        };
    }

    public loadCBDocuments(clauseId: string): void {
        this.pendingDocuments = this.clauseDocumentRevisionService.getNumPendingDocuments();
        this.totalDocuments = this.clauseDocumentRevisionService.getCurrentStateDocuments().size;

        this.documentsService.documentClausesSearchClauseClauseIdGet(clauseId).subscribe(values => {
            const valuesInStateDocuments: DocumentClauseDTO[] = [];
            const currentDocuments = this.clauseDocumentRevisionService.getCurrentStateDocuments();
            const idDocuments = Array.from(currentDocuments.keys());

            values.forEach(element => {
                if (idDocuments.find(id => id === element.id) !== undefined) {
                    valuesInStateDocuments.push(element);
                }
                this.dataSource = new MatTableDataSource<DocumentClauseDTO>(valuesInStateDocuments);
            });

            this.setDataSourceAttributes();
            this.closeIfFinishes();
        });
    }

    public loadPendingCBDocuments(clauseId: string): void {
        this.documentsService.documentClausesSearchPendingClauseClauseIdGet(clauseId).subscribe((values: DocumentClauseDTO[]) => {
            this.dataSource = new MatTableDataSource<DocumentClauseDTO>(values);

            const stateDocument = new Map<string, string>();
            this.clauseDocumentRevisionService.UpdateStateDocument(stateDocument);
            values.forEach(element => {
                this.clauseDocumentRevisionService.setPending(element);
            });
            this.setDataSourceAttributes();
        });
    }

    public subscribeToStateDocumentChange() {
        this.currentStateSubscription = this.clauseDocumentRevisionService.getCurrentStateDocumentsSubscription().subscribe(() => {
            this.pendingDocuments = this.clauseDocumentRevisionService.getNumPendingDocuments();
            this.totalDocuments = this.clauseDocumentRevisionService.getCurrentStateDocuments().size;
            this.havePendingDocuments = this.pendingDocuments > 0;
        });
    }

    public acceptChanges(documentClause: DocumentClauseDTO): void {
        if (this.canAcceptChange(documentClause)) {
            this.showSendToSign = false;
            let forSignaturePreviously = false;
            if (documentClause.document?.documentTypes?.code !== DocumentTypeCodes.Template) {
                const documentState = documentClause.documentStatuses;
                forSignaturePreviously = documentClause.isTheDocumentPendingSendToSign;
                if (documentState?.code === DocumentStatusCodes.ForSignature) {
                    const message = $localize`:@@ConfirmacionCambioMensajeCancelarFirma:Este documento está enviado a firma, para proceder a cambiar la cláusula se cancelará el envío a firma. ¿Estás seguro?`;
                    const dialogButton = $localize`:@@CancelarFirmaYAceptarCambios:Cancelar firma y aplicar cambios`;
                    this.genericDialogService.showQuestion(message, '', '', dialogButton).afterClosed().subscribe((result) => {
                        if (result === true) {
                            forSignaturePreviously = true;
                            this.resolveAcceptChanges(documentClause, forSignaturePreviously);
                        }
                    });
                    return;
                }
                this.resolveAcceptChanges(documentClause, forSignaturePreviously);
                return;
            }
            this.resolveAcceptChanges(documentClause, forSignaturePreviously);
        }
    }

    public resolveAcceptChanges(document: DocumentClauseDTO, forSignaturePreviously: boolean) {
        const changeClause: ChangeDocumentClauseDTO = {
            clauseId: document.clause.id,
            documentId: document.document.id,
            acceptChanges: true
        };

        this.documentsService.documentClausesResolveChangesPut(changeClause).subscribe(() => {
            this.clauseDocumentRevisionService.setApplied(document);
            if (forSignaturePreviously && (document.document?.documentTypes?.code === DocumentTypeCodes.Annexed ||
                document.document?.documentTypes?.code === DocumentTypeCodes.Contract)) {
                this.UpdateListEntryToState(document.document, DocumentStatusCodes.Completed);
            }
            this.trySendToSign(document, forSignaturePreviously);
        });
    }

    public rejectChanges(documentClause: DocumentClauseDTO): void {
        this.showSendToSign = false;
        let forSignaturePreviously = false;
        if (documentClause.document?.documentTypes?.code !== DocumentTypeCodes.Template) {
            forSignaturePreviously = documentClause.isTheDocumentPendingSendToSign;
            this.resolveRejectChanges(documentClause, forSignaturePreviously);
            return;
        }
        this.resolveRejectChanges(documentClause, forSignaturePreviously);
    }

    public resolveRejectChanges(document: DocumentClauseDTO, forSignaturePreviously: boolean) {
        const changeClause: ChangeDocumentClauseDTO = {
            clauseId: this.clauseId,
            documentId: document.document.id,
            acceptChanges: false
        };

        this.documentsService.documentClausesResolveChangesPut(changeClause).subscribe(() => {
            this.clauseDocumentRevisionService.setKeep(document);
            this.trySendToSign(document, forSignaturePreviously);
        });
    }

    public trySendToSign(documentClause: DocumentClauseDTO, forSignaturePreviously: boolean) {
        if (documentClause.document?.documentTypes?.code === DocumentTypeCodes.Template || !forSignaturePreviously) {
            this.closeIfFinishes();
            return;
        }

        this.calculateShowSendToSign(documentClause, forSignaturePreviously).subscribe((documentClauses: DocumentClauseDTO[]) => {
            this.showSendToSign = documentClauses.length < 1;
            if (this.showSendToSign) {
                this.sendToSign(documentClause);
                return;
            }
            this.closeIfFinishes();
        });
    }

    public sendToSign(documentClause: DocumentClauseDTO) {
        this.documentSignatureService.documentsSignatureExpirationDateDocumentIdGet(documentClause.document.id)
            .subscribe((expirationDate: ExpirationDateDTO) => {
                this.expirationDays = expirationDate.days;
                this.expirationDate = expirationDate.date;
                this.OpenModalExpirationDate(documentClause);
            });
    }

    public OpenModalExpirationDate(documentClause: DocumentClauseDTO) {
        const dataSource: any = {
            expirationDays: this.expirationDays,
            expirationDate: this.expirationDate,
        };
        const dialogButton = $localize`:@@ShareAgain:Volver a enviar a firma`;
        this.genericDialogService.showQuestion('', DocumentClauseRevisionSendToFirmComponent, dataSource, dialogButton).afterClosed()
            .subscribe((result) => {
                if (result === true) {
                    const nuevaFecha = new Date();
                    this.theNewExpirationDate(nuevaFecha);
                    const documentSignature: DocumentSignatureDTO = {
                        documentId: documentClause.document.id,
                        expiredDate: this.newExpirationDate
                    };
                    this.documentService.documentSendToSignSamePeoplePut(documentSignature).subscribe((document: DocumentDTO) => {
                        this.UpdateListEntryToState(document, DocumentStatusCodes.ForSignature);
                        const message = $localize`:@@FirmaEnviadaCorrectamenteRevisionDeClausulas:El envío a firma se ha realizado correctamente para el documento "` + document.documentSignature.name + '"';
                        this.genericDialogService.showMessage(message);
                        this.showSendToSign = false;
                    }, error => {
                        this.showSendToSign = false;
                        const document = 'Document';
                        if (error.error[document] && error.error[document].length > 0) {
                            const errorType = error.error[document][0];
                            if (errorType === 'Document is not finished') {
                                const messagefinished = $localize`:@@DocumentoNoFinalizadoClausulaDocumentoMensaje:Algunos campos de edición no están completos. Debes completar estos campos antes de enviar a firma desde la pantalla de edición del documento`;
                                this.genericDialogService.showMessage(messagefinished);
                                return;
                            }
                        }
                        const message = $localize`:@@ClausulaDocumentoFirmaErrorMensaje:Se ha producido un error en el envío a firma. Puedes completar la firma desde la vista de edición del documento`;
                        this.genericDialogService.showMessage(message);
                    });
                } else {
                    this.documentService.documentCancelForSignaturePreviouslyIdPut(documentClause.document.id).subscribe(() => {
                        this.closeIfFinishes();
                    }, () => {
                        const message = $localize`:@@ClausulaDocumentoErrorMensaje:Se ha producido un error`;
                        this.genericDialogService.showMessage(message);
                    });
                }
            });
    }

    public theNewExpirationDate(date: Date) {
        this.newExpirationDate = date;
    }

    public calculateShowSendToSign(documentClause: DocumentClauseDTO, forSignaturePreviously: boolean): Observable<DocumentClauseDTO[]> {
        if (documentClause.document?.documentTypes?.code === DocumentTypeCodes.Template || !forSignaturePreviously) {
            this.showSendToSign = false;
        }

        return this.documentsService.documentClausesSearchPendingDocumentDocumentIdGet(documentClause.document.id);
    }

    public loadDocumentState(documentId: string): Observable<DocumentDTO> {
        return this.documentService.documentIdGet(documentId);
    }

    public isPending(document: DocumentClauseDTO): boolean {
        return this.clauseDocumentRevisionService.isPending(document);
    }

    public isApplied(document: DocumentClauseDTO): boolean {
        return this.clauseDocumentRevisionService.isApplied(document);
    }

    public isKeep(document: DocumentClauseDTO): boolean {
        return this.clauseDocumentRevisionService.isKeep(document);
    }

    public goToPreviewChanges(document: DocumentClauseDTO): void {
        const config = this.genericDialogService.getBigDialogConfig();
        const data: any = {
            template: ClauseDocumentRevisionPreviewComponent,
            dialogTitle: $localize`:@@ClausulaDocumentoTitulo:Revisa los cambios por modificación de la cláusula origen`,
            dataSourceComponent: document,
            secondaryButtonContentObservableName: 'isReviewDisabledSubscription',
            displayCloseOption: true,
        };

        this.genericDialogService.openTemplateWithConfigAndData(data.template, config, data).afterClosed().subscribe((result) => {
            if (result === true) {
                this.closeIfFinishes();
            }
        });
    }

    public closeIfFinishes(): void {
        this.isSecondaryButtonSubscription.next(this.havePendingDocuments);

        if (this.havePendingDocuments) {
            return;
        }

        this.closeIfFinished.next(true);
    }

    public isReviewDisabled(clauseDocument: DocumentClauseDTO): boolean {
        return !clauseDocument?.document?.userPermissions || !clauseDocument?.document?.userPermissions?.canAcceptOrRejectClauseChanges;
    }

    public canAcceptChange(element: DocumentClauseDTO): boolean {
        return !this.isReviewDisabled(element) && this.isPending(element);
    }

    public isCheckHeaderChecked(): boolean {
        return this.selection.hasValue() && this.isAllSelected();
    }

    public isCheckHeaderIndeterminate(): boolean {
        return this.selection.hasValue() && !this.isAllSelected();
    }

    public isRowChecked(element: DocumentClauseDTO): boolean {
        return this.selection.isSelected(element);
    }

    public rowSelectedChange($event, element: DocumentClauseDTO) {
        if (!$event) {
            return;
        }

        this.selection.toggle(element);
    }

    public rowHeaderSelectedChange($event) {
        if (!$event) {
            return;
        }

        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }

        this.dataSource.data.forEach(element => {
            if (!this.canAcceptChange(element)) {
                return;
            }
            this.selection.select(element);
        });
    }

    public getDocumentClauseCanAcceptClauseChanges(): number {
        let numAcceptChanges = 0;

        this.dataSource.data.forEach(documentClause => {
            if (!this.canAcceptChange(documentClause)) {
                return;
            }
            numAcceptChanges++;
        });

        return numAcceptChanges;
    }

    public isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.getDocumentClauseCanAcceptClauseChanges();
        return numSelected === numRows;
    }

    public isAnySelected(): boolean {
        const selected = this.selection.selected.length > 0;
        return selected;
    }

    public openConfirmationMultipleUpdates() {
        const dataSource: any = {
            nDocsToUpdate: this.selection.selected.length,
            isAnySendToSign: this.isAnySendToSign(this.selection.selected)
        };
        const dialogButton = $localize`:@@AplicarCambiosClausulaDocumento:Aplicar cambios`;

        this.genericDialogService.showQuestion('', ClauseDocumentWarningChangesComponent, dataSource, dialogButton).afterClosed()
            .subscribe((result) => {
                if (result === true) {
                    const documentsToAccept = this.selection.selected;
                    this.resolveAcceptAllSelected(documentsToAccept);
                } else {
                    return;

                }
            });
    }

    public rejectAllSelectedChanges() {
        const documentsToReject = this.selection.selected;
        this.resolveRejectAllSelected(documentsToReject);
    }

    public resolveAcceptAllSelected(documentsToAccept: DocumentClauseDTO[]) {
        const changeClauses: ChangeDocumentClauseDTO[] = [];
        documentsToAccept.forEach((documentClause) => {
            const changeClause: ChangeDocumentClauseDTO = {
                clauseId: documentClause.clause.id,
                documentId: documentClause.document.id,
                acceptChanges: true
            };
            changeClauses.push(changeClause);
        });

        const acceptChanges = true;

        this.resolveAllSelectedChanges(documentsToAccept, changeClauses, acceptChanges);
    }

    public resolveRejectAllSelected(documentsToAccept: DocumentClauseDTO[]) {
        const changeClauses: ChangeDocumentClauseDTO[] = [];
        documentsToAccept.forEach((documentClause) => {
            const changeClause: ChangeDocumentClauseDTO = {
                clauseId: documentClause.clause.id,
                documentId: documentClause.document.id,
                acceptChanges: false
            };
            changeClauses.push(changeClause);
        });

        const acceptChanges = false;
        this.resolveAllSelectedChanges(documentsToAccept, changeClauses, acceptChanges);
    }

    public resolveAllSelectedChanges(documentsToAccept: DocumentClauseDTO[], changeClauses: ChangeDocumentClauseDTO[],
                                     acceptChanges: boolean) {
        const documentsForSignature: DocumentClauseDTO[] = [];
        this.documentsService.documentClausesResolveMultiplechangesPut(changeClauses).subscribe(() => {
            documentsToAccept.forEach(documentClause => {
                if (acceptChanges) {
                    this.clauseDocumentRevisionService.setApplied(documentClause);
                    if (documentClause.documentStatuses.code === DocumentStatusCodes.ForSignature &&
                        (documentClause.document?.documentTypes?.code === DocumentTypeCodes.Annexed ||
                            documentClause.document?.documentTypes?.code === DocumentTypeCodes.Contract)) {
                        this.UpdateListEntryToState(documentClause.document, DocumentStatusCodes.Completed);
                        if (!documentClause.isTheDocumentPendingSendToSign) {
                            documentsForSignature.push(documentClause);
                        }
                    }
                } else {
                    this.clauseDocumentRevisionService.setKeep(documentClause);
                }
            });
            this.selection.clear();
            this.trySendToSignDocuments(documentsToAccept, documentsForSignature).then(() => {
                this.closeIfFinishes();
            });
        });
    }

    public isAnySendToSign(documentClausesSelected: DocumentClauseDTO[]): boolean {
        return documentClausesSelected.find((documentClause) =>
            documentClause.documentStatuses?.code === DocumentStatusCodes.ForSignature) !== undefined;
    }

    public isAnyPendingSendToSign(documentClausesSelected: DocumentClauseDTO[]): boolean {
        return documentClausesSelected.find((documentClause) => documentClause.documentStatuses?.code
            === DocumentStatusCodes.ForSignature || documentClause.isTheDocumentPendingSendToSign) !== undefined;
    }

    public trySendToSignDocuments(documentsSelected: DocumentClauseDTO[], documentsForSignature: DocumentClauseDTO[]): Promise<void> {
        const promise = new Promise<void>((resolve) => {
            if (!this.isAnyPendingSendToSign(documentsSelected) && !(documentsForSignature.length > 0)) {
                resolve();
                return;
            }

            const pendingSendToSign = documentsSelected.filter(documentClause =>
                documentClause.isTheDocumentPendingSendToSign).concat(documentsForSignature);

            const idsDocuments: string[] = pendingSendToSign.map<string>(documentClause => documentClause.document.id);
            this.documentsService.documentClausesSearchPendingDocumentIdsPost(idsDocuments)
                .subscribe((documentsWithAnotherClausepending: DocumentClauseDTO[]) => {
                    const documentsReadyToSign = pendingSendToSign.filter(dc => documentsWithAnotherClausepending
                        .find(dcWithAnotherClause => dcWithAnotherClause.document.id === dc.document.id) === undefined);

                    this.numDocumentsNotReadyToSign = documentsWithAnotherClausepending.length;
                    this.numDocumentsReadyToSign = documentsReadyToSign.length;
                    this.numDocumentsSendToSign = pendingSendToSign.length;

                    this.expirationDays = this.DEFAULT_EXPIRATION_DAYS;
                    this.expirationDate = new Date();
                    this.expirationDate.setDate(Number(this.expirationDays) + this.expirationDate.getDate());

                    const dataSource: any = {
                        numDocumentsSendToSign: this.numDocumentsSendToSign,
                        numDocumentsNotReadyToSign: this.numDocumentsNotReadyToSign,
                        numDocumentsReadyToSign: this.numDocumentsReadyToSign,
                        expirationDays: this.expirationDays,
                        expirationDate: this.expirationDate
                    };
                    const dialogButton = $localize`:@@ShareAgain:Volver a enviar a firma`;

                    this.genericDialogService.showQuestion('', ClauseDocumentRevisionSendToSignModalComponent, dataSource, dialogButton)
                        .afterClosed().subscribe((result) => {
                            const idDocumentstoSendToSamePeople = documentsReadyToSign.map<string>(dc => dc.document.id);
                            if (result === true) {
                                this.documentService.documentSendToSignSamePeopleDocumentsPut(idDocumentstoSendToSamePeople)
                                    .subscribe(() => {
                                        documentsReadyToSign.forEach(documento => {
                                            this.UpdateListEntryToState(documento.document, DocumentStatusCodes.ForSignature);
                                        });
                                        const message = $localize`:@@FirmaEnviadaCorrectamenteDocumentos:El envío a firma se ha realizado correctamente para los documentos`;
                                        this.genericDialogService.showMessage(message).afterClosed().subscribe(() => {
                                            resolve();
                                        });

                                    }, error => {
                                        this.showSendToSign = false;
                                        const document = 'Document';
                                        if (error.error[document] && error.error[document].length > 0) {
                                            const errorType = error.error[document][0];
                                            if (errorType === 'Document is not finished') {
                                                const messageFinished = $localize`:@@DocumentoNoFinalizadoClausulaDocumentoMensaje:Algunos campos de edición no están completos. Debes completar estos campos antes de enviar a firma desde la pantalla de edición del documento`;
                                                this.genericDialogService.showMessage(messageFinished).afterClosed().subscribe(() => {
                                                    resolve();
                                                });
                                                return;
                                            }
                                        }
                                        const message = $localize`:@@ClausulaDocumentoFirmaErrorMensaje:Se ha producido un error en el envío a firma. Puedes completar la firma desde la vista de edición del documento`;
                                        this.genericDialogService.showMessage(message).afterClosed().subscribe(() => {
                                            resolve();
                                        });
                                    });
                            } else {
                                this.documentService.documentCancelForSignaturePreviouslyMultiplePut(idDocumentstoSendToSamePeople)
                                    .subscribe(() => {
                                        const message = $localize`:@@FirmaCancelarReenvioCorrecto:La operación se ha realizado correctamente`;
                                        this.genericDialogService.showMessage(message).afterClosed().subscribe(() => {
                                            resolve();
                                        });

                                    }, () => {
                                        const message = $localize`:@@ClausulaDocumentoFirmaErrorMensaje:Se ha producido un error en el envío a firma. Puedes completar la firma desde la vista de edición del documento`;
                                        this.genericDialogService.showMessage(message).afterClosed().subscribe(() => {
                                            resolve();
                                        });
                                    });
                            }
                        });
                });
        });

        return promise;
    }

    private UpdateListEntryToState(document: DocumentDTO, state: DocumentStatusCodes) {
        const data = this.dataSource.data;
        const documentUpdate = data.find(ds => ds.document.id === document.id);
        if (documentUpdate !== undefined) {
            documentUpdate.documentStatuses = this.documentStatuses.find(ds => ds.code === state);
            this.dataSource.data = data;
            this.setDataSourceAttributes();
        }
    }
}
