import {
    Component, ComponentRef, EventEmitter, HostBinding, Inject, Input, OnDestroy, OnInit, Output,
    TemplateRef, ViewChild, ViewContainerRef
} from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs-compat';

export enum dialogTypes {
    Info = 'info',
    Success = 'success',
    Confirm = 'confirm',
    Error = 'error',
    Warning = 'warning',
    NotOverflow = 'not-overflow',
    Tree = 'tree',
    Border = 'border',
}

@Component({
    selector: 'app-ctbox-generic-dialog-data',
    templateUrl: './ctbox-generic-dialog-data.component.html'
})
export class CtboxGenericDialogDataComponent implements OnInit, OnDestroy {

    @ViewChild('dialogcontent', { static: true }) template: TemplateRef<any>;
    @ViewChild('dialogbody', { read: ViewContainerRef, static: true }) viewContainerRef: ViewContainerRef;

    @Input() public dataSource: any;
    @Input() public dialogBody: string;
    @Input() public dialogButton: string;
    @Input() public dialogContentBorder: boolean;
    @Input() public dialogContentGetResultPropertyName: string;
    @Input() public dialogContentGetCloseResultPropertyName: string;
    @Input() public dialogCloseButon: string;
    @Input() public dialogMessage: string;
    @Input() public dialogTitle: string;
    @Input() public dialogTitleClass: string;
    @Input() public dialogTypes = dialogTypes.Info;
    @Input() public displayButtonBar: boolean;
    @Input() public displayButtonBarObservableName: string;
    @Input() public displayCloseOption: boolean;
    @Input() public primaryButtonContentObservableName: string;
    @Input() public primaryButtonActionBeforeCloseObservableName: string;
    @Input() public primaryButtonActionAfterCloseObservableName: string;
    @Input() public secondaryButtonContentObservableName: string;
    @Input() public secondaryButtonActionBeforeCloseObservableName: string;
    @Input() public secondaryButtonActionAfterCloseObservableName: string;
    @Input() public inputValues = new Map<string, any>();
    @Input() public isDraggableDialog: boolean;
    @Input() public isDraggableContent: boolean;

    @Output() public confirmDialogData: EventEmitter<any> = new EventEmitter<any>();
    @Output() public cancelDialogData: EventEmitter<any> = new EventEmitter<any>();

    @HostBinding('class') get HeadingClass() {
        return 'generic-dialog-block';
    }

    public componentRef: ComponentRef<any>;
    public lastOpenedDialog: MatDialogRef<any>;
    public isPrimaryButtonActive = true;
    public isSecondaryButtonActive = true;

    private isDialogOpen: boolean;
    private dialogCloseSubscription: Subscription;
    private primaryButtonActiveSubscription: Subscription;
    private primaryButtonActionAfterCloseSubscription: Subscription;
    private secondaryButtonActiveSubscription: Subscription;
    private secondaryButtonActionAfterCloseSubscription: Subscription;
    private displayButtonBarObservableNameSubscription: Subscription;

    constructor(
        public dialogRef: MatDialogRef<CtboxGenericDialogDataComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.dataSource = data.dataSource;
        this.dialogTitle = data.dialogTitle;
        this.dialogTitleClass = data.dialogTitleClass;
        this.dialogBody = data.dialogBody;
        this.displayCloseOption = data.displayCloseOption;
        this.displayButtonBar = data.displayButtonBar;
        this.dialogButton = data.dialogButton;
        this.dialogCloseButon = data.dialogCloseButon;
        this.dialogMessage = data.dialogMessage;
        this.dialogTypes = data.dialogTypes;
        this.dialogContentBorder = data.dialogContentBorder;
        this.dialogContentGetResultPropertyName = data.dialogContentGetResultPropertyName;
        this.dialogContentGetCloseResultPropertyName = data.dialogContentGetCloseResultPropertyName;
        this.primaryButtonContentObservableName = data.primaryButtonContentObservableName;
        this.primaryButtonActionBeforeCloseObservableName = data.primaryButtonActionBeforeCloseObservableName;
        this.primaryButtonActionAfterCloseObservableName = data.primaryButtonActionAfterCloseObservableName;
        this.secondaryButtonContentObservableName = data.secondaryButtonContentObservableName;
        this.secondaryButtonActionBeforeCloseObservableName = data.secondaryButtonActionBeforeCloseObservableName;
        this.secondaryButtonActionAfterCloseObservableName = data.secondaryButtonActionAfterCloseObservableName;
        this.displayButtonBarObservableName = data.displayButtonBarObservableName;
        this.inputValues = data.inputValues;
        this.isDraggableContent = data.isDraggableContent ?? true;
        this.isDraggableDialog = data.isDraggableDialog ?? true;
    }

    public ngOnInit(): void {
        this.componentRef = this.viewContainerRef.createComponent(this.data.template);

        if (this.inputValues) {
            this.inputValues.forEach((value: any, key: string) => {
                this.componentRef.instance[key] = value;
            });
        }
        this.subscribeToContentValidation();
    }

    public ngOnDestroy(): void {
        if (this.primaryButtonActiveSubscription) {
            this.primaryButtonActiveSubscription.unsubscribe();
        }

        if (this.secondaryButtonActiveSubscription) {
            this.secondaryButtonActiveSubscription.unsubscribe();
        }

        if (this.primaryButtonActionAfterCloseSubscription) {
            this.primaryButtonActionAfterCloseSubscription.unsubscribe();
        }

        if (this.secondaryButtonActionAfterCloseSubscription) {
            this.secondaryButtonActionAfterCloseSubscription.unsubscribe();
        }

        if (this.displayButtonBarObservableNameSubscription) {
            this.displayButtonBarObservableNameSubscription.unsubscribe();
        }

        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

    public acceptClick(): void {
        if (this.existTemplateObservable(this.primaryButtonActionBeforeCloseObservableName)) {
            this.getTemplateObservable(this.primaryButtonActionBeforeCloseObservableName).next(true);
            return;
        }
        this.operationPrimaryButton();
    }

    public cancelClick(): void {
        if (this.existTemplateObservable(this.secondaryButtonActionBeforeCloseObservableName)) {
            this.getTemplateObservable(this.secondaryButtonActionBeforeCloseObservableName).next(true);
            return;
        }
        this.operationSecondaryButton();
    }

    public clickCloseOption(): void {
        this.operationCloseButton();
    }

    public openDialog(options?: MatDialogConfig): void {
        if (this.isDialogOpen) {
            this.closeChildDialog();
        }

        this.lastOpenedDialog = this.dialog.open(this.template, options);
        this.dialogCloseSubscription = this.lastOpenedDialog.afterClosed().subscribe((val: any) => {
            this.cancelDialogData.emit(val);
        });
        this.isDialogOpen = true;
    }

    public closeChildDialog(): void {
        if (!this.isDialogOpen) {
            return;
        }

        this.lastOpenedDialog.close();
        this.isDialogOpen = false;
        if (this.dialogCloseSubscription) {
            this.dialogCloseSubscription.unsubscribe();
            this.dialogCloseSubscription = undefined;
        }

        this.lastOpenedDialog = undefined;
    }

    private getAcceptResult() {
        if (!this.dialogContentGetResultPropertyName ||
            this.componentRef.instance[this.dialogContentGetResultPropertyName] === null || // To avoid 0 or empty string
            this.componentRef.instance[this.dialogContentGetResultPropertyName] === undefined) {
            return true;
        }

        return this.componentRef.instance[this.dialogContentGetResultPropertyName];
    }

    private getCancelResult() {
        if (!this.dialogContentGetResultPropertyName ||
            this.componentRef.instance[this.dialogContentGetResultPropertyName] === null || // To avoid 0 or empty string
            this.componentRef.instance[this.dialogContentGetResultPropertyName] === undefined) {
            return false;
        }

        return null;
    }

    private getCloseResult() {
        if (!this.dialogContentGetCloseResultPropertyName ||
            this.componentRef.instance[this.dialogContentGetCloseResultPropertyName] === null || // To avoid 0 or empty string
            this.componentRef.instance[this.dialogContentGetCloseResultPropertyName] === undefined) {
            return false;
        }

        if (this.dialogContentGetCloseResultPropertyName &&
            this.componentRef.instance[this.dialogContentGetCloseResultPropertyName] !== false &&
            !this.componentRef.instance[this.dialogContentGetCloseResultPropertyName]) {
            return null;
        }

        return this.componentRef.instance[this.dialogContentGetCloseResultPropertyName];
    }

    private existTemplateObservable(observableName: string): boolean {
        return observableName && this.componentRef.instance[observableName];
    }

    private getTemplateObservable(observableName: string): any {
        return this.componentRef.instance[observableName];
    }

    private operationPrimaryButton() {
        this.confirmDialogData.emit();
        this.closeChildDialog();
        this.closeThisDialog(this.getAcceptResult());
    }

    private operationSecondaryButton() {
        this.cancelDialogData.emit();
        this.closeChildDialog();
        this.closeThisDialog(this.getCancelResult());
    }

    private operationCloseButton() {
        this.cancelDialogData.emit();
        this.closeChildDialog();
        this.closeThisDialog(this.getCloseResult());
    }

    private closeThisDialog(result: any) {
        this.dialogRef.close(result);
    }

    private subscribeToContentValidation() {
        if (this.existTemplateObservable(this.primaryButtonContentObservableName)) {
            this.primaryButtonActiveSubscription = this.getTemplateObservable(this.primaryButtonContentObservableName)
                .subscribe((isPrimaryButtonActive: boolean) => {
                    this.isPrimaryButtonActive = isPrimaryButtonActive;
                });
        }

        if (this.existTemplateObservable(this.secondaryButtonContentObservableName)) {
            this.secondaryButtonActiveSubscription = this.getTemplateObservable(this.secondaryButtonContentObservableName)
                .subscribe((isSecondaryButtonActive: boolean) => {
                    this.isSecondaryButtonActive = isSecondaryButtonActive;
                });
        }

        if (this.existTemplateObservable(this.primaryButtonActionAfterCloseObservableName)) {
            this.primaryButtonActionAfterCloseSubscription =
                this.getTemplateObservable(this.primaryButtonActionAfterCloseObservableName)
                    .subscribe((isPrimaryButtonAfterClose: boolean) => {

                        if (!isPrimaryButtonAfterClose) {
                            return;
                        }

                        this.operationPrimaryButton();
                    });
        }

        if (this.existTemplateObservable(this.secondaryButtonActionAfterCloseObservableName)) {
            this.secondaryButtonActionAfterCloseSubscription =
                this.getTemplateObservable(this.secondaryButtonActionAfterCloseObservableName)
                    .subscribe((isSecondaryButtonAfterClose: boolean) => {

                        if (!isSecondaryButtonAfterClose) {
                            return;
                        }

                        this.operationSecondaryButton();
                    });
        }

        if (this.existTemplateObservable(this.displayButtonBarObservableName)) {
            this.displayButtonBarObservableNameSubscription =
                this.getTemplateObservable(this.displayButtonBarObservableName)
                    .subscribe((displayButtonBarObservableName: boolean) => {

                        this.displayButtonBar = displayButtonBarObservableName;
                    });
        }
    }

}
