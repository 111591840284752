import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat';
import { DocumentCreationType } from 'src/app/core/standard/enums/documents/document-creation-params.enum';
import { ModuleType } from 'src/app/core/shared/enums/module-type.enum';
import { DocumentStatusCodes } from 'src/app/core/standard/enums/documents/document-status.enum';
import { DocumentTabsList } from 'src/app/core/standard/enums/documents/document-tabs-list.enum';
import { DocumentEditionTabs } from 'src/app/core/standard/enums/documents/document-edition-tabs.enum';

@Injectable({
    providedIn: 'root'
})

export abstract class IDocumentsNavigationService {

    abstract getDocumentId(): Observable<string>;
    abstract getTabInEdition(): Observable<string>;
    abstract getTocTemplateId(): Observable<string>;
    abstract setTocTemplateId(tocTemplateId: string): void;
    abstract getParentDocumentId(): Observable<string>;
    abstract getTemplateId(): Observable<string>;
    abstract getDocumentCreationType(): Observable<DocumentCreationType>;
    abstract getOriginModule(): Observable<ModuleType>;
    abstract goToCreateFromSmartform(tocTemplateId: string, originModule: ModuleType): void;
    abstract createDocumentFromGeneratedSmartform(): void;
    abstract createDocumentFromLldTemplate(tocTemplateId: string, originModule: ModuleType): void;
    abstract goToCreateDocumentFromFile(): void;
    abstract goToCreateDocumentNotEditable(): void;
    abstract goToCreateEmptyDocument(): void;
    abstract goToNewDocumentSelection(): void;
    abstract goToFirstDocumentSelection(): void;
    abstract goToDocumentEditionTab(documentId: string, tab: DocumentEditionTabs): void;
    abstract goToDocumentEditionInTab(tab: DocumentEditionTabs): void;
    abstract goToCreateDocumentFromTemplate(templateId: string, originModule: ModuleType): void;
    abstract goToUploadFileToCreateAnnexed(parentDocumentId: string): void;
    abstract goToUploadFileToCreateDocument(): void;
    abstract goToCreateEmptyAnnexed(parentDocumentId: string): void;
    abstract goToCreateAnnexedFromFile(parentDocumentId: string): void;
    abstract goToCreateAnnexedNotEditable(parentDocumentId: string): void;
    abstract goToMyTemplatesToC(): void;
    abstract goToSmartformsToC(): void;
    abstract goToLldToC(): void;
    abstract goToDocumentsMain(): void;
    abstract goToDocumentsSearch(): void;
    abstract goToDocumentStateList(state: DocumentStatusCodes, activeTab: DocumentTabsList): void;
}
