import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TemplateDocumentDTO, TemplateDocumentService, TemplateDTO } from 'src/app/api';
import { DocumentTypeCodes } from 'src/app/core/shared/enums/document-type-codes.enum';
import { IWarningNotificacionService } from 'src/app/core/shared/services/warning-notification/warning-notification.service.interface';
import { ITemplateDocumentRevisionService } from 'src/app/core/shared/services/templates/template-document-revision/template-document-revision.service.interface';
import { ITemplatePreviewService } from 'src/app/core/shared/services/templates/template-preview/template-preview.service.interface';
import { NotificationState } from '../ctbox-notification/enums/notification-state.enum';

@Component({
    selector: 'app-templates-document-revision-list',
    templateUrl: './templates-document-revision-list.component.html',
    styleUrls: ['./templates-document-revision-list.component.scss']

})
export class TemplatesDocumentRevisionListComponent implements OnInit {

    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.sort = ms;
        this.setDataSourceAttributes();
    }

    @Input() templateId: string;
    @Input() keepDocuments: boolean;

    public get notificationState(): typeof NotificationState {
        return NotificationState;
    }

    public get documentTypeCodes(): typeof DocumentTypeCodes {
        return DocumentTypeCodes;
    }
    public displayedColumns: string[] = ['icon', 'name', 'modificationTimeStamp', 'lastModificationUser', 'actionsColumn'];
    public dataSource: MatTableDataSource<TemplateDocumentDTO>;
    public pendingDocuments = 0;
    public totalDocuments = 0;
    public currentTemplateDocumentIdPreview: string;
    public forbidEditMessage: string;
    public hasPendingDocuments: boolean;

    private sort: MatSort;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
                private documentsService: TemplateDocumentService,
                private warningNotificacionService: IWarningNotificacionService,
                private templatePreviewService: ITemplatePreviewService,
                private templateDocumentRevisionService: ITemplateDocumentRevisionService,
                private inputRoute: ActivatedRoute) {
        this.forbidEditMessage = $localize`:@@PlantillaDocumentoNoTienesPermisoRevisionMensaje:No tienes permisos de edición en este documento`;
        this.templateId = this.data.dataSource;
    }

    public ngOnInit(): void {
        if (!this.templateId) {
            this.templateId = this.inputRoute.snapshot.paramMap.get('templateId');
        }

        if (!this.keepDocuments) {
            this.keepDocuments = this.inputRoute.snapshot.paramMap.get('keepDocuments') !== null;
        }

        this.totalDocuments = this.templateDocumentRevisionService.getCurrentStateDocuments().size;
        this.subscribeToStateDocumentChange();
        if (this.keepDocuments && this.totalDocuments > 0) {
            // This is the way that this screen  arrives from close preview screen.
            // If it is not total documents will be zero and go for the else.
            this.loadCBDocuments(this.templateId);
        } else {
            this.loadPendingCBDocuments(this.templateId);
        }
    }

    public setDataSourceAttributes() {
        if (this.dataSource === undefined) {
            return;
        }
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
            return this.templatePreviewService.orderBy(data, sortHeaderId);
        };
    }

    public loadCBDocuments(templateId: string): void {
        this.pendingDocuments = this.templateDocumentRevisionService.getNumPendingDocuments();
        this.totalDocuments = this.templateDocumentRevisionService.getCurrentStateDocuments().size;

        this.documentsService.templateDocumentSearchTemplateTemplateIdGet(templateId).subscribe(values => {
            const valuesInStateDocuments: TemplateDocumentDTO[] = [];
            const currentDocuments = this.templateDocumentRevisionService.getCurrentStateDocuments();
            const idDocuments = Array.from(currentDocuments.keys());
            values.forEach(element => {
                if (idDocuments.find(id => id === element.id) !== undefined) {
                    valuesInStateDocuments.push(element);
                }
                this.dataSource = new MatTableDataSource<TemplateDocumentDTO>(valuesInStateDocuments);
            });
            this.setDataSourceAttributes();
            this.closeIfFinishes();
        });
    }

    public loadPendingCBDocuments(templateId: string): void {
        this.documentsService.templateDocumentSearchPendingTemplateTemplateIdGet(templateId).subscribe(values => {
            this.dataSource = new MatTableDataSource<TemplateDocumentDTO>(values);
            const stateDocument = new Map<string, string>();
            this.templateDocumentRevisionService.UpdateStateDocument(stateDocument);
            values.forEach(element => {
                this.templateDocumentRevisionService.setPending(element);
            });
            this.setDataSourceAttributes();
        });
    }

    public subscribeToStateDocumentChange() {
        this.templateDocumentRevisionService.getCurrentStateDocumentsSubscription().subscribe(() => {
            this.pendingDocuments = this.templateDocumentRevisionService.getNumPendingDocuments();
            this.totalDocuments = this.templateDocumentRevisionService.getCurrentStateDocuments().size;
        });
    }

    public isPending(document: TemplateDocumentDTO): boolean {
        return this.templateDocumentRevisionService.isPending(document);
    }

    public isApplied(document: TemplateDocumentDTO): boolean {
        return this.templateDocumentRevisionService.isApplied(document);
    }

    public isKeep(document: TemplateDocumentDTO): boolean {
        return this.templateDocumentRevisionService.isKeep(document);
    }

    public goToPreviewChanges(templateDocument: TemplateDocumentDTO): void {
        const orderedDocuments = this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort);
        this.templateDocumentRevisionService.orderIndex(orderedDocuments);
        this.currentTemplateDocumentIdPreview = templateDocument.id;
        this.warningNotificacionService.showTemplatesPendingPreviewTemplate(templateDocument, true).then((hasPending: boolean) => {
            this.hasPendingDocuments = hasPending;
        });
    }

    public closeIfFinishes(): void {

        const shouldClose = this.pendingDocuments <= 0;
        if (!shouldClose) {
            return;
        }
    }

    public isTemplate(element: TemplateDocumentDTO): boolean {
        return (element.document as TemplateDTO) !== null;
    }

    public isReviewDisabled(templateDocument: TemplateDocumentDTO): boolean {
        return !templateDocument?.document?.userPermissions || !templateDocument?.document?.userPermissions?.canReviewTemplateChanges;
    }

}
