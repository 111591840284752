import {
    Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild, OnChanges, AfterViewInit, OnDestroy,
    ChangeDetectorRef, AfterViewChecked, Output, EventEmitter
} from '@angular/core';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { Subscription } from 'rxjs';
import { Point } from '@angular/cdk/drag-drop';
import { SignatureUserDTO, SignatureUserLocationParametersDTO } from 'src/app/api';
import { IDocumentSignatureNoEditableService } from 'src/app/core/standard/services/documents/document-signature-no-editable/document-signature-no-editable.service.interface';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { FormatString } from 'src/app/core/shared/utils/FormatString';
import { SignatureConfigurationComponent } from '../signature-configuration/signature-configuration.component';
import { MatDialogRef } from '@angular/material/dialog';
import { dialogTypes } from '../ctbox-generic-dialog-data/ctbox-generic-dialog-data.component';

@Component({
    selector: 'app-signature-with-role',
    templateUrl: './signature-with-role.component.html',
    styleUrls: ['./signature-with-role.component.scss']
})
export class SignatureWithRoleComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked, OnDestroy {
    // Documentation in: https://confluence.inet.laleynext.es/pages/editpage.action?pageId=130747026

    @Input() signatureUser: SignatureUserDTO;
    @Input() pdfViewer: PdfViewerComponent;
    @Input() isDocLoaded: boolean;
    @Input() documentWithSign: ElementRef;
    @Input() viewerMainAncestorComponent: ElementRef;
    @Input() isPreview: boolean;
    @Input() canEdit: boolean;
    @Output() previewEnd = new EventEmitter<void>();
    @ViewChild('signature') signature: ElementRef;

    public documentSignatureNoEditableSubscription: Subscription;

    public pixelToMMFactor = 0.26458333333;  // https://www.unitconverters.net/typography/pixel-x-to-millimeter.html

    public heightSignature = '90px';
    public widthSignature = '250px';
    public xSignature = '0px';
    public ySignature = '0pX';
    public transformSignature = 'translate3d(0px, 0px, 0px)';
    public signInitialPosition: Point;
    public currentDisableDrag = false;
    public previewMessage = '';

    public matTooltipDelete = $localize`:@@BorrarFirma:Borrar firma`;
    public matTooltipEdit = $localize`:@@EditarFirma:Editar firma`;

    private lastDialog: MatDialogRef<any, any>;

    constructor(
        private genericDialogService: GenericDialogService,
        private documentSignatureNoEditableService: IDocumentSignatureNoEditableService,
        private changeDetector: ChangeDetectorRef) {
    }

    public ngOnInit(): void {
        this.documentSignatureNoEditableSubscription = this.documentSignatureNoEditableService
            .getDocumentSignatureSubscription().subscribe(() => {
                if (!this.signatureUser || this.isPreview) {
                    return;
                }
                this.signatureUser = this.documentSignatureNoEditableService.getSignatureUserByRole(this.signatureUser.role);
            });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.signatureUser || (changes.isDocLoaded && changes.isDocLoaded.currentValue === true)) {
            this.startSignatureConfiguration();

        }
    }

    public ngAfterViewInit(): void {
        this.startSignatureConfiguration();
    }

    public ngAfterViewChecked() {
        this.changeDetector.detectChanges();
    }

    public ngOnDestroy(): void {
        if (this.documentSignatureNoEditableSubscription) {
            this.documentSignatureNoEditableSubscription.unsubscribe();
        }
    }

    public startSignatureConfiguration() {
        if (this.isPreview) {
            this.previewMessage = $localize`:@@ArrastraParaAnadirFirmaMensajePreview:Arrastra y suelta para añadir al documento.`;
        }

        this.placeSignature();
    }

    public deleteSigner() {
        if (this.isPreview) {
            this.previewEnd.emit();
            return;
        }
        this.documentSignatureNoEditableService.removeSignatureUser(this.signatureUser.role);
    }

    public editSigner() {
        this.openInsertSignature().afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            this.documentSignatureNoEditableService.changeRoleSignatureUser(this.signatureUser.role, result.role);
        });
    }

    public dragStarted() {
        if (!this.isPreview) {
            return;
        }

        this.previewMessage = $localize`:@@SueltaParaColocarFirmaAnadirFirmaMensajePreview:Coloca la firma en su posición en el documento`;
    }

    public dragEnded() {
        if (this.isPreview) {
            this.disableDrag();
            this.previewEnd.emit();
            this.openInsertSignature().afterClosed().subscribe((result) => {
                this.signatureUser.role = result.role;
                this.documentSignatureNoEditableService.addSignatureUser(this.signatureUser);
                this.enableDrag();
            });
        }

        this.setPositionParameters();
        if (!this.isPreview) {
            this.placeSignature();
            this.signInitialPosition = {
                x: 0,
                y: 0
            };
        }
    }

    public getCurrentRoles() {
        return this.documentSignatureNoEditableService.getCurrentRolesInSigners();
    }

    private placeSignature() {
        if (!this.pdfViewer || !this.pdfViewer.pdfViewer || !this.signature) {
            return;
        }

        if (this.isPreview) {

            return;
        }

        let pageIndex = 0;
        const pages = this.pdfViewer.pdfViewer.getPagesOverview();

        if (pages.length < 1) {
            return;
        }

        if (this.signatureUser && this.signatureUser.signatureUserLocationParameters) {
            const pageNumber = this.signatureUser.signatureUserLocationParameters.page;
            pageIndex = pageNumber - 1;
        }

        if (pageIndex >= pages.length) {
            pageIndex = pages.length - 1;
        }

        const pageView = this.pdfViewer.pdfViewer.getPageView(pageIndex);
        const scales = this.getScalesForPage(pageView);

        this.placeSignatureSize(scales);

        this.placeSignaturePosition(scales, pageView);
    }

    private placeSignaturePosition(scales: { scaleX: any; scaleY: any; }, pageView: any) {
        const pageBundingRect = pageView.textLayer.textLayerDiv.getBoundingClientRect();

        let xPosPixelsInPage = 0;
        let yPosPixelsInPage = 0;

        if (this.signatureUser && this.signatureUser.signatureUserLocationParameters) {
            xPosPixelsInPage = this.signatureUser.signatureUserLocationParameters.posX * scales.scaleX;
            yPosPixelsInPage = this.signatureUser.signatureUserLocationParameters.posY * scales.scaleY;
        }

        const viewerMainAncestorDocumentComponent = this.viewerMainAncestorComponent?.nativeElement?.getBoundingClientRect();

        const xPosPixelsFinal = pageBundingRect.left - viewerMainAncestorDocumentComponent?.left + xPosPixelsInPage;
        const yPosPixelsFinal = pageBundingRect.top - viewerMainAncestorDocumentComponent?.top + yPosPixelsInPage;

        this.xSignature = FormatString('{0}px', xPosPixelsFinal.toString());
        this.ySignature = FormatString('{0}px', yPosPixelsFinal.toString());
    }

    private placeSignatureSize(scales: { scaleX: any; scaleY: any; }) {
        const xSize = 60;
        const ySize = 23.8125;

        const xSizePixels = xSize * scales.scaleX;
        const ySizePixels = ySize * scales.scaleY;

        // set pixel size
        this.heightSignature = FormatString('{0}px', ySizePixels.toString());
        this.widthSignature = FormatString('{0}px', xSizePixels.toString());
    }

    private setPositionParameters() {
        let signerParameters = this.signatureUser.signatureUserLocationParameters;

        signerParameters = this.setCurrentPositionSignatureParameters(signerParameters);
        signerParameters = this.placeSignatureInsidePage(signerParameters);

        this.signatureUser.signatureUserLocationParameters = signerParameters;

        if (!this.isPreview) {
            this.documentSignatureNoEditableService.changeSignatureLocationParametersUser(this.signatureUser);
        }
    }

    private setCurrentPositionSignatureParameters(signerParameters: SignatureUserLocationParametersDTO): SignatureUserLocationParametersDTO {
        const pages = this.pdfViewer.pdfViewer.getPagesOverview();

        // Position of signature
        const signatureBoundingRect = this.signature.nativeElement.getBoundingClientRect();

        const pageNumber = this.getPageForSignature(pages, signatureBoundingRect.y);
        const pageIndex = pageNumber - 1;
        // Bounding rect of page, include x, y and size
        const pageView = this.pdfViewer.pdfViewer.getPageView(pageIndex);
        const pageBundingRect = pageView.textLayer.textLayerDiv.getBoundingClientRect();
        const scales = this.getScalesForPage(pageView);

        const xPosPixels = signatureBoundingRect.x;
        const yPosPixels = signatureBoundingRect.y;
        let xPosPage = 0;
        let yPosPage = 0;

        if (pageView) {
            xPosPage = pageBundingRect.x;
            yPosPage = pageBundingRect.y;
        }

        const xPos = (xPosPixels - xPosPage) / scales.scaleX;
        const yPos = (yPosPixels - yPosPage) / scales.scaleY;

        const xSizePixels = signatureBoundingRect.width;
        const ySizePixels = signatureBoundingRect.height;

        signerParameters.page = pageNumber;
        signerParameters.posX = Math.floor(xPos);
        signerParameters.posY = Math.floor(yPos);

        if (!this.isPreview) {
            const xSize = (xSizePixels) / scales.scaleX;
            const ySize = (ySizePixels) / scales.scaleY;
            signerParameters.sizeX = Math.floor(xSize);
            signerParameters.sizeY = Math.floor(ySize);

            this.documentSignatureNoEditableService.changeSignatureLocationParametersUser(this.signatureUser);
        }

        return signerParameters;
    }

    private getScalesForPage(pageView: any): { scaleX, scaleY } {
        const scalePage = this.pdfViewer.pdfViewer.currentScale;
        const pageBundingRect = pageView.textLayer.textLayerDiv.getBoundingClientRect();

        const scaleViewPortX = pageBundingRect.width / pageView.width;
        const scaleViewPortY = pageBundingRect.height / pageView.height;
        const scaleX = (scalePage * scaleViewPortX) / this.pixelToMMFactor;
        const scaleY = (scalePage * scaleViewPortY) / this.pixelToMMFactor;

        return { scaleX, scaleY };
    }

    private placeSignatureInsidePage(signerParameters: SignatureUserLocationParametersDTO): SignatureUserLocationParametersDTO {
        const pageNumber = signerParameters.page;
        const pageIndex = pageNumber - 1;

        let xPos = signerParameters.posX;
        let yPos = signerParameters.posY;

        const xSize = signerParameters.sizeX;
        const ySize = signerParameters.sizeY;

        const pageView = this.pdfViewer.pdfViewer.getPageView(pageIndex);
        const pageWidth = (pageView.width / pageView.scale) * this.pixelToMMFactor;
        const pageHeight = (pageView.height / pageView.scale) * this.pixelToMMFactor;

        if (xPos < 0) {
            xPos = 0;
        }
        if (Math.floor(xPos + xSize) > pageWidth) {
            xPos = Math.floor(pageWidth - xSize);
        }

        if (yPos < 0) {
            yPos = 0;
        }
        if (Math.floor(yPos + ySize) > pageHeight) {
            yPos = Math.floor(pageHeight - ySize);
        }

        signerParameters.posX = Math.floor(xPos);
        signerParameters.posY = Math.floor(yPos);
        signerParameters.sizeX = Math.floor(xSize);
        signerParameters.sizeY = Math.floor(ySize);

        return signerParameters;
    }

    private getPageForSignature(pages: any, yPositionSignature: number): number {
        const firstPage = 1;
        for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
            // The div containing the page without border.
            const pagePosition = this.pdfViewer.pdfViewer.getPageView(pageIndex).textLayer.textLayerDiv.getBoundingClientRect();
            const pagePositionHeight = pagePosition.height;
            if (yPositionSignature > pagePosition.y && yPositionSignature <= pagePosition.y + pagePositionHeight) {
                return pageIndex + 1;
            }

        }

        return firstPage;
    }

    private openInsertSignature() {
        const config = this.genericDialogService.getSmallDialogConfig();
        const data: any = {
            template: SignatureConfigurationComponent,
            displayCloseOption: true,
            displayButtonBar: true,
            dialogButton: this.isPreview ? $localize`:@@InsertarFirma:Añadir firma` : $localize`:@@EditarFirmaTitulo:Editar firma`,
            dialogCloseButon: $localize`:@@Cancelar:Cancelar`,
            dialogTypes: dialogTypes.Warning,
            dialogTitle: this.isPreview ? $localize`:@@InsertarFirma:Añadir firma` : $localize`:@@EditarFirmaTitulo:Editar firma`,
            isAdding: this.isPreview,
            defaultRole: this.signatureUser?.role,
            signatureId: this.signatureUser?.id,
            rolCanBeRepeated: this.signatureUser?.role,
            currentRoles: this.getCurrentRoles(),
            primaryButtonContentObservableName: 'isValidSubscription',
            dialogContentGetResultPropertyName: 'signature',
            dialogContentGetCloseResultPropertyName: 'signature',
        };

        this.lastDialog = this.genericDialogService.openTemplateWithConfigAndData(data.template, config, data);

        return this.lastDialog;

    }

    private enableDrag() {
        this.currentDisableDrag = false;
    }

    private disableDrag() {
        this.currentDisableDrag = true;
    }
}
