import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs-compat';
import { ClauseFolderDTO, LastModificationFoldersService, LastModificationFoldersDTO } from 'src/app/api';
import { AdministrationPermissionFolder } from '../../../models/administration-permission-folder.model';
import { IAdministrationPermissionClausesFoldersService } from './administration-permission-clauses-folders.service.interface';

@Injectable({
    providedIn: 'root'
})
export class AdministrationPermissionClausesFoldersService extends IAdministrationPermissionClausesFoldersService {

    private currentClauseLoadedDate = new BehaviorSubject<Date>(new Date());

    public generateFoldersFromClauseFolders(clauseFolders: ClauseFolderDTO[]): AdministrationPermissionFolder[] {
        const folders: AdministrationPermissionFolder[] = [];

        clauseFolders.forEach(clauseFolder => {
            const adminFolder = this.getAdminFolderFromClauseFolder(clauseFolder);
            folders.push(adminFolder);
        });

        return folders;
    }

    public hasNewChangesClauseFolders(lastModificationFoldersService: LastModificationFoldersService): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            lastModificationFoldersService.lastModificationFoldersGet().subscribe((data: LastModificationFoldersDTO) => {
                const needRefresh = data && new Date(data.lastModificationClauseFolders) > this.getCurrentClauseFolderLoadedDate();
                resolve(needRefresh);
            }, () => {
                resolve(true);
            });
        });
    }

    public updateClauseFolderChangesDate(): void {
        this.currentClauseLoadedDate.next(this.getCurrentDateUTC());
    }

    private getCurrentDateUTC(): Date {
        const now = new Date();
        return new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    }

    private getAdminFolderFromClauseFolder(clauseFolder: ClauseFolderDTO): AdministrationPermissionFolder {
        const adminFolder: AdministrationPermissionFolder = {
            id: clauseFolder.id,
            name: clauseFolder.name,
            folderParentId: clauseFolder.clauseFolderParentId,
            hasAccess: true,
            childrenFolder: []
        };

        clauseFolder.childrenFolder.forEach(child => {
            const childAdminFolder = this.getAdminFolderFromClauseFolder(child);
            adminFolder.childrenFolder.push(childAdminFolder);
        });

        return adminFolder;
    }

    private getCurrentClauseFolderLoadedDate(): Date {
        return this.currentClauseLoadedDate.getValue();
    }
}
