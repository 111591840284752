import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { IClausesNavigationService } from './clauses-navigation.service.interface';
import { ClauseCreationType } from 'src/app/core/shared/enums/clause-creation-params.enum';
import { BehaviorSubject, Observable } from 'rxjs-compat';

@Injectable({
    providedIn: 'root'
})

export class ClausesNavigationService implements IClausesNavigationService {

    private clauseName = new BehaviorSubject<string>('');
    private clauseId = new BehaviorSubject<string>('');
    private tocClauseId = new BehaviorSubject<string>('');
    private clauseCreationType = new BehaviorSubject<ClauseCreationType>(ClauseCreationType.empty);

    constructor(private router: Router) { }

    public getClauseName(): Observable<string> {
        return this.clauseName.asObservable();
    }

    public getClauseId(): Observable<string> {
        return this.clauseId.asObservable();
    }

    public getTocClauseId(): Observable<string> {
        return this.tocClauseId.asObservable();
    }

    public setTocClauseId(tocClauseId: string): void {
        this.tocClauseId.next(tocClauseId);
    }

    public setClauseName(ClauseName: string): void {
        return this.clauseName.next(ClauseName);
    }

    public getClauseCreationType(): Observable<ClauseCreationType> {
        return this.clauseCreationType.asObservable();
    }

    public createClauseFromLldClause(tocClauseId: string): void {
        this.setClauseId(null);
        this.setTocClauseId(tocClauseId);
        this.setClauseCreationType(ClauseCreationType.fromLld);
        this.navigateToCreateClauseLLD();
    }

    public goToEditionClause(id: string) {
        this.setClauseCreationType(ClauseCreationType.edit);
        this.setClauseId(id);
        this.navigateToCreateClause(id);
    }

    public goToCreateClauseFromFile(): void {
        this.setClauseCreationType(ClauseCreationType.fromFile);
        this.navigateToCreateClause();
    }

    public goToCreateEmptyClause(): void {
        this.setClauseCreationType(ClauseCreationType.empty);
        this.navigateToCreateClause();
    }

    public goToNewClauseSelection(): void {
        this.router.navigate(['/main/clauses-library/create-new-clause-selection']);
    }

    public goToSearchClause(searchTerm: string): void {
        this.router.navigate(['/main/clauses-library/search-clauses', { searchTerm }]);
    }

    public goToLoadLocalDocument(): void {
        this.router.navigate(['/main/clauses-library/load-local-clause']);
    }

    public goToMyClausesToC(): void {
        this.clearAll();
        this.router.navigate(['/main/clauses-library/my-clauses']);
    }

    public goToLldToC(): void {
        this.clearAll();
        this.router.navigate(['/main/clauses-library/clauses-laley']);
    }

    public navigateToEditClause(): void {
        this.router.navigate(['/clauses-library/clauses-view']);
    }

    public goToClausesLibrary(): void {
        this.router.navigate(['clauses-library/clauses-list']);
    }

    private setClauseCreationType(clauseCreationType: ClauseCreationType): void {
        this.clauseCreationType.next(clauseCreationType);
    }

    private navigateToCreateClause(id?: string): void {
        if (!id) {
            this.router.navigate(['/main/clauses-library/create-clause']);
            return;
        }

        this.router.navigate(['/main/clauses-library/create-clause', { clauseId: id }]);
    }

    private navigateToCreateClauseLLD(): void {
        this.router.navigate(['/main/clauses-library/create-clause-lld']);
    }

    private setClauseId(clauseId: string): void {
        this.clauseId.next(clauseId);
    }

    private clearAll(): void {
        this.setClauseId(null);
        this.setTocClauseId(null);
        this.setClauseCreationType(ClauseCreationType.empty);
    }
}
