import { Component, OnInit, HostBinding, Inject, Input, ViewChild, OnDestroy } from '@angular/core';
import {
    ChangeDocumentClauseDTO, DocumentClauseDTO, DocumentClauseService, DocumentDTO,
    DocumentService, DocumentSignatureService, UserPermissionsOnDocumentDTO
} from 'src/app/api';
import { ActivatedRoute, Router } from '@angular/router';
import { HtmlViewerComponent } from 'src/app/shared/components/html-viewer/html-viewer.component';
import { DocumentTypeCodes } from 'src/app/core/shared/enums/document-type-codes.enum';
import { DocumentStatusCodes } from 'src/app/core/standard/enums/documents/document-status.enum';
import { IClauseDocumentRevisionService } from 'src/app/core/shared/services/clauses/clause-document-revision/clause-document-revision.service.interface';
import { ITransformCleanHtmlService } from 'src/app/core/shared/services/transform-clean-html/transform-clean-html.service.interface';
import { TypeUserService } from 'src/app/core/shared/services/type-user/type-user.service';
import { DocumentSignatureDTO } from 'src/app/api/model/documentSignatureDTO';
import { ExpirationDateDTO } from 'src/app/api/model/expirationDateDTO';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentClauseRevisionSendToFirmComponent } from 'src/app/shared/components/document-clause-revision-send-to-firm/document-clause-revision-send-to-firm.component';

@Component({
    selector: 'app-clause-document-revision-preview',
    templateUrl: './clause-document-revision-preview.component.html'
})
export class ClauseDocumentRevisionPreviewComponent implements OnInit, OnDestroy {

    @Input() documentClauseId: string;
    @Input() userPermissions: UserPermissionsOnDocumentDTO;

    @ViewChild('documentViewer') documentViewer: HtmlViewerComponent;

    @HostBinding('class') componentClass: string;

    public get documentStatusCodes(): typeof DocumentStatusCodes {
        return DocumentStatusCodes;
    }

    public isReviewDisabledSubscription = new BehaviorSubject<boolean>(true);

    public lastOpenedDialog: MatDialogRef<any>;
    public documentClause: DocumentClauseDTO;
    public pendingDocuments: number;
    public waitForFinishDocuments: number;
    public totalDocuments: number;
    public indexDocument: number;
    public clauseOriginal: string;
    public clauseModified: string;
    public clauseName: string;
    public documentName: string;
    public documentState: DocumentStatusCodes;
    public isDocumentATemplate = false;
    public forSignaturePreviously = false;
    public expirationDays: number;
    public expirationDate: Date;
    public newExpirationDate: Date;
    public lockUpdateHtml = false;
    public buttonColorAcceptChanges = 'primary';

    private addedTextInClauseId = 'id_';
    private currentStateSubscription: Subscription;

    constructor(@Inject(MAT_DIALOG_DATA) public data,
        public genericDialogService: GenericDialogService,
        public typeUserService: TypeUserService,
        private documentsService: DocumentClauseService,
        private clauseDocumentRevisionService: IClauseDocumentRevisionService,
        private transformCleanHtmlService: ITransformCleanHtmlService,
        private documentService: DocumentService,
        private inputRoute: ActivatedRoute,
        private router: Router,
        private documentSignatureService: DocumentSignatureService) {
        this.pendingDocuments = clauseDocumentRevisionService.getNumPendingDocuments();
        this.waitForFinishDocuments = clauseDocumentRevisionService.getNumWaitForFinishedDocuments();
        this.totalDocuments = clauseDocumentRevisionService.getCurrentStateDocuments().size;
        this.indexDocument = 0;
        this.documentClauseId = data.dataSourceComponent.id;
        this.documentClause = data.dataSourceComponent;
    }

    public ngOnInit(): void {
        this.componentClass = 'generic-dialog-content-inherit';
        if (!this.documentClauseId) {
            this.documentClauseId = this.inputRoute.snapshot.paramMap.get('documentClauseId');
        }

        this.subscribeToStateDocumentChange();

        this.loadDocument(this.documentClauseId);
        this.clauseName = this.documentClause.document.name;
        this.documentName = this.documentClause.document.name;

        this.buttonColorAcceptChanges = (this.documentState === this.documentStatusCodes.ForSignature) ? 'warn' : 'primary';

    }

    public ngOnDestroy(): void {
        if (this.currentStateSubscription) {
            this.currentStateSubscription.unsubscribe();
        }
    }

    public subscribeToStateDocumentChange() {
        this.currentStateSubscription = this.clauseDocumentRevisionService.getCurrentStateDocumentsSubscription().subscribe(() => {
            this.pendingDocuments = this.clauseDocumentRevisionService.getNumPendingDocuments();
            this.waitForFinishDocuments = this.clauseDocumentRevisionService.getNumWaitForFinishedDocuments();
            this.totalDocuments = this.clauseDocumentRevisionService.getCurrentStateDocuments().size;
            this.indexDocument = this.clauseDocumentRevisionService.getIndexDocumentClause(this.documentClause);
        });
    }

    public close(): void {
        if (this.checkHasChanges()) {
            const message = $localize`:@@ConfirmacionSalirMensaje:Si abandonas la pantalla los cambios se perderán`;
            this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
                if (result === true) {
                    if (this.lastOpenedDialog) {
                        this.lastOpenedDialog.close();
                    } else {
                        this.router.navigate(['/main/clauses-library/clauses-document-revision',
                            this.documentClause.clause.id, true], {});
                        return;
                    }
                }
            });
        } else {
            if (this.lastOpenedDialog) {
                this.lastOpenedDialog.close();
            } else {
                this.router.navigate(['/main/clauses-library/clauses-document-revision',
                    this.documentClause.clause.id, true], {});
            }
        }
    }

    public loadDocument(documentClauseId: string) {
        this.documentsService.documentClausesIdGet(documentClauseId).subscribe((document: DocumentClauseDTO) => {
            this.lockUpdateHtml = true;
            this.documentClause = document;
            this.clauseModified = document.clause.description;
            this.indexDocument = this.clauseDocumentRevisionService.getIndexDocumentClause(document);
            this.documentViewer.markClause(this.documentClause.clause.id);
            this.clauseOriginal = document.clause.description;
            this.clauseName = document.clause.name;
            this.loadDocumentState(document.document.id);
            this.checkPendingDocuments(this.documentClause.clause.id);

            setTimeout(() => {
                this.updateClauseContent();
                this.lockUpdateHtml = false;
            });
        });
    }

    public loadDocumentState(documentId: string) {
        if (this.documentClause.document?.documentTypes.code === DocumentTypeCodes.Template) {
            this.documentState = DocumentStatusCodes.Draft;
            this.calculateWaitingForFinish(this.documentClause);
            return;
        }

        this.documentService.documentIdGet(documentId).subscribe(document => {
            if (!document) {
                this.documentState = null;
                this.forSignaturePreviously = false;
            }

            this.documentState = document?.documentStatus?.code;
            this.forSignaturePreviously = document.documentSignature ? document.documentSignature.forSignaturePreviously : false;
            this.calculateWaitingForFinish(this.documentClause);
        }, error => {
            this.documentState = null;
            this.forSignaturePreviously = false;
        });
    }

    public checkPendingDocuments(clauseId: string) {
        if (this.totalDocuments > 0) {
            return;
        }
        this.clauseDocumentRevisionService.loadPendingCBDocumentsForClause(clauseId);
    }

    public goToClauseInDocument() {
        const clauseElement = document.getElementById(this.addedTextInClauseId + this.documentClause.clause.id);
        clauseElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }

    public updateClauseContent(): void {
        this.clauseOriginal = this.documentClause !== undefined ? this.documentClause.clause.description : '';
    }

    public navegateNextDocument(): void {
        if (this.checkHasChanges()) {
            const message = $localize`:@@ConfirmacionSalirMensaje:Si abandonas la pantalla los cambios se perderán`;
            this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
                if (result === true) {
                    this.nextDocumentAction();
                }
            });
            return;
        }
        this.nextDocumentAction();
    }

    public navegatePreviousDocument(): void {
        if (this.checkHasChanges()) {
            const message = $localize`:@@ConfirmacionSalirMensaje:Si abandonas la pantalla los cambios se perderán`;
            this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
                if (result === true) {
                    this.previousDocumentAction();
                }
            });
            return;
        }
        this.previousDocumentAction();
    }

    public navegateFirstDocument(): void {
        const nextClauseId = this.clauseDocumentRevisionService.getFirstDocumentClauseId(this.indexDocument);
        this.loadDocument(nextClauseId);
    }

    public navegateLastDocument(): void {
        const nextClauseId = this.clauseDocumentRevisionService.getLastDocumentClauseId(this.indexDocument);
        this.loadDocument(nextClauseId);
    }

    public previousDocumentAction(): void {
        const previousDocumentId = this.clauseDocumentRevisionService.getPreviousDocumentClauseId(this.indexDocument);
        this.loadDocument(previousDocumentId);
    }

    public nextDocumentAction(): void {
        const nextDocumentId = this.clauseDocumentRevisionService.getNextDocumentClauseId(this.indexDocument);
        this.loadDocument(nextDocumentId);
    }

    public checkHasChanges(): boolean {
        if (!this.isPending(this.documentClause)) {
            return false;
        }
        if (this.documentClause !== undefined && this.documentClause.clause !== undefined) {
            const original = this.documentClause.clause.description;
            const modifiedToCheck = this.clauseModified;
            const areSameWithClean = this.transformCleanHtmlService.checkCleanAndIsSameText(original, modifiedToCheck);
            return !areSameWithClean;
        }
        return false;
    }

    public clean(text: string): string {
        const DO_NOT_REPLACE_IN_RAW = false;
        const domParser = new DOMParser();
        const theDocument = domParser.parseFromString(text, 'text/html');
        this.transformCleanHtmlService.moveValuesTextService(theDocument, DO_NOT_REPLACE_IN_RAW);
        return theDocument.querySelector('body').innerHTML;
    }

    public acceptChanges(document: DocumentClauseDTO): void {
        let message: string;
        let dialogButton: string;
        if (this.documentState && this.documentState === DocumentStatusCodes.ForSignature) {
            message = $localize`:@@ConfirmacionCambioMensajeCancelarFirma:Este documento está enviado a firma, para proceder a cambiar la cláusula se cancelará el envío a firma. ¿Estás seguro?`;
            dialogButton = $localize`:@@CancelarFirmaYAceptarCambios:Cancelar firma y aplicar cambios`;
        } else {
            message = $localize`:@@ConfirmacionCambioMensaje:Vas a realizar cambios en el documento. ¿Estás seguro?`;
            dialogButton = $localize`:@@AplicarCambiosClausulaDocumento:Aplicar cambios`;
        }

        this.genericDialogService.showQuestion(message, '', '', dialogButton).afterClosed().subscribe((result) => {
            if (result === true) {
                const changeClause: ChangeDocumentClauseDTO = {
                    clauseId: this.documentClause.clause.id,
                    documentId: document.document.id,
                    acceptChanges: true,
                    html: this.clauseModified
                };
                this.documentsService.documentClausesResolveChangesPut(changeClause).subscribe((documentUpdated: DocumentClauseDTO) => {
                    this.calculateWaitingForFinishPromise(document).subscribe((documentClauses: DocumentClauseDTO[]) => {
                        if (documentClauses.length < 1 && documentUpdated.document.documentTypes?.code !== DocumentTypeCodes.Template &&
                            documentUpdated.isTheDocumentPendingSendToSign) {
                            this.clauseDocumentRevisionService.setWaitForFinished(document);
                        } else {
                            this.clauseDocumentRevisionService.setUnwaitForFinished(document);
                        }
                        this.clauseDocumentRevisionService.setApplied(document);
                        this.pendingDocuments = this.clauseDocumentRevisionService.getNumPendingDocuments();
                        this.waitForFinishDocuments = this.clauseDocumentRevisionService.getNumWaitForFinishedDocuments();
                        this.doAfterReview();
                    });
                });
            }
        });
    }

    public rejectChanges(document: DocumentClauseDTO): void {
        const message = $localize`:@@ConfirmacionRechazarMensaje:Vas a mantener la versión actual del documento. ¿Estás seguro?`;
        this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
            if (result === true) {
                const changeClause: ChangeDocumentClauseDTO = {
                    clauseId: this.documentClause.clause.id,
                    documentId: document.document.id,
                    acceptChanges: false,
                };
                this.documentsService.documentClausesResolveChangesPut(changeClause).subscribe((documentUpdated: DocumentClauseDTO) => {
                    this.calculateWaitingForFinishPromise(document).subscribe((documentClauses: DocumentClauseDTO[]) => {
                        if (documentClauses.length < 1 && documentUpdated.document.documentTypes.code !== DocumentTypeCodes.Template &&
                            documentUpdated.isTheDocumentPendingSendToSign) {
                            this.clauseDocumentRevisionService.setWaitForFinished(document);
                        } else {
                            this.clauseDocumentRevisionService.setUnwaitForFinished(document);
                        }
                        this.clauseDocumentRevisionService.setKeep(document);
                        this.pendingDocuments = this.clauseDocumentRevisionService.getNumPendingDocuments();
                        this.waitForFinishDocuments = this.clauseDocumentRevisionService.getNumWaitForFinishedDocuments();
                        this.doAfterReview();
                    });
                });
            }
        });

    }

    /// todo close

    public doAfterReview(): void {
        if (this.pendingDocuments < 1 && this.waitForFinishDocuments < 1) {
            this.close();
        } else {
            this.loadDocument(this.documentClause.id);
        }
    }

    public isPending(document: DocumentClauseDTO): boolean {
        const pending = this.clauseDocumentRevisionService.isPending(document);
        return pending;
    }

    public isApplied(document: DocumentClauseDTO): boolean {
        return this.clauseDocumentRevisionService.isApplied(document);
    }

    public isKeep(document: DocumentClauseDTO): boolean {
        return this.clauseDocumentRevisionService.isKeep(document);
    }

    public calculateWaitingForFinish(document: DocumentClauseDTO): void {
        if (document.document.documentTypes.code !== DocumentTypeCodes.Template && !this.forSignaturePreviously) {
            this.clauseDocumentRevisionService.setUnwaitForFinished(document);
            return;
        }

        this.documentsService.documentClausesSearchPendingDocumentDocumentIdGet(document.document.id)
            .subscribe((documentClauses: DocumentClauseDTO[]) => {
                this.documentsService.documentClausesIdGet(document.id).subscribe((documentLoaded: DocumentClauseDTO) => {
                    if (documentClauses.length < 1 && documentLoaded.document.documentTypes.code !== DocumentTypeCodes.Template &&
                        documentLoaded.isTheDocumentPendingSendToSign) {
                        this.clauseDocumentRevisionService.setWaitForFinished(document);
                    } else {
                        this.clauseDocumentRevisionService.setUnwaitForFinished(document);
                    }
                });
            });
    }

    public calculateWaitingForFinishPromise(document: DocumentClauseDTO): Observable<DocumentClauseDTO[]> {
        return this.documentsService.documentClausesSearchPendingDocumentDocumentIdGet(document.document.id);
    }

    public clauseChanged(text: { rawHtml: string, cleanedHtmlWithHeader: string}) {
        if (this.lockUpdateHtml) {
            return;
        }

        const cleanedHeaderHtml = this.transformCleanHtmlService.cleanHeader(text.cleanedHtmlWithHeader);
        this.clauseModified = cleanedHeaderHtml;
    }

    public OpenModalExpirationDate() {
        if (this.isDocumentATemplate) {
            return;
        }
        const dataSource: any = {
            expirationDays: this.expirationDays,
            expirationDate: this.expirationDate,
        };
        const dialogButton = $localize`:@@ShareAgain:Volver a enviar a firma`;
        this.genericDialogService.showQuestion('', DocumentClauseRevisionSendToFirmComponent, dataSource, dialogButton).afterClosed()
            .subscribe((result) => {
                if (result === true) {
                    const nuevaFecha = new Date();
                    this.theNewExpirationDate(nuevaFecha);
                    const documentSignature: DocumentSignatureDTO = {
                        documentId: this.documentClause?.document.id,
                        expiredDate: this.newExpirationDate
                    };
                    this.documentService.documentSendToSignSamePeoplePut(documentSignature).subscribe((document: DocumentDTO) => {
                        const message = $localize`:@@FirmaEnviadaCorrectamente:El envío a firma se ha realizado correctamente para el documento "` + document.documentSignature.name + '"';
                        this.genericDialogService.showMessage(message);
                        this.clauseDocumentRevisionService.setUnwaitForFinished(this.documentClause);
                    }, error => {
                        const document = 'Document';
                        if (error.error[document] && error.error[document].length > 0) {
                            const errorType = error.error[document][0];
                            if (errorType === 'Document is not finished') {
                                const message = $localize`:@@DocumentoNoFinalizadoClausulaDocumentoMensaje:Algunos campos de edición no están completos. Debes completar estos campos antes de enviar a firma desde la pantalla de edición del documento`;
                                this.genericDialogService.showMessage(message);
                                return;
                            }
                        }
                        const messageError = $localize`:@@ClausulaDocumentoFirmaErrorMensaje:Se ha producido un error en el envío a firma. Puedes completar la firma desde la vista de edición del documento`;
                        this.genericDialogService.showMessage(messageError);
                    });
                } else {
                    this.documentService.documentCancelForSignaturePreviouslyIdPut(this.documentClause.document.id).subscribe(() => {
                        // This is intentional
                    }, error => {
                        const messageError = $localize`:@@ClausulaDocumentoErrorMensaje:Se ha producido un error`;
                        this.genericDialogService.showMessage(messageError);
                    });
                }
            });
    }

    public finish() {
        if (this.forSignaturePreviously) {
            this.documentSignatureService.documentsSignatureExpirationDateDocumentIdGet(this.documentClause.document.id)
                .subscribe((expirationDate: ExpirationDateDTO) => {
                    this.expirationDays = expirationDate.days;
                    this.expirationDate = expirationDate.date;
                    this.OpenModalExpirationDate();
                });
        } else {
            this.close();
        }
    }

    public theNewExpirationDate(date: Date) {
        this.newExpirationDate = date;
    }

    public isAcceptChangesDisabled(documentClause: DocumentClauseDTO): boolean {
        return !documentClause?.document?.userPermissions ||
            !documentClause?.document?.userPermissions?.canAcceptOrRejectClauseChanges;
    }

    public isRejectChangesDisabled(documentClause: DocumentClauseDTO): boolean {
        return documentClause.document?.userPermissions &&
            !documentClause.document?.userPermissions?.canAcceptOrRejectClauseChanges;
    }

    public isWaitingForFinish(documentClause: DocumentClauseDTO): boolean {
        return this.clauseDocumentRevisionService.isWaitForFinish(documentClause);
    }

}
