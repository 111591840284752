import { Observable } from 'rxjs/Observable';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, RouterLinkActive, Routes, Route } from '@angular/router';
import { AuthorizeService } from 'src/app/core/shared/services/authorize/authorize.service';
import { TabItem } from './models/tab-item.model';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ctbox-tabs',
    templateUrl: './ctbox-tabs.component.html',
    styleUrls: ['./ctbox-tabs.component.scss']
})
export class CtboxTabsComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {

    @Input() size: string;
    @Input() classTabs: string;
    @Input() customFilterEntity: any;
    @Input() propertyToUseInFilter: string;
    @Input() menuTabs: any;
    @Input() activeTab: any = null;
    @Input() public set menu(value: Array<TabItem>) {
        if (value) {
            this.navLinks = value;
            if (this.activeTab !== null) {
                this.activeLink = value.find(element => element.click === this.activeTab);
            } else {
                this.activeLink = value[0];
            }
        }
    }
    @Input() customBadgeObserver$: Observable<any>;
    @Input() IsExpandTitleContainer = false;
    @Input() refreshExtendedTitle$: Observable<any>;

    @Output() tabClicked = new EventEmitter<void>();

    public isViewInitialized = false;
    public navLinks = [];
    public activeLink = this.navLinks.length > 0 ? this.navLinks[0] : null;

    private badgeData: any;
    private onDestroy$ = new EventEmitter<void>();

    constructor(private route: ActivatedRoute,
                private changeDetector: ChangeDetectorRef,
                private loginService: AuthorizeService) {

        this.loginService.getUserInfo()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
            this.setNavLinks();
        });
    }

    public ngOnInit() {
        this.setNavLinks();
        this.configureCustomBadgeObserver();
        this.configureRefreshExtendedTitleObserver();
    }

    public ngAfterViewInit() {
        this.isViewInitialized = true;
        this.changeDetector.detectChanges();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.customFilterEntity || changes.propertyToUseInFilter) {
            this.setNavLinks();
        }
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    public isLinkActive(rla: RouterLinkActive, link: any): boolean {
        return this.isViewInitialized && (rla.isActive || this.activeLink === link);
    }

    public goLinkTab(link: any): void {
        this.activeLink = link;
        if (link?.click) {
            this.onTabClicked(link);
        }
    }

    public onTabClicked(link: any): void {
        this.tabClicked.emit(link.click);
    }

    private setNavLinks(): void {
        if (this.menuTabs) {
            this.menu = this.menuTabs;
            return;
        }
        const componentHasNavLinks = this.route.routeConfig && this.route.routeConfig.children;
        this.navLinks = componentHasNavLinks ? this.buildNavItems(this.route.routeConfig.children) : [];
        if (!this.activeLink) {
            this.activeLink = this.navLinks.length > 0 ? this.navLinks[0] : null;
        }
    }

    private buildNavItems(routes: Routes): Routes {
        const navLinks = (routes)
            .filter(route => this.routeHasNavLinks(route))
            .map(({ path = '', data }) => ({
                path,
                label: data?.breadcrumb,
                class: data?.class,
                icon: data?.icon,
                classSection: data?.classSection,
                tooltip: data?.tooltip,
                badgeValue: this.badgeData ? this.badgeData[data?.badgeProperty] : null,
                itemName: data?.itemName
            }));

        return navLinks;
    }

    private routeHasNavLinks(route: Route): boolean {
        return route.data && route.data.breadcrumb && this.hasPermission(route) && this.useCustomFilter(route);
    }

    private hasPermission(route: Route): boolean {
        return this.loginService.HasPermission(route.data.permission, route.data.permissionType);
    }

    private useCustomFilter(route: Route): boolean {
        return !this.customFilterEntity || !this.propertyToUseInFilter || !route.data[this.propertyToUseInFilter] ||
            this.customFilterEntity[route.data[this.propertyToUseInFilter]];
    }

    private configureCustomBadgeObserver(): void {
        if (!this.customBadgeObserver$) {
            return;
        }

        this.customBadgeObserver$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((badgeData) => {
                this.badgeData = badgeData;
                this.setNavLinks();
        });
    }

    private configureRefreshExtendedTitleObserver(): void {
        if (!this.refreshExtendedTitle$) {
            return;
        }

        this.refreshExtendedTitle$.pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
            this.setNavLinks();
        });
    }
}
