import { Injectable } from '@angular/core';
import { Conversion, Editor } from 'ckeditor5';
import { OptionalFragmentCheckPlugin } from '../../plugins/optional-fragment-check/optional-fragment-check-plugin';

@Injectable({
    providedIn: 'root'
})
export class FragmentCheckModelToDataViewConverterService {

    public configureConverters(conversion: Conversion) {
        this.containerConversion(conversion);
        this.checkConversion(conversion);
        this.descriptionConversion(conversion);
        this.contentConversion(conversion);
    }

    private containerConversion(conversion: Conversion) {
        conversion.for("dataDowncast").elementToElement({
            model: OptionalFragmentCheckPlugin.labelModel,
            view: (modelItem, { writer }) => {
                const isChecked = modelItem.getAttribute('checked');
                const id = modelItem.getAttribute('id');

                return writer.createContainerElement(
                    "div",
                    {
                        class: [OptionalFragmentCheckPlugin.containerClass],
                        id,
                        'checked': isChecked
                    });
                }
        });
    }

    private checkConversion(conversion) {
        conversion.for("dataDowncast").elementToElement({
            model: OptionalFragmentCheckPlugin.labelCheckModel,
            view: (modelItem, { writer }) =>
            {
                const id = modelItem.getAttribute('id');
                const name = id;
                const isChecked = modelItem.getAttribute('checked');

                return writer.createContainerElement('input', {
                    id,
                    name,
                    class: OptionalFragmentCheckPlugin.checkClass,
                    type: 'checkbox',
                    ...(isChecked && { checked: true })
                });
            }
        });
    }

    private descriptionConversion(conversion) {
        conversion.for("dataDowncast").elementToElement({
            model: OptionalFragmentCheckPlugin.labelDescriptionModel,
            view: (_modelItem, { writer }) =>
                writer.createContainerElement("div",
                {
                    class: OptionalFragmentCheckPlugin.descriptionClass,
                })
        });
    }

    private contentConversion(conversion) {
        conversion.for("dataDowncast").elementToStructure({
            model: OptionalFragmentCheckPlugin.labelContentModel,
            view: (_modelItem, { writer }) => {
                return writer.createSlot();
            }
        });
    }
}
