import { Component, ElementRef, HostBinding, Input, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { noOnlyWhiteSpacesValidator } from 'src/app/core/shared/validators/noWhiteSpacesValidator';
import { invalidCharactersValidator } from 'src/app/core/shared/validators/invalidCharactersValidator';

@Component({
    selector: 'app-ctbox-validate-name',
    templateUrl: './ctbox-validate-name.component.html'
})
export class CtboxValidateNameComponent implements OnInit {

    @ViewChild('nameInput') nameInput: ElementRef;

    @Input() controlName: string;
    @Input() public parentGroup: UntypedFormGroup;
    @Input() maxLength = 350;
    @Input() showLabel = true;
    @Input() classInput = 'name';
    @Input() showCancel = false;

    @Output() onBlur: EventEmitter<any> = new EventEmitter(null);
    @Output() onClickCancel: EventEmitter<any> = new EventEmitter(null);

    @HostBinding('className') componentClass: string;

    public ngOnInit(): void {
        this.componentClass = 'validate-name';
        if (!this.hasFormValues()) {
            this.add();
        }
    }

    public get childrenControls(): UntypedFormArray {
        return this.parentGroup.get(this.controlName) as UntypedFormArray;
    }

    public focusInput(): void {
        this.nameInput.nativeElement.focus();
    }

    public blur(event: any): void {
        if (event.relatedTarget.id === 'cancelEditFolderName') {
            return;
        }
        this.onBlur.emit(event);
    }

    public cancelName(event: any): void {
        this.onClickCancel.emit(event);
    }

    public showCancelButton(): boolean {
        return this.showCancel && !this.parentGroup.controls.name.pristine;
    }

    private add(): void {
        (this.parentGroup.controls[this.controlName] as UntypedFormControl).addValidators(
            [Validators.required, Validators.maxLength(this.maxLength),
                noOnlyWhiteSpacesValidator, invalidCharactersValidator]);
    }

    private hasFormValues(): boolean {
        return this.childrenControls.length > 0;
    }

}
