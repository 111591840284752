import { Component, Inject, Input} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-clause-document-warning-changes',
    templateUrl: './clause-document-warning-changes.component.html',
    styleUrls: ['./clause-document-warning-changes.component.scss']
})
export class ClauseDocumentWarningChangesComponent {

    @Input() nDocsToUpdate: number;
    @Input() isAnySendToSign: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data) {
        this.nDocsToUpdate = data.dataSource.nDocsToUpdate;
        this.isAnySendToSign = data.dataSource.isAnySendToSign;
    }

}
