import { Component, Input, OnInit, SimpleChanges, OnChanges, EventEmitter, OnDestroy, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { noOnlyWhiteSpacesValidator } from 'src/app/core/shared/validators/noWhiteSpacesValidator';
import { emailValidator } from 'src/app/core/shared/validators/emailValidator';
import { positiveIntegerNumberValidator } from 'src/app/core/shared/validators/positiveIntegerNumberValidator';
import { TemplateWorkflowSchemaDto } from 'src/app/api/model/templateWorkflowSchemaDto';
import { WorkflowType } from 'src/app/core/standard/enums/workflow/workflow-type.enum';
import { WorkflowPermissionType } from 'src/app/core/standard/enums/workflow/workflow-permission-type.enum';
import { WorkflowModeEdition } from 'src/app/core/standard/enums/workflow/workflow-edition-type.enum';
import { Subscription } from 'rxjs';
import { DocumentWorkflowDto, MasterData, WorkflowPermissionTypesService } from 'src/app/api';
import { nameValidator } from 'src/app/core/shared/validators/nameValidator';
import { DocumentWorkflowDtoOperation } from 'src/app/api/model/documentWorkflowDtoOperation';
import { OperationType } from 'src/app/api';
import { DocumentWorkflowService } from 'src/app/api';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';

@Component({
    selector: 'ctbox-workflow-user',
    templateUrl: './ctbox-workflow-user.component.html',
    styleUrls: ['./ctbox-workflow-user.component.scss']
})

export class CtboxWorkflowUserComponent implements OnInit, OnDestroy, OnChanges {

    @Input() public numUsers: number;
    @Input() public index: number;
    @Input() public parentGroup: UntypedFormGroup[];
    @Input() public modeEdition = WorkflowModeEdition.editionTemplate;
    @Input() public user: DocumentWorkflowDto | TemplateWorkflowSchemaDto;
    @Input() public workFlowTitle: string;
    @Input() public workFlowStarted = false;

    @Output() workflowUserChange = new EventEmitter<DocumentWorkflowDto | TemplateWorkflowSchemaDto>();
    @Output() deleteUserEvent = new EventEmitter<DocumentWorkflowDto | TemplateWorkflowSchemaDto>();
    @Output() resumeWorkflowSelected = new EventEmitter<void>();

    public isEditable: boolean;
    public isEmailEditable: boolean;
    public isDaysDisabled = true;
    public isMessageDisabled = true;
    public currentCreatorEmail: string;
    public invalidEmails: string[] = [];
    public formUser: UntypedFormGroup;
    public maxNameLength = 100;

    public fields = {
        id: 'id', name: 'name', email: 'email', expirationDays: 'expirationDays',
        subject: 'subject', message: 'message', fillDataPermision: 'fillDataPermision',
        commentPermission: 'commentPermission', workflowType: 'workflowType',
    };

    public emailSubject: string;
    public approvalEmailSubject = $localize`:@@PlantillaWorkflowAprobacionAsuntoEmail:ContractBOX Gestión: Fase de aprobación - @documento`;
    public negotiationEmailSubject = $localize`:@@PlantillaWorkflowNegociacionAsuntoEmail:ContractBOX Gestión: Fase de negociación - @documento`;
    public emailBody = $localize`:@@PlantillaWorkflowCuerpoEmail:El usuario @remitente te ha enviado un documento que requiere tu revisión. Tienes un plazo de @días para dar tu aprobación o bien indicar los motivos de no conformidad.`;
    public workflowPermissionTypes: MasterData[];

    private keyEnterCode = 13;
    private userFormSubscription: Subscription;

    constructor(private apiWorkflowPermissionType: WorkflowPermissionTypesService,
                private apiDocumentWorkflow: DocumentWorkflowService,
                private genericDialogService: GenericDialogService) { }

    public ngOnInit(): void {
        if (this.user) {
            this.initFormModel();
            this.setUserWorkflow();
            this.index += 1;
        }
        this.isEditable = this.modeEdition === WorkflowModeEdition.editionTemplate;
        this.isEmailEditable = this.modeEdition === WorkflowModeEdition.editionDocument;
        this.apiWorkflowPermissionType.masterdataManagementWorkflowPermissionTypesGet().subscribe((masterdata: MasterData[]) => {
            this.workflowPermissionTypes = masterdata;
        });
    }

    public ngOnDestroy(): void {
        if (this.userFormSubscription) {
            this.userFormSubscription.unsubscribe();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.currentCreatorEmail) {
            this.setEmailsNotValids();
        }
    }

    public setEmailsNotValids() {
        this.invalidEmails = [];
        this.invalidEmails.push(this.currentCreatorEmail);
    }

    public controlKeyEnter(event): void {
        if (event.charCode === this.keyEnterCode) {
            event.preventDefault();
        }
    }

    public deleteUser(user: TemplateWorkflowSchemaDto | DocumentWorkflowDto ): void {
        this.parentGroup.splice(this.user.order - 1, 1);
        this.deleteUserEvent.emit(user);
    }

    public hasSeveralUser(): boolean {
        return this.isEditable && (this.numUsers > 1);
    }
    public isBlocked(): boolean {
        return this.modeEdition === WorkflowModeEdition.editionBlocked;
    }

    public editMessageMode(): boolean {
        this.isMessageDisabled = false;
        this.formUser.controls.subject.enable();
        this.formUser.controls.message.enable();
        return this.isMessageDisabled;
    }

    public finishSubjectEdit(): boolean {
        this.isMessageDisabled = true;
        this.formUser.controls.subject.disable();
        return this.isMessageDisabled;
    }

    public finishMessageEdit(): boolean {
        this.isMessageDisabled = true;
        this.formUser.controls.message.disable();
        return this.isMessageDisabled;
    }

    public editDaysMode(): boolean {
        this.isDaysDisabled = !this.isDaysDisabled;
        return this.isDaysDisabled;
    }

    public finishExpirationDaysEdit() {

        if (this.modeEdition === WorkflowModeEdition.editionTemplate ||
            this.formUser.controls.expirationDays.errors) {
            return;
        }

        const newExpirationDays = this.formUser.controls.expirationDays.value;
        const documentWorkflowId = this.formUser.controls.id.value;
        const operations: DocumentWorkflowDtoOperation[] = [];
        const operation: DocumentWorkflowDtoOperation = {
            operationType: OperationType.NUMBER_0,
            op: 'replace',
            path: 'expirationDays',
            value: newExpirationDays
        };

        operations.push(operation);

        this.apiDocumentWorkflow.documentWorkflowDocumentWorkflowIdPatch(documentWorkflowId, operations).subscribe(() => {
        }, () => {
            const message = $localize`:@@GuardadoDocumentWorkflowErrorNensaje:Se ha producido un error al guardar`;
            this.genericDialogService.showMessage(message);
        });

        this.isDaysDisabled = true;
        return this.isDaysDisabled;
    }

    public getDescriptionPermission(code: number) {
        switch (code) {
            case WorkflowPermissionType.FillData: return $localize`:@@CompletarDatos:Completar datos`;
            case WorkflowPermissionType.AddComments: return $localize`:@@AñadirComentarios:Añadir comentarios`;
        }
    }

    public resumeWorkflow() {
        this.resumeWorkflowSelected.emit();
    }

    private initFormModel(): void {
        this.formUser = new UntypedFormGroup({});
        this.formUser.addControl(this.fields.id, new UntypedFormControl(null));
        this.formUser.addControl(this.fields.name, new UntypedFormControl(
            null, [Validators.maxLength(this.maxNameLength),
            Validators.required,
            nameValidator,
            noOnlyWhiteSpacesValidator]));
        this.formUser.addControl(this.fields.email, new UntypedFormControl(
            null, [Validators.required, noOnlyWhiteSpacesValidator, emailValidator]));
        this.formUser.addControl(this.fields.expirationDays, new UntypedFormControl(
            null, [Validators.required, noOnlyWhiteSpacesValidator, positiveIntegerNumberValidator, Validators.max(365)]));
        this.formUser.addControl(this.fields.subject, new UntypedFormControl(
            null, [Validators.required, Validators.maxLength(60), noOnlyWhiteSpacesValidator]));
        this.formUser.addControl(this.fields.message, new UntypedFormControl(
            null, [Validators.required, Validators.maxLength(5000), noOnlyWhiteSpacesValidator]));
        this.formUser.addControl(this.fields.fillDataPermision, new UntypedFormControl(null));
        this.formUser.addControl(this.fields.commentPermission, new UntypedFormControl(null));
        this.formUser.addControl(this.fields.workflowType, new UntypedFormControl(null));
    }

    private setUserWorkflow(): void {
        this.formUser.controls.id.setValue(this.user.id);
        this.formUser.controls.name.setValue(this.user.fullName);
        this.formUser.controls.email.setValue(this.user.email);
        this.formUser.controls.expirationDays.setValue(this.user.expirationDays.toString());
        this.emailSubject =
            (this.user.workflowType.code === WorkflowType.Approval) ? this.approvalEmailSubject : this.negotiationEmailSubject;
        this.formUser.controls.subject.setValue(this.emailSubject);
        this.formUser.controls.message.setValue(this.emailBody);
        this.formUser.controls[this.fields.fillDataPermision]
            .setValue(this.user.permissionType.find(x => x.code === WorkflowPermissionType.FillData));
        this.formUser.controls[this.fields.commentPermission]
            .setValue(this.user.permissionType.find(x => x.code === WorkflowPermissionType.AddComments));
        this.formUser.controls[this.fields.workflowType]
            .setValue(this.user.workflowType.code === WorkflowType.Approval ? WorkflowType.Approval : WorkflowType.Negotiation);

        this.userFormSubscription = this.formUser.valueChanges.subscribe(value => {
            this.user.id = value.id;
            this.user.fullName = value.name;
            this.user.email = value.email;
            this.user.expirationDays = Number(value.expirationDays);
            this.user.subject = value.subject;
            this.user.emailBody = value.message;
            this.user.workflowType.code = value.workflowType;

            if (value.fillDataPermision !== null && value.fillDataPermision !== undefined) {
                const index = this.user.permissionType.findIndex(x => x.code === WorkflowPermissionType.FillData);

                if (value.fillDataPermision && index === -1) {
                    this.user.permissionType.push(this.workflowPermissionTypes.find(x => x.code === WorkflowPermissionType.FillData));
                }
                else if (!value.fillDataPermision && index !== -1) {
                    this.user.permissionType.splice(index, 1);
                }
            }

            if (value.commentPermission !== null && value.commentPermission !== undefined) {
                const index = this.user.permissionType.findIndex(x => x.code === WorkflowPermissionType.AddComments);

                if (value.commentPermission && index === -1) {
                    this.user.permissionType.push(this.workflowPermissionTypes.find(x => x.code === WorkflowPermissionType.AddComments));
                }
                else if (!value.commentPermission && index !== -1) {
                    this.user.permissionType.splice(index, 1);
                }
            }
        });

        this.user.subject = this.emailSubject;
        this.user.emailBody = this.emailBody;

        this.parentGroup.push(this.formUser);
    }

    public showLiteralOnlyRead(): boolean {
        return this.modeEdition === WorkflowModeEdition.editionDocument &&
               !this.user.permissionType.some(wf => wf.code === WorkflowPermissionType.FillData);
    }
}
