import { Element, Range, TreeWalkerValue, Writer } from 'ckeditor5';
import { RadioElements, RadioModel } from '../../models/radio/radio-model';
import { RadioPlugin } from '../../plugins/radio/radio-plugin';
import { RadioCommand } from './radio-command';

export default class AddOptionsCommand extends RadioCommand {

    private readonly helpTextOptionMessage = $localize`:@@TextoAyudaOpcionRadioPlugin:Texto ayuda Opción`;
    private readonly contentOptionMessage = $localize`:@@ContenidoOpcionRadioPlugin:Contenido Opción`;

    public override execute(model: RadioModel, totalOptionsInRadioGroup: number): void {
        const editor = this.editor;

        editor.model.change(writer => {
            const range = writer.createRangeIn(editor.model.document.getRoot()!);
            let radioOptionModel: TreeWalkerValue;
            let radioModel: TreeWalkerValue;

            this.findRadioModel(range, model, radioOptionModel!, radioModel!);

            this.setEachOptionConfig(model, totalOptionsInRadioGroup, writer, radioOptionModel!);

            writer.setAttribute('optionsCount', totalOptionsInRadioGroup, radioModel!.item);
        });
    }

    private setEachOptionConfig(model: RadioModel, totalOptionsInRadioGroup: number, writer: Writer, radioOptionModel: TreeWalkerValue) {
        for (let index = model.optionsCount!; index < totalOptionsInRadioGroup; index++) {

            const option = this.createOption(writer, index);

            const check = this.createCheck(writer, model);

            const description = this.createDescription(writer);

            const content = this.createContent(writer);

            const radioElements: RadioElements = { description, content, option, check };

            this.addOptionText(writer, index, radioElements);

            this.addOptionToWriter(writer, radioOptionModel, radioElements);
        }
    }

    private createContent(writer: Writer): Element {
        return writer.createElement(RadioPlugin.MODEL_ENTITIES.content.model, {});
    }

    private createDescription(writer: Writer): Element {
        return writer.createElement(RadioPlugin.MODEL_ENTITIES.description.model, {});
    }

    private createCheck(writer: Writer, model: RadioModel): Element {
        return writer.createElement(RadioPlugin.MODEL_ENTITIES.input.model, {
            'dataName': model.id
        });
    }

    private createOption(writer: Writer, index: number): Element {
        return writer.createElement(RadioPlugin.MODEL_ENTITIES.option.model, {
            'position': index.toString()
        });
    }

    private findRadioModel(range: Range, model: RadioModel, radioOptionModel: TreeWalkerValue, radioModel: TreeWalkerValue): void {
        for (const value of range.getWalker()) {
            if (value.item.hasAttribute('dataName') && value.item.getAttribute('dataName') === model.id && !radioOptionModel) {
                radioOptionModel = value;
            }
            if (value.item.hasAttribute('id') && value.item.getAttribute('id') === model.id && !radioModel) {
                radioModel = value;
            }
            if (!!radioOptionModel && !!radioModel) {
                break;
            }
        }
    }

    private addOptionText(writer: Writer, index: number, radioElements: RadioElements) {
        const descriptionDefaultText = writer.createText(`(${this.helpTextOptionMessage} ${index + 1})`);
        const descriptionDefaultTextElement = writer.createElement('paragraph');
        const contentDefaultTextElement = writer.createElement('paragraph');
        const contentDefaultText = writer.createText(`${this.contentOptionMessage} ${index + 1}`);

        writer.append(descriptionDefaultText, descriptionDefaultTextElement);
        writer.append(descriptionDefaultTextElement, radioElements.description);
        writer.append(contentDefaultText, contentDefaultTextElement);
        writer.append(contentDefaultTextElement, radioElements.content);
    }

    private addOptionToWriter(writer: Writer, radioOptionModel: any, radioElements: RadioElements) {
        writer.append(radioElements.check, radioElements.option);
        writer.append(radioElements.description, radioElements.option);
        writer.append(radioElements.content, radioElements.option);
        writer.append(radioElements.option, radioOptionModel!.item.parent!.parent!);
    }
}
