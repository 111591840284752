import { SignatureUserLocationParametersDTO } from 'src/app/api/model/signatureUserLocationParametersDTO';
import {
    Component, Input, OnInit, SimpleChanges, OnChanges, ViewChild, ElementRef,
    Output, EventEmitter, OnDestroy
} from '@angular/core';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { DocumentDTO, DocumentSignatureDTO, SignatureUserDTO } from 'src/app/api';
import { IDocumentSignatureNoEditableService } from 'src/app/core/standard/services/documents/document-signature-no-editable/document-signature-no-editable.service.interface';
import { Subscription } from 'rxjs-compat';
import { IDocumentEditionService } from 'src/app/pages/standard/contracts-library/services/document-edition/document-edition.service.interface';
import { ThemePalette } from '@angular/material/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { SendDataService } from 'src/app/core/shared/services/send-data/send-data.service';

@Component({
    selector: 'app-cb-pdf-viewer',
    templateUrl: './cb-pdf-viewer.component.html',
    styleUrls: ['./cb-pdf-viewer.component.scss']
})
export class CBPdfViewerComponent implements OnInit, OnChanges, OnDestroy {

    @Input() pdfFile: File;
    @Input() canAddSigner: boolean;
    @Input() mainAncestorComponent: ElementRef;
    @Input() canEdit: boolean;
    @Output() addSignatureToPdf = new EventEmitter<SignatureUserDTO>();
    @Output() deleteSignatureToPdf = new EventEmitter<SignatureUserDTO>();
    @Output() documentChange = new EventEmitter<DocumentDTO>();

    @ViewChild('file') file: ElementRef;
    @ViewChild('docViewer') docViewer: PdfViewerComponent;

    arrayContent: any;
    pdfSrc: ArrayBuffer;

    private filePagesRendered = new Set<number>();

    static readonly fixedOffset = 145;
    static readonly relativeOffset = 160;
    static readonly zeroPixels = '0px';
    static readonly zeroAuto = '0 auto';
    static readonly guidEmpty = '00000000-0000-0000-0000-000000000000';
    static readonly borderBox = 'border-box';

    ARRAY_BUFFER_METHOD = 'arrayBuffer';
    documentSignatureNoEditableSubscription: Subscription;
    documentSubscription: Subscription;
    documentSignature: DocumentSignatureDTO;
    color: ThemePalette = 'primary';
    mode: ProgressBarMode = 'determinate';
    progressBarValue = 0;
    signatureUserPreview: SignatureUserDTO;
    isPreviewEnable = false;
    isPreviewDragging = false;
    isMouseInPreview = false;
    isMouseInButtonAdd = false;
    scrollSubscription: Subscription;
    elementScrolled: any;

    pages: { width: number, height: number, rotation: number }[];
    isDocumentAlreadyLoadedInViewer = false;

    public signatureDefaultText = $localize`:@@FirmantePorDefecto:Firmante`;
    public defaultSigner: string;

    constructor(private documentSignatureNoEditableService: IDocumentSignatureNoEditableService,
                private documentEditionService: IDocumentEditionService,
                private sendDataService: SendDataService) {
        this.scrollSubscription = this.sendDataService.getData().subscribe(x => {
            this.elementScrolled = x;

        });
    }

    ngOnInit(): void {
        this.readFile();

        this.documentSubscription = this.documentEditionService.getDocumentSubscription()
            .subscribe((document: DocumentDTO) => {
                this.documentSignatureNoEditableService.setCurrentDocumentSignature(document.documentSignature);
                this.documentSignature = document.documentSignature;
                this.documentSignatureNoEditableService.resetHasChangesInFile();

            });

        this.documentSignatureNoEditableSubscription = this.documentSignatureNoEditableService.getDocumentSignatureSubscription()
            .subscribe((documentSignature: DocumentSignatureDTO) => {
                this.documentSignature = documentSignature;
            });

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pdfFile) {
            this.readFile();
        }
    }

    ngOnDestroy(): void {
        if (this.documentSubscription) {
            this.documentSubscription.unsubscribe();
        }
        if (this.documentSignatureNoEditableSubscription) {
            this.documentSignatureNoEditableSubscription.unsubscribe();
        }

    }

    public hasUnsavedChanges(): boolean {
        return this.documentSignatureNoEditableService.getHasChangesInFile();
    }

    public resetUnsavedChanges(): void {
        this.documentSignatureNoEditableService.resetHasChangesInFile();
    }

    textLayerRendered(e: { source: any, pageNumber: number, numTextDivs: number }) {
        this.isDocumentAlreadyLoadedInViewer = false;
        setTimeout(() => {
            this.filePagesRendered.add(e.pageNumber);
            this.progressBarValue = (100 * this.filePagesRendered.size) / this.docViewer.pdfViewer.pagesCount;
            this.isDocumentAlreadyLoadedInViewer = this.filePagesRendered.size >= this.docViewer.pdfViewer.pagesCount;

            if (this.isDocumentAlreadyLoadedInViewer) {
                this.filePagesRendered.clear();
            }
        });

    }

    readFile() {
        if (!this.pdfFile) {
            this.pdfSrc = new ArrayBuffer(0);
            return;
        }
        this.isDocumentAlreadyLoadedInViewer = false;

        this.filePagesRendered.clear();

        this.pdfFile[this.ARRAY_BUFFER_METHOD]().then((buffer: ArrayBuffer) => {
            this.arrayContent = buffer;
        });
    }

    addSigner() {
        const signatureUserLocationParam = this.getSignatureUserLocationParam();
        this.signatureUserPreview = {
            role: this.documentSignatureNoEditableService.getDefaultRolNotInSigners(this.signatureDefaultText),
            documentSignatureId: this.documentSignature?.id,
            signatureUserLocationParameters: signatureUserLocationParam,
            order: this.documentSignatureNoEditableService.getCurrentDocumentSignature().signaturesUsers.length
        };

        this.documentSignatureNoEditableService.addSignatureUser(this.signatureUserPreview);

    }

    deleteSigner(signerToDelete: SignatureUserDTO) {
        this.documentSignatureNoEditableService.removeSignatureUser(signerToDelete?.role);
    }

    getSignatureUserLocationParam(): SignatureUserLocationParametersDTO {
        const signParameters: SignatureUserLocationParametersDTO = {
            page: 1,
            posX: 1,
            posY: 1,
            sizeX: 60,
            sizeY: 23,
        };

        return signParameters;
    }

    addPreviewSigner() {
        const signatureUserLocationParam = this.getSignatureUserLocationParam();
        let documentSignature = this.documentSignatureNoEditableService.getCurrentDocumentSignature();

        if (documentSignature === null) {
            this.documentSubscription = this.documentEditionService.getDocumentSubscription()
                .subscribe((document: DocumentDTO) => {
                    if (document && !document.documentSignature) {
                        const newDocumentSignature: DocumentSignatureDTO = {
                            docStatus: 2,
                            documentId: document.id,
                            downloaded: false,
                            isSequential: true,
                        };

                        document.documentSignature = newDocumentSignature;
                        documentSignature = newDocumentSignature;
                        this.documentSignature = documentSignature;
                    }

                    this.documentSignatureNoEditableService.setCurrentDocumentSignature(document?.documentSignature);
                });
        }

        let signatureOrder = 0;
        let signatureId = CBPdfViewerComponent.guidEmpty;

        if (documentSignature.signaturesUsers) {
            signatureOrder = documentSignature.signaturesUsers.length;
        }

        if (this.documentSignature.id) {
            signatureId = this.documentSignature?.id;
        }

        this.signatureUserPreview = {
            role: this.documentSignatureNoEditableService.getDefaultRolNotInSigners(this.signatureDefaultText),
            documentSignatureId: signatureId,
            signatureUserLocationParameters: signatureUserLocationParam,
            order: signatureOrder
        };

        this.isPreviewEnable = true;
    }

    afterPreviewEnd() {
        this.isPreviewEnable = false;
        this.signatureUserPreview = null;
    }
}
