import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs-compat';
import { DocumentSignatureDTO, SignatureUserDTO } from 'src/app/api';

@Injectable({
  providedIn: 'root'
})
export abstract class IDocumentSignatureNoEditableService {

    abstract setCurrentDocumentSignature(documentSignature: DocumentSignatureDTO): void;
    abstract getCurrentDocumentSignature(): DocumentSignatureDTO;
    abstract getDocumentSignatureSubscription(): BehaviorSubject<DocumentSignatureDTO>;
    abstract changeRoleSignatureUser(oldRole: string, newRole: string): void;
    abstract changeSignatureLocationParametersUser(signatureUser: SignatureUserDTO): void;
    abstract getCurrentRolesInSigners(): string[];
    abstract getDefaultRolNotInSigners(defaultSignerFormat: string): string;
    abstract getSignatureUserByRole(role: string): SignatureUserDTO;
    abstract addSignatureUser(signatureUser: SignatureUserDTO): void;
    abstract removeSignatureUser(role: string): void;
    abstract getHasChangesInFile(): boolean;
    abstract resetHasChangesInFile(): void;

}
