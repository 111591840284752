<div class="viewer">
    <div class="viewer-header comparation">
        <div class="viewer-info size-50">
            <div class="viewer-title" *ngIf="pendingDocuments > 0">
                <mat-icon class="info-icon" matTooltip="Comparativa de cambios realizados entre la última versión de la plantilla revisada y la actual">info</mat-icon>
                <div class="viewer-title-label" i18n="@@DocumentoPlantillaLldEditadaTitulo">Plantilla laleydigital:</div>
                <div class="viewer-title-name"> {{templateName}}</div>
            </div>
        </div>
        <div class="viewer-info size-50">
            <div class="viewer-title">
                <div class="viewer-title-label">{{templateDocumentType}}</div>
                <div class="viewer-title-name">{{templateDocumentName}}</div>
            </div>
        </div>
    </div>
    <div class="viewer-body comparation header">
        <div class="viewer-html  size-50" *ngIf="pendingDocuments > 0">
            <div *ngIf="existCo" class="viewer-comment">
                <div class="comment">
                    <div class="comment-title" i18n="@@ComentarioDeLaActualizacion">Comentario de la actualización:</div>
                    <app-html-viewer #templateViewerCO [html]="templateCoText" className="comment-text" [addScrollSm]="true" [replaceFilledInput]="false"></app-html-viewer>
                </div>
            </div>
            <app-html-comparer [hidden]="!showComparer()" #templatesComparer [currentHtml]="templateOldVersion" [newHtml]="templateNewVersion" [alreadyInComparation]="false" [addScrollSm]="true" [replaceFilledInput]="false"></app-html-comparer>
            <app-html-viewer [hidden]="showComparer()" #comparedViewer [html]="templateNewVersion" [showAsReadOnly]="true"></app-html-viewer>
        </div>
        <div class="viewer-html  size-50">
            <app-wysiwyg-editor class="wysiwyg viewer-html" *ngIf="pendingDocuments > 0" #documentEditor [dataEditor]="documentModified" [permissionEditor]="permissionConfig" [pluginConfiguration]="pluginConfig" [resetHasUnsavedData]="resetUnsavedChangesNotifier"
                [isReadOnly]="isReadOnly" (editorReady)="editorReady()" (changeContent)="modifiedHtml($event)" (hasUnsavedChanges)="hasUnsavedChangesEvent($event)">
            </app-wysiwyg-editor>
            <app-html-viewer *ngIf="pendingDocuments < 1" #documentViewer [html]="documentModified" [showAsReadOnly]="true"></app-html-viewer>
        </div>
    </div>
    <div class="viewer-footer">
        <div class="viewer-actions" *ngIf="pendingDocuments > 0">
            <ctbox-button buttonType="primary" buttonIcon="check" buttonColor="accent" buttonText="Finalizar cambios" i18n-buttonText="@@FinalizarCambios" [isDisabled]="!document?.userPermissions?.canReviewTemplateChanges || !document?.userPermissions?.canSave" (buttonClick)="acceptChanges()"></ctbox-button>
            <ctbox-button buttonType="primary" buttonIcon="save" buttonText="Guardar" i18n-buttonText="@@Guardar" [isDisabled]="!document?.userPermissions?.canSave" (buttonClick)="save()"></ctbox-button>
        </div>
    </div>
</div>