import { ClauseDTO } from 'src/app/api';
import { BehaviorSubject, Observable, Subject } from 'rxjs-compat';
import { EventEmitter, Injectable } from '@angular/core';
import { LockedFileResultStatus } from '../../models/locked-file-result-status';

@Injectable({
  providedIn: 'root',
})
export class ClausesChangesService {
    private name = new BehaviorSubject<string>('');
    private clause = new BehaviorSubject<ClauseDTO>(null);
    private clauseNeedToBeReload = new Subject<string>();
    private onClauseFolderNeedToBeReload$ = new EventEmitter<void>();
    private onClauseListNeedToBeReload$ = new EventEmitter<void>();

    private clauseLock = new BehaviorSubject<LockedFileResultStatus>(LockedFileResultStatus.successful);


    public getClauseNameSubscription(): Observable<string> {
        return this.name.asObservable();
    }

    public setClauseName(newName: string): void {
        this.name.next(newName);
    }

    public getClauseSubscription(): Observable<ClauseDTO> {
        return this.clause.asObservable();
    }

    public getCurrentClause(): ClauseDTO {
        return this.clause.getValue();
    }

    public setClause(clause: ClauseDTO): void {
        this.clause.next(clause);
    }

    public cleanClause(): void {
        this.clause.next(null)
    }

    public getClauseNeedToBeReloadSubscription(): Observable<string> {
        return this.clauseNeedToBeReload.asObservable();
    }

    public notifyClauseNeedToBeReload(clauseId: string): void {
        this.clauseNeedToBeReload.next(clauseId);
    }

    public getClauseFolderNeedToBeReloadSubscription(): Observable<void> {
        return this.onClauseFolderNeedToBeReload$.asObservable();
    }

    public notifyClauseFolderNeedToBeReload(): void {
        this.onClauseFolderNeedToBeReload$.emit();
    }

    public getClauseFolderListToBeReloadSubscription(): Observable<void> {
        return this.onClauseListNeedToBeReload$.asObservable();
    }

    public notifyClauseListNeedToBeReload(): void {
        this.onClauseListNeedToBeReload$.emit();
    }

    public getClauseLocked(): Observable<LockedFileResultStatus> {
        return this.clauseLock.asObservable();
    }

    public setClauseLocked(clauseLock: LockedFileResultStatus): void {
        this.clauseLock.next(clauseLock);
    }
}
