import { Injectable } from '@angular/core';
import { DowncastWriter, ViewContainerElement, ViewEditableElement } from 'ckeditor5';
import { RadioPlugin } from '../../plugins/radio/radio-plugin';

@Injectable({
    providedIn: 'root'
})
export class RadioEditionViewUtilsService {

    constructor() { }

    public createRadiusEditorView(modelItem: any, viewWriter: DowncastWriter): ViewContainerElement {
        const optionsCount = modelItem.getAttribute("optionsCount");
        const id = modelItem.getAttribute("id");

        return viewWriter.createContainerElement(
            "div",
            {
                class: [RadioPlugin.MODEL_ENTITIES.container.editonView],
                id,
                'data-options-count': optionsCount
            }
        );
    }

    public createOptionEditionView(modelItem: any, viewWriter: DowncastWriter): ViewContainerElement {
        const dataPosition = modelItem.getAttribute(RadioPlugin.MODEL_ENTITIES.optionPosition.model);

        return viewWriter.createContainerElement("div",
            {
                class: [RadioPlugin.MODEL_ENTITIES.option.editonView],
                'data-position': dataPosition
            });
    }

    public createRadiusElementEditionView(modelItem: any, viewWriter: DowncastWriter): ViewContainerElement {
        const nameGroup = modelItem.getAttribute(RadioPlugin.MODEL_ENTITIES.groupName.model);

        const checked = modelItem.getAttribute(RadioPlugin.ATTRIBUTE_CHECKED);

        const viewElement = viewWriter.createContainerElement('input', {
            class: [RadioPlugin.MODEL_ENTITIES.input.editonView],
            type: 'radio',
            name: nameGroup,
            ...(checked && { checked: true })
        });

        return viewElement;
    }

    public createDescriptionElementEditionView(viewWriter: DowncastWriter): ViewEditableElement {

        return viewWriter.createEditableElement("div", {
            class: [RadioPlugin.MODEL_ENTITIES.description.editonView],
        });
    }

    public createContentElementEditionView(viewWriter: DowncastWriter): ViewEditableElement {

        return viewWriter.createEditableElement("div",
            {
                class: [RadioPlugin.MODEL_ENTITIES.content.editonView],
            });
    }
}
