import { Directive, Injectable, OnDestroy } from "@angular/core";
import { ContextualBalloon, Editor, Widget, ClickObserver, ViewDocumentClickEvent } from "ckeditor5";
import { ClauseModel } from "../../models/clause/clause-model";
import { MatDialogRef } from '@angular/material/dialog';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { GenericDialogConfig } from "src/app/core/shared/models/generic-dialog-config.model";
import { ListClauseModalComponent } from 'src/app/shared/components/wysiwyg-editor/list-clause-modal/list-clause-modal.component';
import { dialogTypes } from "../../../../ctbox-generic-dialog-data/ctbox-generic-dialog-data.component";
import { ITransformCleanHtmlService } from "src/app/core/shared/services/transform-clean-html/transform-clean-html.service.interface";
import { IClausesNavigationService } from 'src/app/core/standard/services/clauses/clause-navigation/clauses-navigation.service.interface';
import ClauseAddCommand from "../../commands/clause/clauses-add-command";
import ClauseDeleteCommand from "../../commands/clause/clauses-delete-command";
import ClauseGoToCommand from "../../commands/clause/clauses-go-to-command";
import { BasePlugin } from "../base/base-plugin";
import ClauseBalloonView from '../../ui/clause/clause-balloon-view.directive';
import { ClauseSchemaService } from "../../schema/clause/clause-schema.service";
import { UserInterfaceService } from "../../ui/user-interface.service";
import { ToolbarButtonModel } from '../../models/base/toolbar-button-model';
import { ClauseModelToDataViewConverterService } from '../../converters/clause/clause-model-to-data-view-converter.service';
import { ClauseModelToEditorViewConverterService } from '../../converters/clause/clause-model-to-editor-view-converter.service';
import { ClauseDataViewToModelConverterService } from '../../converters/clause/clause-data-view-to-model-converter.service';
import { UI_CLASSES } from "../../ui/styles/styles-constants";

@Directive({
    selector: 'clauses-plugin',
})
@Injectable({
    providedIn: 'root'
})
export class ClausesPlugin extends BasePlugin implements OnDestroy {

    public static readonly PLUGIN_NAME = "Clauses";
    public static readonly EMBEDDED_CLAUSE = "embedded-clause";
    public static readonly CLAUSE_IN_EDITOR = "clause-in-editor";
    public static readonly DELETE_COMMAND_NAME = "delete-clause";
    public static readonly GO_TO_COMMAND_NAME = "go-to-clause";
    public static readonly INSERT_COMMAND_NAME = "add-embedded-clause";
    public static readonly ID = 'id';
    public static readonly DESCRIPTION = 'description';
    public static readonly DIV = 'div';
    public static readonly TOOLBAR_NAME_STRING = $localize`:@@ClausulaBotonera:Cláusula`;
    public static readonly DELETE_OPTION = $localize`:@@BorrarClausulaEmbebida:BORRAR`;
    public static readonly GO_TO_CLAUSE = $localize`:@@IrAClausulaEmbebida:IR A CLAUSULA`;

    public static readonly MODEL_ENTITIES = {
        'class'         :   { model: ClausesPlugin.EMBEDDED_CLAUSE,      dataView: ClausesPlugin.CLAUSE_IN_EDITOR,      editonView: ClausesPlugin.CLAUSE_IN_EDITOR   },
    };
    public static genericDialog: GenericDialogService;
    public static cleanHtmlService: ITransformCleanHtmlService;
    public static clausesNavigationService: IClausesNavigationService;

    private clauseSchemaService: ClauseSchemaService;

    private dataViewToModelConverter: ClauseDataViewToModelConverterService;
    private modelToDataViewConverter: ClauseModelToDataViewConverterService;
    private modelToEditorViewConverter: ClauseModelToEditorViewConverterService;

    private userInterfaceService: UserInterfaceService;
    private balloonView: ClauseBalloonView;
    private lastDialog: MatDialogRef<any, any>;

    protected mappers = [
        ClausesPlugin.MODEL_ENTITIES.class.dataView
    ];

    protected commands = {
        [ClausesPlugin.INSERT_COMMAND_NAME]: ClauseAddCommand,
        [ClausesPlugin.DELETE_COMMAND_NAME]: ClauseDeleteCommand,
        [ClausesPlugin.GO_TO_COMMAND_NAME]: ClauseGoToCommand
    };

    protected toolbarButton: ToolbarButtonModel = {
        icon: UI_CLASSES.SVG_ICONS.CLAUSE_EDITOR_ICON,
        pluginToolbarElementName: ClausesPlugin.pluginToolbarElementName,
        buttonText: ClausesPlugin.TOOLBAR_NAME_STRING,
        tooltip: $localize`:@@InsertarClausulaEtiquetaBotonBotonera:Inserta una cláusula de la biblioteca`,
        hasTooltip: true,
        hasText: true
    };

    constructor(editor: Editor) {
        super(editor);
        this.dataViewToModelConverter = new ClauseDataViewToModelConverterService();
        this.modelToDataViewConverter = new ClauseModelToDataViewConverterService();
        this.modelToEditorViewConverter = new ClauseModelToEditorViewConverterService();
        this.clauseSchemaService = new ClauseSchemaService();
        this.userInterfaceService = new UserInterfaceService();
    }

    public static get requires() { return [Widget, ContextualBalloon]; }
    public static get pluginName() { return ClausesPlugin.PLUGIN_NAME; }
    public static get pluginToolbarElementName() { return ClausesPlugin.EMBEDDED_CLAUSE; }
    public static get visualSelectionMarker() { return ClausesPlugin.EMBEDDED_CLAUSE; }

    protected defineSchema(): void {
        this.clauseSchemaService.defineSchema(this.schema);
    }

    protected defineConverters(): void {
        this.modelToEditorViewConverter.configureConverters(this.conversion);
        this.dataViewToModelConverter.configureConverters(this.conversion);
        this.modelToDataViewConverter.configureConverters(this.conversion);
    }

    protected editorInteractions(): void {
        super.setupEditorObserver(ClickObserver);
        this.enableBalloonActivators();
    }

    protected toolbarExecuteOperation(): void {
        this.openInsertClauseFromLibrary();
    }

    private enableBalloonActivators(): void {
        const viewDocument = this.editor.editing.view.document;
        this.listenTo<ViewDocumentClickEvent>(viewDocument, 'click', this.handleClickEvent.bind(this));
    }

    private handleClickEvent(): void {
        const parentClause = this.pluginUtils.getSelectedElementWithClass(this.editor, ClausesPlugin.MODEL_ENTITIES.class.editonView);
        if (!parentClause) {
            return;
        }
        const idClause = parentClause.getAttribute(ClausesPlugin.ID);
        this.showUI(idClause);
    }

    private showUI(idClauseValue?: string): void {
        if (!this.balloonView) {
            this.createBalloonView(idClauseValue);
        }

        this.userInterfaceService.addUI(this.editor, this.balloon, this.balloonView, ClausesPlugin.MODEL_ENTITIES.class.editonView, ClausesPlugin.visualSelectionMarker);
    }

    private createBalloonView(idClauseValue?: string) {
        this.balloonView = this.getBalloonView(idClauseValue);
        this.userInterfaceService.enableUserBalloonInteractions(this.editor, this.balloon, this.balloonView,
            ClausesPlugin.visualSelectionMarker, this);
    }

    private getBalloonView(idClauseValue?: string): ClauseBalloonView {

        const balloonView = new ClauseBalloonView(this.editor.locale);

        this.configureDeleteClauseButton(balloonView);
        this.configureGoToClauseButton(balloonView, idClauseValue!);

        return balloonView;
    }

    private configureDeleteClauseButton(balloonView: ClauseBalloonView) {
        balloonView.deleteButtonView.on('execute', () => {
            this.editor.execute(ClausesPlugin.DELETE_COMMAND_NAME);
            this.userInterfaceService.hideUI(this.editor, this.balloon, this.balloonView, ClausesPlugin.visualSelectionMarker, this);
        });
    }

    private configureGoToClauseButton(balloonView: ClauseBalloonView, idClauseValue: string) {
        balloonView.goToClauseButtonView.on('execute', () => {
            this.editor.execute(ClausesPlugin.GO_TO_COMMAND_NAME, idClauseValue);
            this.userInterfaceService.hideUI(this.editor, this.balloon, this.balloonView, ClausesPlugin.visualSelectionMarker, this);
        });
    }

    private openInsertClauseFromLibrary(): void {
        const config = this.getListModalConfig();
        const data = this.getDialogData();

        this.lastDialog = ClausesPlugin.genericDialog.openTemplateWithConfigAndData(data.template, config, data);

        this.lastDialog.afterClosed().subscribe((result) => {

            if (result === null) {
                return;
            }
            this.insertClauses(result);
        });
    }

    private insertClauses(clausesArray: ClauseModel[]): void {
        clausesArray.forEach(clause => this.insertClause(clause));
    }

    private insertClause(clauseInfo: ClauseModel): void {
        clauseInfo.description = ClausesPlugin.cleanHtmlService.cleanHeader(clauseInfo.description);
        const clause: ClauseModel = { id: clauseInfo.id, description: clauseInfo.description };

        this.editor.execute(ClausesPlugin.INSERT_COMMAND_NAME, clause);
    }

    private getListModalConfig(): GenericDialogConfig {
        const config = ClausesPlugin.genericDialog.getDefaultDialogConfig();
        //TODO sacar a config visible a nivel de plugin
        config.width = '50vw';
        config.height = '90vh';
        return config;
    }

    private getDialogData(): any {
        return {
            template: ListClauseModalComponent,
            displayCloseOption: true,
            displayButtonBar: true,
            dialogButton: $localize`:@@InsertarClausulaDesdeBibliotecaBoton:Insertar seleccionados`,
            dialogCloseButon: $localize`:@@Cancelar:Cancelar`,
            dialogTypes: dialogTypes.NotOverflow,
            dialogTitle: $localize`:@@InsertarClausulaDesdeBiblioteca:Insertar una cláusula desde la biblioteca`,
            primaryButtonContentObservableName: 'isValidSubscription',
            dialogContentGetResultPropertyName: 'selectedClauses',
            dialogContentGetCloseResultPropertyName: 'selectedClauses',
        };
    }
}
