import { Injectable } from '@angular/core';
import { ClauseDTO, UserPermissionsOnClauseDTO, WarningChangesClauseDTO } from 'src/app/api';

@Injectable({
  providedIn: 'root'
})
export abstract class IClausesWarningService {

    abstract setPendingDocuments(clause: ClauseDTO): void;
    abstract showClausesWarnings(userPermissions: UserPermissionsOnClauseDTO, warningChanges: WarningChangesClauseDTO): boolean;
    abstract openClauseDocumentListPreview(clauseToEdit: ClauseDTO): Promise<boolean>;
    abstract openClauseLldRevisionPreviewComponent(clauseToEdit: ClauseDTO): void;
}
