import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat';
import { ClauseCreationType } from 'src/app/core/shared/enums/clause-creation-params.enum';

@Injectable({
    providedIn: 'root'
})

export abstract class IClausesNavigationService {

    abstract getClauseName(): Observable<string>;
    abstract getClauseId(): Observable<string>;
    abstract getTocClauseId(): Observable<string>;
    abstract setTocClauseId(tocClauseId: string): void;
    abstract setClauseName(ClauseName: string): void;
    abstract getClauseCreationType(): Observable<ClauseCreationType>;

    abstract createClauseFromLldClause(tocClauseId: string): void;

    abstract goToEditionClause(id: string): void;
    abstract goToCreateClauseFromFile(): void;
    abstract goToCreateEmptyClause(): void;
    abstract goToNewClauseSelection(): void;
    abstract goToSearchClause(searchTerm: string): void;
    abstract goToLoadLocalDocument(): void;
    abstract goToMyClausesToC(): void;
    abstract goToLldToC(): void;

    abstract goToClausesLibrary(): void;
}
