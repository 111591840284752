import { ElementRef, Injectable, NgZone } from '@angular/core';
import { PathLocationStrategy } from '@angular/common';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { Md5 } from 'ts-md5/dist/md5';
import { dialogTypes } from '../../ctbox-generic-dialog-data/ctbox-generic-dialog-data.component';
import { Observable, Subject } from 'rxjs';
import { ClauseDTO } from 'src/app/api';

declare let CKEDITOR: any;

@Injectable({
  providedIn: 'root'
})
export class CkeditorReadOnlyClauseWidgetService {

    private readonly CLAUSE_EDITOR_CLASS = 'read-only-clause';
    private editor: any;
    private clauseAdded = new Subject<{relationId: string, clauseVersionId: string}>();
    private clauseRemoved = new Subject<string>();
    private clauseNavigateTo = new Subject<string>();

    private readonly CLAUSE_VERSION_ATTRI_PRE = 'embedded-clause-version-id-';
    private readonly VERSION_ID_ATTRIBUTE = 'data-clause-version-id';
    private readonly MODIFICATION_TIMESTAMP_ATTRIBUTE = 'data-clause-version-modification-timestamp';
    private readonly RELATION_DATA_NAME = 'relationId';
    private readonly VERSION_ID_DATA_NAME = 'versionId';


    constructor(
        private genericDialogService: GenericDialogService,
        private pathLocationStrategy: PathLocationStrategy,
        private ngZone: NgZone
    ) {
    }

    public addPlugin(insertClauseCmcButton: ElementRef): void {
        if (!(CKEDITOR.config.extraPlugins as string).includes('insertClauseCmcPlugin')) {
            CKEDITOR.config.extraPlugins = (CKEDITOR.config.extraPlugins as string).concat('insertClauseCmcPlugin,');
        }

        delete CKEDITOR.plugins.registered.insertClauseCmcPlugin;

        const context = this;
        CKEDITOR.plugins.add('insertClauseCmcPlugin', {
            requires: 'widget',
            icons: '',
            init: (editor: any ) => {
                this.editor = editor;
                editor.addContentsCss(this.pathLocationStrategy.getBaseHref() + 'css/clauseFromLibrary.css');
                editor.widgets.add('insertClauseCmcPlugin', {
                    button: 'insertClauseCmcButton',
                    template: this.getClauseTemplate('', '', '', '', '', new Date()),
                    requiredContent: `div(${this.CLAUSE_EDITOR_CLASS});`,
                    draggable: false,
                    destroy() {
                        context.clauseRemoved.next(this.data[context.RELATION_DATA_NAME]);
                    },
                    init() {
                        context.setDataInWidget(this);

                        context.clauseAdded.next({relationId: this.data[context.RELATION_DATA_NAME],
                                            clauseVersionId: this.data[context.VERSION_ID_DATA_NAME]});
                    },
                    focus() {
                        this.setSelected(false);
                    },
                    upcast: (element: any) => {
                        return element.name === 'div' && element.hasClass(this.CLAUSE_EDITOR_CLASS);
                    }
                });

                editor.ui.addButton('clauseFromTree', {
                    label: 'Insertar cláusula de la Biblioteca',
                    command: 'insertClause',
                    toolbar: 'forms',
                    state: CKEDITOR.TRISTATE_DISABLED
                });

                editor.on('focus', () => {
                    context.enableAddClauseButton(editor);
                });

                editor.on('blur', () => {
                    context.disableAddClauseButton(editor);
                });

                editor.addCommand('insertClause', {
                    exec: () => {
                        insertClauseCmcButton.nativeElement.click();
                    },
                });

                editor.on('contentDom', (event: any) => {
                    this.editor = event.editor;
                    const content = event.editor.document.$.body;
                    if (!content) {
                        return;
                    }
                    content.addEventListener('click', (eventClick: any) => {
                        const clickedElement = eventClick.target;

                        if (!editor.readOnly && clickedElement.id.includes('delete-embedded-clause-version')) {
                            this.removeClause(clickedElement.id);
                            const editorHeight = this.editor?.document?.getBody().$.scrollHeight;
                            this.editor.resize('100%', editorHeight);
                        }

                        if (clickedElement.id.includes('view-embedded-clause-version')) {
                            const versionId = clickedElement.id.substring(clickedElement.id.lastIndexOf('-id-') + 4 );
                            this.viewClause(versionId);
                        }

                    });
                });
            }
        });
    }

    public openInsertClauseModal(editorInstance: any, md5: Md5, componentModal: any): void {
        const editor = editorInstance;
        if (editor.readOnly) {
            return;
        }

        const config = this.genericDialogService.getBigDialogConfig();
        const data: any = {
            template: componentModal,
            displayCloseOption: true,
            displayButtonBar: true,
            dialogButton: $localize`:@@InsertarClausulaDesdeBibliotecaBoton:Insertar seleccionados`,
            dialogCloseButon: $localize`:@@Cancelar:Cancelar`,
            dialogTypes: dialogTypes.NotOverflow,
            dialogTitle: $localize`:@@InsertarClausulaDesdeBiblioteca:Insertar una cláusula desde la biblioteca`,
            primaryButtonContentObservableName: 'isValidSubscription',
            primaryButtonActionBeforeCloseObservableName: 'actionBeforeCloseFinishHim',
            primaryButtonActionAfterCloseObservableName: 'actionAfterCloseFinishHim',
            dialogContentGetResultPropertyName: 'selectedClauses',
            dialogContentGetCloseResultPropertyName: 'selectedClauses',
        };

        const dialog = this.genericDialogService.openTemplateWithConfigAndData(data.template, config, data);
        dialog.afterClosed().subscribe((result: ClauseDTO[]) => {
            if (result === null) {
                    return;
                }
            result.forEach(clause => {
                this.insertSingleClause(md5, clause, editor);
            });
        });
    }

    public getAddClauseObservable(): Observable<{relationId: string, clauseVersionId: string}> {
        return this.clauseAdded.asObservable();
    }

    public getRemoveClauseObservable(): Observable<string> {
        return this.clauseRemoved.asObservable();
    }

    public getNavigateToClauseObservable(): Observable<string> {
        return this.clauseNavigateTo.asObservable();
    }

    private getClauseTemplate(clauseRelationId: string, clauseVersionId: string,
                              hashDescription: string | Int32Array, textClause: string,
                              idClause: string, clauseModificationTimestamp: Date): string {
         return `<div class="${this.CLAUSE_EDITOR_CLASS}" data-hash="${hashDescription}" id="${this.CLAUSE_VERSION_ATTRI_PRE}${clauseRelationId}" ${this.VERSION_ID_ATTRIBUTE}="${clauseVersionId}" ${this.MODIFICATION_TIMESTAMP_ATTRIBUTE}="${clauseModificationTimestamp}">
                <div class="${this.CLAUSE_EDITOR_CLASS}-body">${textClause}</div>
                <div class="${this.CLAUSE_EDITOR_CLASS}-buttons">
                    <button class="${this.CLAUSE_EDITOR_CLASS}-icon mat-icon material-icons delete" title="Borrar cláusula" id="delete-${this.CLAUSE_VERSION_ATTRI_PRE}${clauseRelationId}">delete</button>
                    <button class="${this.CLAUSE_EDITOR_CLASS}-icon mat-icon material-icons link" title="Ir a cláusula" id="view-${this.CLAUSE_VERSION_ATTRI_PRE}${idClause}">link</button>
                </div>
                </div>`;
    }

    private insertSingleClause(md5: Md5, clause: ClauseDTO, editor: any): void {
        const clauseRelationId = crypto.randomUUID();
        const clauseVersion = clause.clauseVersions.find(element => element.version === clause.currentVersion);
        const textClause = clause.html;
        const idClause = clause.id;
        const hashDescription = md5.start().appendStr(textClause).end();
        const widget = editor.widgets.registered.insertClauseCmcPlugin.template;
        widget.source = this.getClauseTemplate(clauseRelationId, clauseVersion.id, hashDescription,
            textClause, idClause, clauseVersion.modificationDate);

        editor.execCommand('insertClauseCmcPlugin');
        this.setCursorAfterClauseNextPosition(clauseRelationId, editor);
    }

    private setCursorAfterClauseNextPosition(clauseRelationId: string, editor: any) {
        const range = editor.createRange();
        const element = editor.document.getById(this.CLAUSE_VERSION_ATTRI_PRE + clauseRelationId);
        range.moveToClosestEditablePosition(element, CKEDITOR.POSITION_AFTER_END);
        const selection = editor.getSelection();
        selection.selectRanges([range]);
    }

    private removeClause(id: string): void {
        const editor = this.editor;
        editor.fire( 'saveSnapshot' );
        const elementClickedForEditor = editor.document.getById(id);
        const widgetInstanceToDelete = editor.widgets.getByElement(elementClickedForEditor);
        widgetInstanceToDelete.element.remove();
        editor.widgets.destroy(widgetInstanceToDelete, true);
        editor.widgets.checkWidgets({ initOnlyNew: true, focusInited: false });
        editor.fire( 'saveSnapshot' );
    }

    private viewClause(id: string): void {
        this.ngZone.run(() => {
            this.clauseNavigateTo.next(id);
          });
    }

    private getRelationIdFromElement(element: any): string {
        const id = element?.getAttributes().id;
        return id.slice(this.CLAUSE_VERSION_ATTRI_PRE.length, id.length);
    }

    private getVersionIdFromElement(element: any): string {
        return  element?.getAttributes()[this.VERSION_ID_ATTRIBUTE];
    }

    private setDataInWidget(widget: any) {
        const relationId = this.getRelationIdFromElement(widget.element);
        const versionId = this.getVersionIdFromElement(widget.element);

        widget.setData(this.RELATION_DATA_NAME, relationId);
        widget.setData(this.VERSION_ID_DATA_NAME, versionId);
    }

    private disableAddClauseButton(editor: any): void {
        editor.ui.get('clauseFromTree')?.setState(CKEDITOR.TRISTATE_DISABLED);
        editor.getCommand('insertClause')?.disable();
    }

    private enableAddClauseButton(editor: any): void {
        if (editor.readOnly) {
            this.disableAddClauseButton(editor);
            return;
        }

        editor.ui.get('clauseFromTree')?.setState(CKEDITOR.TRISTATE_OFF);
        editor.getCommand('insertClause')?.enable();
    }
}
