import { Injectable } from "@angular/core";
import { Schema } from "ckeditor5";
import { SignaturePlugin } from "../../plugins/signature/signature-plugin";
import { INHERIT_ALL_FROM_VALUES } from "../schema-values";

@Injectable({
    providedIn: 'root'
})
export class SignatureSchemaService {

    public defineSchema(schema: Schema): void {
        const { SIGNATURE_IN_EDITOR, ID, ATTRIBUTE_ROLE_IS_STORED } = SignaturePlugin;
        schema.register(SIGNATURE_IN_EDITOR, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.BLOCK_OBJECT,
            allowAttributes: [ID, ATTRIBUTE_ROLE_IS_STORED],
        });
    }
}
