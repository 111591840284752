import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs-compat';
import { ClauseFolderDTO } from 'src/app/api';
import { FileNode } from 'src/app/shared/components/ctbox-tree/models/file-node.model';
import { NodeTreeActionType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-action-type.enum';
import { NodeTreeNodeType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-node-type.enum';
import { IClausesFolderTreeService } from './clauses-folder-tree.service.interface';
import { HierarchySearchFileResult } from '../../../models/hierarchy-search-file-result.model';
import { SortTypeAlphabetical } from 'src/app/shared/pipes/sort-type-alphabetical.pipe';

@Injectable({
  providedIn: 'root'
})
export class ClausesFolderTreeService extends IClausesFolderTreeService {

    private currentExpansionModel = new BehaviorSubject<SelectionModel<string>>(new SelectionModel<string>(true));

    private pipe = new SortTypeAlphabetical();
    private rootNodeName = $localize`:@@NombreRaizClausulas:Cláusulas de mi corporación`;
    private rootNodeId = '0';

    public getRootNodeName(): string {
        return this.rootNodeName;
    }

    public getRootNodeId(): string {
        return this.rootNodeId;
    }

    public setCurrentExpansionModel(selectionModel: SelectionModel<string>): void {
        this.currentExpansionModel.next(selectionModel);
    }

    public getCurrentExpansionModel(): Observable<SelectionModel<string>> {
        return this.currentExpansionModel.asObservable();
    }

    public createTreeFolder(folderDto: ClauseFolderDTO[], rootActions: NodeTreeActionType[],
                            nodeActions: NodeTreeActionType[]): FileNode[] {

        const rootNode: FileNode = this.createRootNode(rootActions);
        const tree: FileNode[] = [rootNode];

        folderDto.filter(f => !f.clauseFolderParentId).forEach(childFolders => {
            const childNode = this.transformClauseFolderToNode(childFolders, nodeActions, folderDto);
            rootNode.children.push(childNode);
        });

        rootNode.children = this.pipe.transform(rootNode.children);

        return tree;

    }

    private createRootNode(permissions: NodeTreeActionType[]): FileNode {
        return {
            id: this.rootNodeId,
            value: this.rootNodeName,
            children: [],
            type: NodeTreeNodeType.Folder,
            parentId: '',
            actions: permissions,
            isChecked: true
        };
    }

    private transformClauseFolderToNode(folder: ClauseFolderDTO, permissions: NodeTreeActionType[],
                                        allFolders: ClauseFolderDTO[]): FileNode {
        const node: FileNode = {
            id: folder.id,
            value: folder.name,
            type: NodeTreeNodeType.Folder,
            children: [],
            parentId: folder.clauseFolderParentId,
            actions: permissions,
            isChecked: true
        };

        allFolders.filter(f => f.clauseFolderParentId === folder.id).forEach(childFolder => {
            const child = this.transformClauseFolderToNode(childFolder, permissions, allFolders);
            node.children.push(child);
        });
        node.children = this.pipe.transform(node.children);
        return node;
    }

    public createTreeFolderSearchResult(folderDto: HierarchySearchFileResult[],
                                        nodeActions: NodeTreeActionType[],
                                        defaultCheckedNodes?: string[]): FileNode[] {
        const tree: FileNode[] = [];

        folderDto.forEach(folderDTO => {
            const folderSearch = this.transformClauseFolderSearchResultToNode(folderDTO, nodeActions, defaultCheckedNodes);
            tree.push(folderSearch);
        });

        return this.pipe.transform(tree);
    }

        private transformClauseFolderSearchResultToNode(
            folder: HierarchySearchFileResult,
            permissions: NodeTreeActionType[],
            defaultCheckedNodes?: string[]): FileNode {
            const node: FileNode = {
                id: folder.id,
                value: folder.name,
                type: NodeTreeNodeType.Folder,
                children: [],
                parentId: folder.parentId,
                actions: permissions,
                isChecked: (defaultCheckedNodes?.includes(folder.id)) ?? false,
                additionalData: folder.resultCount
            };

            folder.childrenFolder.forEach(childFolder => {
                const child = this.transformClauseFolderSearchResultToNode(childFolder, permissions, defaultCheckedNodes);
                node.children.push(child);
            });
            node.children = this.pipe.transform(node.children);
            return node;
        }
}
