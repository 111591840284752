<div class="main-container">
    <div class="editor-container editor-container_document-editor editor-container_include-minimap editor-container_include-style" #editorContainerElement>
        <div class="editor-container__menu-bar" #editorMenuBarElement></div>
        <div class="editor-container__toolbar" #editorToolbarElement></div>
        <div class="editor-container__minimap-wrapper">
            <div class="editor-container__editor-wrapper">
                <div class="editor-container__editor">
                    <div #editorElement>
                        <app-scroll-to-top scrollTop="top" *ngIf="showButtonTop"></app-scroll-to-top>
                        <ckeditor *ngIf="isLayoutReady" #ckeditorInstance [editor]="Editor" [config]="config" [(ngModel)]="currentData" (ready)="onReady($event)" (change)="onChangeContent($event)"></ckeditor>
                    </div>
                </div>
            </div>
            <div class="editor-container__sidebar editor-container__sidebar_minimap">
                <div #editorMinimapElement></div>
            </div>
        </div>
    </div>
</div>