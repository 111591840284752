<div class="pdf-viewer" id="pdfContainer" #pdfContainer cdkScrollable>
    <div class="pdf-viewer-buttons" *ngIf="canAddSigner">
        <ctbox-button buttonType="secondary" buttonIconSvg="signature" buttonText="Añadir firma" i18n-buttonText="@@AnadirFirma" (buttonClick)="addPreviewSigner()" [isDisabled]="!isDocumentAlreadyLoadedInViewer"></ctbox-button>
        <app-signature-with-role *ngIf="isPreviewEnable" [isPreview]="true" [canEdit]="true" [signatureUser]="signatureUserPreview" [pdfViewer]="this.docViewer" [isDocLoaded]="true" [documentWithSign]="pdfContainer" [viewerMainAncestorComponent]="mainAncestorComponent" (previewEnd)="afterPreviewEnd()"></app-signature-with-role>
    </div>
    <div class="pdf-view-container" id="outerContainer">
        <mat-progress-bar *ngIf="!isDocumentAlreadyLoadedInViewer" class="pdf-view-progress-bar" [color]="color" [mode]="mode" [value]="progressBarValue"></mat-progress-bar>
        <app-signature-with-role *ngFor="let signerUser of this.documentSignature?.signaturesUsers" [signatureUser]="signerUser" [pdfViewer]="this.docViewer" [isDocLoaded]="isDocumentAlreadyLoadedInViewer" [documentWithSign]="pdfContainer" [viewerMainAncestorComponent]="mainAncestorComponent"
            [canEdit]="canEdit" (signatureDeleteClicked)="deleteSigner($event)"></app-signature-with-role>
        <div class="pdf-container">
            <pdf-viewer class="pdf-viewer-document" id="docViewer" #docViewer [src]="this.arrayContent" [rotation]="0" [original-size]="false" [show-all]="true" [fit-to-page]="false" [zoom]="1" [zoom-scale]="'page-width'" [stick-to-page]="false" [render-text]="true"
                [external-link-target]="'blank'" [autoresize]="true" [show-borders]="true" (text-layer-rendered)="textLayerRendered($event)"></pdf-viewer>
        </div>
    </div>
</div>