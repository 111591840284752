import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs-compat';
import { LastModificationFoldersService, LastModificationFoldersDTO, PermissionsUserByUserCmcDto } from 'src/app/api';
import { PermissionUserHierarchy } from '../../../models/permission-user-hierarchy';
import { IAdministrationPermissionTemplatesFoldersService } from './administration-permission-templates-folders.service.interface';

@Injectable({
    providedIn: 'root'
})
export class AdministrationPermissionTemplatesFoldersService extends IAdministrationPermissionTemplatesFoldersService {

    private currentTemplateLoadedDate = new BehaviorSubject<Date>(new Date());

    public generateFoldersFromPermissionUserHierarchy(currentUserId: string,
                                                      permissionsUserByUser: PermissionsUserByUserCmcDto[]): PermissionUserHierarchy[] {

        const permissionUserHierarchyTree: PermissionUserHierarchy[] = [];
        let templateFolderPermissions;
        if (!currentUserId) {
            templateFolderPermissions = permissionsUserByUser[0].templateFolderPermissions;
        } else {
            templateFolderPermissions = permissionsUserByUser.find(p => p.userId === currentUserId).templateFolderPermissions;
        }

        templateFolderPermissions.filter(result => result.parentId === null).forEach((result) => {
            permissionUserHierarchyTree.push(result);
        });

        this.buildHierarchicalTreeNodes(templateFolderPermissions, permissionUserHierarchyTree);

        return permissionUserHierarchyTree;
    }

    public hasNewChangesTemplateFolders(lastModificationFoldersService: LastModificationFoldersService): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            lastModificationFoldersService.lastModificationFoldersGet().subscribe((data: LastModificationFoldersDTO) => {
                const needRefresh = data && new Date(data.lastModificationTemplateFolders) > this.getCurrentTemplateFolderLoadedDate();

                resolve(needRefresh);

            }, (error: any) => {
                resolve(true);
            });
        });
    }

    public updateTemplateFolderChangesDate(): void {
        this.currentTemplateLoadedDate.next(this.getCurrentDateUTC());
    }

    private getCurrentDateUTC(): Date {
        const now = new Date();
        return new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    }

    private getCurrentTemplateFolderLoadedDate(): Date {
        return this.currentTemplateLoadedDate.getValue();
    }

    private buildHierarchicalTreeNodes(flatNodes: PermissionUserHierarchy[], currentTree: PermissionUserHierarchy[]) {
        currentTree.forEach((node) => {
            const childrenNodes = flatNodes.filter(item => item.parentId === node.id);
            this.buildHierarchicalTreeNodes(flatNodes, childrenNodes);
            node.childrenFolder = childrenNodes;
        });
    }

}
