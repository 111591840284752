import { Directive, Injectable, OnDestroy } from "@angular/core";
import { BasePlugin } from "../base/base-plugin";
import { Editor, PositionOptions, ContextualBalloon, Widget, ViewDocumentClickEvent, ClickObserver } from "ckeditor5";
import { OptionalFragmentCheckSchemaService } from "../../schema/optional-fragment-check/optional-fragment-check-schema.service";
import { FragmentCheckDataViewToModelConverterService } from "../../converters/document-fragment-check/data-view-to-model-converter.service";
import { FragmentCheckModelToEditorViewConverterService } from "../../converters/document-fragment-check/model-to-editor-view-converter.service";
import { FragmentCheckModelToDataViewConverterService } from "../../converters/document-fragment-check/model-to-data-view-converter.service";
import DeleteOptionalFragmentCommand from "../../commands/optional-fragment-check/delete-optional-fragment-command";
import AddOptionalFragmentCommand from "../../commands/optional-fragment-check/add-optional-fragment-command";
import { UserInterfaceService } from "../../ui/user-interface.service";
import OptionalFragmentCheckBalloonView from "../../ui/optional-fragment-check/optional-fragment-check-balloon-view.directive";
import { ToolbarButtonModel } from "../../models/base/toolbar-button-model";
import { UI_CLASSES } from "../../ui/styles/styles-constants";


@Directive({
    selector: 'optional-fragment-check-plugin',
})
@Injectable({
    providedIn: 'root'
  })
export class OptionalFragmentCheckPlugin extends BasePlugin implements OnDestroy {

    public static readonly PLUGIN_NAME = 'OptionalFragmentCheck';
    public static readonly PLUGIN_LABEL = 'optional-fragment';
    public static readonly PLUGIN_LABEL_CHECK = 'optional-fragment-check';
    public static readonly PLUGIN_LABEL_DESCRIPTION = 'optional-fragment-description';
    public static readonly PLUGIN_LABEL_CONTENT = 'optional-fragment-content';
    public static readonly PLUGIN_CLASS = 'opt';
    public static readonly PLUGIN_CLASS_CHECK = 'checkbox-text';
    public static readonly PLUGIN_LABEL_CLASS_CHECK = 'checkbox-text-label';
    public static readonly PLUGIN_CLASS_DESCRIPTION = 'hDesc';
    public static readonly PLUGIN_CLASS_CONTENT = 'optional-fragment-content';
    public static readonly ADD_COMMAND_NAME = 'add-optional-fragment'
    public static readonly DELETE_COMMAND_NAME = 'delete-optional-fragment'
    public static readonly ID = 'id';

    public static get labelModel() { return OptionalFragmentCheckPlugin.PLUGIN_LABEL; }
    public static get labelCheckModel() { return OptionalFragmentCheckPlugin.PLUGIN_LABEL_CHECK; }
    public static get labelDescriptionModel() { return OptionalFragmentCheckPlugin.PLUGIN_LABEL_DESCRIPTION; }
    public static get labelContentModel() { return OptionalFragmentCheckPlugin.PLUGIN_LABEL_CONTENT; }

    public static get containerClass() { return OptionalFragmentCheckPlugin.PLUGIN_CLASS; }
    public static get checkClass() { return OptionalFragmentCheckPlugin.PLUGIN_CLASS_CHECK; }
    public static get labelCheckClass() { return OptionalFragmentCheckPlugin.PLUGIN_LABEL_CLASS_CHECK; }
    public static get descriptionClass() { return OptionalFragmentCheckPlugin.PLUGIN_CLASS_DESCRIPTION; }
    public static get contentClass() { return OptionalFragmentCheckPlugin.PLUGIN_CLASS_CONTENT; }

    public static get pluginToolbarElementName() { return OptionalFragmentCheckPlugin.PLUGIN_NAME; }
    public static get visualSelectionMarker() { return OptionalFragmentCheckPlugin.PLUGIN_NAME; }
    public static get requires() { return [Widget, ContextualBalloon]; }

    protected override mappers  = [
        OptionalFragmentCheckPlugin.containerClass,
        OptionalFragmentCheckPlugin.labelCheckClass,
        OptionalFragmentCheckPlugin.descriptionClass,
        OptionalFragmentCheckPlugin.contentClass
    ] ;

    protected override commands = {
        [OptionalFragmentCheckPlugin.ADD_COMMAND_NAME]: AddOptionalFragmentCommand,
        [OptionalFragmentCheckPlugin.DELETE_COMMAND_NAME]: DeleteOptionalFragmentCommand,
    };

    protected toolbarButton: ToolbarButtonModel = {

        icon: UI_CLASSES.SVG_ICONS.CHECK_ICON,
        pluginToolbarElementName: OptionalFragmentCheckPlugin.pluginToolbarElementName,
        tooltip: $localize`:@@InsertarCheckOpcionalEtiquetaBotonBotoneraPlugin:Insertar un fragmento opcional`,
        hasTooltip: true,
        hasText: true
    };


    private balloonView: OptionalFragmentCheckBalloonView;
    private userInterfaceService: UserInterfaceService;
    private dataViewToModelConverter: FragmentCheckDataViewToModelConverterService;
    private modelToDataViewConverter: FragmentCheckModelToDataViewConverterService;
    private modelToEditorViewConverter: FragmentCheckModelToEditorViewConverterService;
    private optionalFragmentCheckSchema: OptionalFragmentCheckSchemaService;

    constructor(editor: Editor) {
        super(editor);
        this.optionalFragmentCheckSchema = new OptionalFragmentCheckSchemaService();
        this.dataViewToModelConverter = new FragmentCheckDataViewToModelConverterService();
        this.modelToDataViewConverter = new FragmentCheckModelToDataViewConverterService();
        this.modelToEditorViewConverter = new FragmentCheckModelToEditorViewConverterService();
        this.userInterfaceService = new UserInterfaceService();
    }

    public showUI(id?: string): void {
        if (!id) {
            return;
        }

        if (!this.balloonView) {
            this.createBalloonView(id);
        } else {
            this.balloonView.id = id;
        }

       this.userInterfaceService.addUI(this.editor, this.balloon, this.balloonView,
            OptionalFragmentCheckPlugin.contentClass, OptionalFragmentCheckPlugin.visualSelectionMarker);
    }

    protected defineSchema(): void {
        const schema = this.editor.model.schema;
        this.optionalFragmentCheckSchema.defineSchema(schema);
    }

    protected defineConverters(): void {
        const conversion = this.editor.conversion;
        this.dataViewToModelConverter.configureConverters(conversion);
        this.modelToDataViewConverter.configureConverters(conversion);
        this.modelToEditorViewConverter.configureConverters(conversion);
    }

    protected editorInteractions(): void {
        super.setupEditorObserver(ClickObserver);
        this.enableBalloonActivators();
    }
    protected toolbarExecuteOperation(): void {
        this.editor.execute(OptionalFragmentCheckPlugin.ADD_COMMAND_NAME);
    }

    protected enableBalloonActivators(): void {
        const viewDocument = this.editor.editing.view.document;
        this.listenTo<ViewDocumentClickEvent>(viewDocument, 'click', this.handleClickEvent.bind(this));
    }

    protected hideFakeVisualSelection(): void {
        this.pluginUtils.hideFakeVisualSelection(this.editor, OptionalFragmentCheckPlugin.visualSelectionMarker);
    }

    protected getBalloonPositionData(): Partial<PositionOptions> {
        return this.pluginUtils.getBalloonPositionData(this.editor, OptionalFragmentCheckPlugin.containerClass, OptionalFragmentCheckPlugin.visualSelectionMarker);

    }

    private handleClickEvent(): void {
        const documentFragment = this.pluginUtils.getSelectedElementWithClass(this.editor, OptionalFragmentCheckPlugin.containerClass);
        if (!documentFragment) {
            return;
        }
        const id = documentFragment.getAttribute(OptionalFragmentCheckPlugin.ID);
        this.showUI(id);
    }

    private createBalloonView(id: string) {
        this.balloonView = this.getBalloonView(id);
        this.userInterfaceService.enableUserBalloonInteractions(this.editor, this.balloon, this.balloonView,
            OptionalFragmentCheckPlugin.visualSelectionMarker, this);
    }

    private getBalloonView(id: string): OptionalFragmentCheckBalloonView {
        const editor = this.editor;
        const balloonView = new OptionalFragmentCheckBalloonView(editor.locale);
        balloonView.id = id;
        this.configureDeleteButton(editor, balloonView);

        return balloonView;
    }

    private configureDeleteButton(editor: Editor, balloonView: OptionalFragmentCheckBalloonView) {
        balloonView.deleteButtonView.on('execute', () => {
            editor.execute(OptionalFragmentCheckPlugin.DELETE_COMMAND_NAME, balloonView.id);
            this.userInterfaceService.hideUI(this.editor, this.balloon, this.balloonView, OptionalFragmentCheckPlugin.visualSelectionMarker, this);
        });
    }
}
