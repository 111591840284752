import { Injectable } from '@angular/core';
import { Conversion, Editor, toWidget, toWidgetEditable } from 'ckeditor5';
import { OptionalFragmentCheckPlugin } from '../../plugins/optional-fragment-check/optional-fragment-check-plugin';

@Injectable({
    providedIn: 'root'
})
export class FragmentCheckModelToEditorViewConverterService {

    public configureConverters(conversion: Conversion) {
         this.containerConversion(conversion);
         this.checkConversion(conversion);
         this.descriptionConversion(conversion);
         this.contentConversion(conversion);
    }

    private containerConversion(conversion) {
        conversion.for("editingDowncast").elementToElement({
            model: OptionalFragmentCheckPlugin.labelModel,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const isChecked = modelItem.getAttribute('checked');
                const id = modelItem.getAttribute('id');

                const element = viewWriter.createContainerElement(
                    "div",
                    {
                        class: [OptionalFragmentCheckPlugin.containerClass],
                        id,
                        //TODO is checked solo si existe checked
                    });

                return toWidget(element, viewWriter);
            },
        });
    }

    private checkConversion(conversion) {
        conversion.for("editingDowncast").elementToElement({
            model: OptionalFragmentCheckPlugin.labelCheckModel,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const name = modelItem.getAttribute('data-name');
                const id = modelItem.getAttribute('id');
                const isChecked = modelItem.getAttribute('checked');

                const viewElement = viewWriter.createContainerElement('input', {
                    class: OptionalFragmentCheckPlugin.checkClass,
                    id,
                    type: 'checkbox',
                    name: name,
                    ...(isChecked && { checked: true })  // Conditionally add the 'checked' attribute
                });

                return toWidget(viewElement, viewWriter);
            },
        });
    }

    private descriptionConversion(conversion) {
        conversion.for("editingDowncast").elementToElement({
            model: OptionalFragmentCheckPlugin.labelDescriptionModel,
            view: (_modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = viewWriter.createEditableElement("div", {
                    class: [OptionalFragmentCheckPlugin.descriptionClass],
                });

                return toWidgetEditable(widgetElement, viewWriter);
            },
        });
    }

    private contentConversion(conversion) {
        conversion.for("editingDowncast").elementToElement({
            model: OptionalFragmentCheckPlugin.labelContentModel,
            view: (_modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = viewWriter.createEditableElement("div", {
                    class: [OptionalFragmentCheckPlugin.contentClass],
                });

                return toWidgetEditable(widgetElement, viewWriter);
            },
        });
    }
}
