import { WysiwygEditorComponent } from 'src/app/shared/components/wysiwyg-editor/wysiwyg-editor.component';
import { AfterViewInit, Component, Input, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { TemplateDownloadDTO, ClauseDTO, ClauseService, ClausesWKService, DocumentDTO } from 'src/app/api';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { ITransformCleanHtmlService } from 'src/app/core/shared/services/transform-clean-html/transform-clean-html.service.interface';
import { IClausesWarningService } from 'src/app/core/shared/services/clauses/clauses-warning/clauses-warning.service.interface';
import { EditorDocumentParts } from 'src/app/shared/components/wysiwyg-editor/models/editor-document-parts';
import { BaseWithEditorDirective } from 'src/app/shared/components/base-components/base-with-editor';

@Component({
    selector: 'app-clause-lld-revision-preview',
    templateUrl: './clause-lld-revision-preview.component.html'
})
export class ClauseLldRevisionPreviewComponent extends BaseWithEditorDirective implements AfterViewInit, OnDestroy {

    @ViewChild('clauseViewer') documentViewer: WysiwygEditorComponent;

    @Input() clauseId: string;

    public clause: ClauseDTO;
    public templateOriginal = '';
    public templateName = '';
    public templateCoText = '';
    public clauseModified: string;
    public clauseModifiedName: string;
    public pendingClauses = 1;
    public totalClauses = 1;

    public doReviewClauseObservable = new BehaviorSubject<boolean>(false);
    public doSaveChangesObservable = new BehaviorSubject<boolean>(false);
    public doCloseObservable = new BehaviorSubject<boolean>(false);

    public displayButtonBarObservable = new BehaviorSubject<boolean>(true);
    public isPrimaryButtonEnableObservable = new BehaviorSubject<boolean>(false);
    public isSecondaryButtonEnableObservable = new BehaviorSubject<boolean>(false);

    private doReviewClauseSubscription: Subscription;
    private doSaveChangesSubscription: Subscription;
    private resetUnsavedChanges = new Subject<boolean>();
    private hasUnsavedChanges = new BehaviorSubject<boolean>(false);


    constructor(@Inject(MAT_DIALOG_DATA) public data,
                public genericDialogService: GenericDialogService,
                private clauseService: ClauseService,
                public clauseWarningService: IClausesWarningService,
                private transformCleanHtmlService: ITransformCleanHtmlService,
                private clauseDownloadService: ClausesWKService) {
            super();
            this.clause = data.dataSourceComponent;
            this.clauseId = this.clause.id;
    }

    public ngAfterViewInit(): void {
        this.subscribeEvents();
        this.loadClause(this.clauseId);
    }

    public ngOnDestroy(): void {
        this.unSubscribeEvents();
    }

    public getPendingClause(clause: ClauseDTO) {
        this.pendingClauses = clause?.warningChanges.hasTemplateLLDPendingRevision ? 1 : 0;
    }

    public loadClause(clauseId: string) {
        this.clauseService.clausesIdGet(clauseId).subscribe((clause: ClauseDTO) => {
            this.clause = clause;
            this.clauseModified = clause.description;
            this.clauseModifiedName = clause.name;
            this.getPendingClause(clause);
            this.extractFromTemplateDownload(clause.iddOrigin);
            this.notifyIsPendingClause();
            this.notifyEnableButtons();
            this.configureEditionWithPermission(clause?.userPermissions);
            this.configurePluginsClause();
        });
    }

    private notifyIsPendingClause() {
        if (!this.displayButtonBarObservable) {
            return;
        }
        this.displayButtonBarObservable.next(this.pendingClauses > 0);
    }

    private notifyEnableButtons() {
        this.isPrimaryButtonEnableObservable.next(this.clause?.userPermissions?.canSave);
        this.isSecondaryButtonEnableObservable.next(this.clause?.userPermissions?.canSave);
    }

    public extractFromTemplateDownload(iddOrigin: string) {
        this.clauseDownloadService.clausesWKWithCoIddGet(iddOrigin).subscribe((templateDownload: TemplateDownloadDTO) => {
            this.templateOriginal = this.transformCleanHtmlService.cleanHeader(templateDownload.html);
            this.templateCoText = templateDownload.co;
            this.templateName = templateDownload.name;
        });
    }

    public tryClose(): void {
        if (this.hasUnsavedChanges.getValue()) {
            const message = $localize`:@@ConfirmacionSalirMensaje:Si abandonas la pantalla los cambios se perderán`;
            this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
                if (result !== true) {
                    return;
                }

                this.close();
            });
            return;
        }

        this.close();
    }

    public close(): void {
        this.notifyClose();
    }

    public htmlDecode(input: string) {
        const doc = new DOMParser().parseFromString(input, 'text/html');
        return doc.documentElement.getElementsByTagName('body')[0].innerHTML;
    }

    public save(): void {
        this.clauseService.clausesIdGet(this.clause.id).subscribe((clause: ClauseDTO) => {
            clause.description = this.clauseModified;
            this.clauseService.clausesPut(clause).subscribe((contractSaved: DocumentDTO) => {
                this.resetUnsavedChanges.next(true);
                const message = $localize`:@@EditarClausulaGuardarMensaje:La cláusula ha sido modificada.`;
                this.genericDialogService.showMessage(message);
            }, (error: any) => {
                const message = $localize`:@@NuevaClausulaErrorAlGuardarMensaje:Se ha producido un error al guardar la cláusula.`;
                this.genericDialogService.showMessage(message);
            });
        });
    }

    public acceptChanges(): void {
        const message = $localize`:@@ConfirmacionCambioClausulaMensaje:Vas a realizar cambios en la cláusula. ¿Estás seguro?`;
        this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
            if (result !== true) {
                return;
            }

            this.saveAndReview();
        });
    }

    public saveAndReview(): void {
        this.clauseService.clausesIdGet(this.clause.id).subscribe((clause: ClauseDTO) => {
            clause.description = this.clauseModified;
            this.clauseService.clausesSaveAndReviewedLldPendingPut(clause).subscribe((clauseSaved: ClauseDTO) => {
                this.resetUnsavedChanges.next(true);
                this.pendingClauses = 0;
                this.doAfterReview();
            }, () => {
                const message = $localize`:@@NuevaClausulaErrorAlGuardarMensaje:Se ha producido un error al guardar la cláusula.`;
                this.genericDialogService.showMessage(message);
            });
        });
    }

    public doAfterReview(): void {
        this.loadClause(this.clause.id);
    }

    public documentChanged(editorDocumentParts: EditorDocumentParts) {
        this.clauseModified = editorDocumentParts?.html;
    }

    public editorReady(ready: boolean) {
        setTimeout(() => {
            this.resetUnsavedChanges.next(true);
        });
    }

    public hasUnsavedChangesEvent(unsavedChanges: boolean): void {
        this.hasUnsavedChanges.next(unsavedChanges);
    }

    private subscribeEvents() {
        if (this.doSaveChangesObservable) {
            this.doSaveChangesSubscription = this.doSaveChangesObservable.subscribe((doAction: boolean) => {
                this.doSaveChanges(doAction);
            });
        }

        if (this.doReviewClauseObservable) {
            this.doReviewClauseSubscription = this.doReviewClauseObservable.subscribe((doAction: boolean) => {
                this.doReviewChanges(doAction);
            });
        }

        this.resetUnsavedChanges.asObservable().subscribe((reset: boolean) => {
            this.notifyResetUnsavedChanges(reset);
        });
    }

    private unSubscribeEvents() {
        if (this.doSaveChangesObservable) {
            this.doSaveChangesSubscription.unsubscribe();
        }

        if (this.doReviewClauseObservable) {
            this.doReviewClauseSubscription.unsubscribe();
        }

        if (this.resetUnsavedChanges) {
            this.resetUnsavedChanges.unsubscribe();
        }
    }

    private doSaveChanges(doAction: boolean) {
        if (!doAction) {
            return;
        }

        this.save();
    }

    private doReviewChanges(doAction: boolean) {
        if (!doAction) {
            return;
        }

        this.acceptChanges();
    }

    private notifyClose() {
        this.doCloseObservable.next(true);
    }
}
