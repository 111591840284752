import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { from } from 'rxjs';
import { Observable } from 'rxjs/Observable';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
    getCanDeactivateMessage(): string;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    constructor(private genericDialogService: GenericDialogService) { }

    public canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        if (component == null || component.getCanDeactivateMessage() === undefined){
            return true;
        }
        const message = component.getCanDeactivateMessage();
        // if there are no pending changes, just allow deactivation; else confirm first
        return component.canDeactivate() ?
            true :
            // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
            // when navigating away from your angular app, the browser will show a generic warning message
            // see http://stackoverflow.com/a/42207299/7307355
            from(this.openWarning(message));
    }

    public openWarning(message: string): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
                resolve(result !== false);
            });
        });
    }
}
