import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClauseFolderDTOOperation, FolderItemInformationDto, OperationType } from 'src/app/api';
import { IClausesFolderService } from './clauses-folder.service.interface';
import { IClausesFolderTreeService } from '../clauses-folder-tree/clauses-folder-tree.service.interface';
import { ClauseFolderDto } from 'src/app/api/model/cmc/clauseFolderDto';

@Injectable({
    providedIn: 'root'
})
export class ClausesFolderService extends IClausesFolderService {

    private currentFolderId = new BehaviorSubject<string>('');
    private currentFolderName = new BehaviorSubject<string>('');

    constructor(@Inject('IClausesFolderTreeService') private readonly clausesFolderTreeService: IClausesFolderTreeService) {
        super();
    }

    public setCurrentFolderName(folderName: string): void {
        return this.currentFolderName.next(folderName);
    }

    public getCurrentFolderName(): string {
        return this.getCorrectedFolderName(this.currentFolderName.getValue());
    }

    public getCorrectedFolderName(originalFolderName: string): string {
        if (originalFolderName === '' || originalFolderName === null) {
            return this.clausesFolderTreeService.getRootNodeName();
        }

        return originalFolderName;
    }

    public setCurrentFolderId(folderId: string): void {
        return this.currentFolderId.next(folderId);
    }

    public getCurrentFolderId(): Observable<string> {
        return this.currentFolderId.asObservable();
    }

    public getOperationReplaceClauseFolder(valueToChange: string, newValue: any): ClauseFolderDTOOperation[] {
        const operations: ClauseFolderDTOOperation[] = [];
        const operation: ClauseFolderDTOOperation = {
            operationType: OperationType.NUMBER_0,
            op: 'replace',
            path: valueToChange,
            value: newValue
        };

        operations.push(operation);
        return operations;
    }

    public isCurrentFolderValid(nodeId: string) {
        return nodeId && nodeId !== this.clausesFolderTreeService.getRootNodeId();
    }

    public getFileIdsInFolderRecusively(folderId: string, folders: ClauseFolderDto[]): FolderItemInformationDto[] {
        const content = folders.find(folder => folder.id === folderId)?.content;
        const fileIds: FolderItemInformationDto[] = [];
        if (content) {
            fileIds.push(...folders.find(folder => folder.id === folderId).content);
        }

        const childrenFolders = folders.filter( folder => folder.clauseFolderParentId === folderId);
        childrenFolders.forEach(  (childFolder: ClauseFolderDto) => {
            fileIds.push(...this.getFileIdsInFolderRecusively(childFolder.id, folders));
        });

        return fileIds;
    }
}
