import { Directive, Inject } from '@angular/core';
import {
    LabeledFieldView, ButtonView, Locale, FocusTracker, KeystrokeHandler, InputNumberView, createLabeledInputNumber,
} from 'ckeditor5';
import { RadioPlugin } from '../../plugins/radio/radio-plugin';
import BaseView from '../base-view.directive';
import { UI_CLASSES } from '../styles/styles-constants'

@Directive({
    selector: 'app-add-radio-dialog-form-view',
})
export default class AddRadioDialogFormView extends BaseView {

    public optionsCountInputView: LabeledFieldView<InputNumberView>;
    public readonly keystrokes = new KeystrokeHandler();

    public readonly focusTracker = new FocusTracker();

    private saveButtonView: ButtonView;
    private cancelButtonView: ButtonView;

    private readonly validators: Array<AddRadioFormValidatorCallback>;

    private readonly optionsNumberMessage = $localize`:@@NumeroOptionesGrupoRadioPlugin:N.º de opciones para este grupo`;
    private readonly notNullGroupsMessage = $localize`:@@NumeroGrupoNuloRadioPlugin:No puede ser nulo`;

    constructor(
        @Inject(Array<AddRadioFormValidatorCallback>) validators: Array<AddRadioFormValidatorCallback>,
        @Inject(Locale) locale?: Locale,
        @Inject(Number) optionsCount?: number
    ) {
        super(locale);

        this.validators = validators;

        this.optionsCountInputView = this.createOptionsCountInput(this.optionsNumberMessage, optionsCount?.toString());

        this.saveButtonView = this.createButton(this.submitButtonMessage, `ck-button-save ${UI_CLASSES.BUTTONS.PLUGIN}`);
        this.saveButtonView.type = 'submit';

        this.cancelButtonView = this.createButton(this.cancelButtonMessage, `ck-button-cancel ${UI_CLASSES.BUTTONS.PLUGIN}`);
        this.cancelButtonView.delegate('execute').to(this, 'cancel');

        this.items = this.createCollection([
            this.optionsCountInputView,
            this.saveButtonView,
            this.cancelButtonView
        ]);

        this.setTemplateForm("ck-radio-form");
    }

    public isValid(): boolean {
        this.resetFormStatus();

        for (const validator of this.validators) {
            const errorText = validator(this);

            if (errorText) {
                this.optionsCountInputView.errorText = errorText;
                return false;
            }
        }

        return true;
    }

    public resetFormStatus(): void {
        this.optionsCountInputView.errorText = null;
    }

    public get optionsCount(): string | null {
        const { element } = this.optionsCountInputView.fieldView;

        if (!element) {
            return null;
        }

        return element.value.trim();
    }

    public set optionsCount(value: string | null) {
        const { element } = this.optionsCountInputView.fieldView;

        if (!element) {
            return;
        }

        element.value = !!value ? value : '';
    }

    private createOptionsCountInput(label: string, defaultValue?: string): LabeledFieldView<InputNumberView> {
        const labeledInput = new LabeledFieldView(this.locale, createLabeledInputNumber);

        labeledInput.label = label;
        labeledInput.fieldView.value = "2";
        labeledInput.fieldView.min = 2;
        labeledInput.fieldView.max = RadioPlugin.MAX_RADIOS;

        labeledInput.fieldView.on('input', (value: any) => {
            labeledInput.errorText = !!value ? '' : this.notNullGroupsMessage;
        });

        return labeledInput;
    }

}

export type AddRadioFormValidatorCallback = (form: AddRadioDialogFormView) => string | undefined;
