import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ClauseDTO, ClauseFolderDTO, ClauseService, ErrorClauseOperationDTO } from 'src/app/api';
import { GenericDialogConfig } from 'src/app/core/shared/models/generic-dialog-config.model';
import { ClauseFolderComponent } from 'src/app/pages/standard/clauses-library/clause-folder/clause-folder.component';
import { IClauseCreationService } from 'src/app/core/standard/services/clauses/clause-creation/clause-creation.service.interface';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { dialogTypes } from 'src/app/shared/components/ctbox-generic-dialog-data/ctbox-generic-dialog-data.component';

@Injectable({
    providedIn: 'root'
})
export class ClauseCreationService extends IClauseCreationService {

    constructor(
        private genericDialogService: GenericDialogService,
        private clauseService: ClauseService
    ) {
        super();
    }

    public saveOpenFolder(newClause: ClauseDTO): MatDialogRef<any> {
        const config = this.getModalConfig();
        const data: any = {
            template: ClauseFolderComponent,
            dialogTitle: $localize`:@@CrearUnaNuevaClausulaTitulo:Guardar en carpeta.`,
            currentClause: newClause,
            displayCloseOption: true,
            displayButtonBar: true,
            dialogTypes: dialogTypes.Tree,
            dialogButton: 'Guardar',
            dialogCloseButon: 'Cancelar',
            primaryButtonContentObservableName: 'isValidSubscription',
        };
        return this.genericDialogService.openTemplateWithConfigAndData(ClauseFolderComponent, config, data);
    }

    private getModalConfig(): GenericDialogConfig {
        const config = this.genericDialogService.getDefaultDialogConfig();
        config.panelClass = 'cdk-overlay-fullscreen';
        config.backdropClass = 'cdk-overlay-backdrop-white';
        config.width = '50vw';
        config.height = '80vh';

        return config;
    }

    public afterClosed(result: any, newClause: ClauseDTO): Promise<ClauseDTO> {
        const promiseResult = new Promise<ClauseDTO>((resolve) => {
            if (!result) {
                resolve(null);
                return;
            }
            this.clauseService.clausesPost(newClause).subscribe((clauseSaved: ClauseDTO) => {
                resolve(clauseSaved);
            }, (error: any) => {
                const errorDescription = error.error?.Name[0];
                this.clauseService.clausesErrorDescriptionGet().subscribe((errorDescriptions: ErrorClauseOperationDTO) => {

                    let message = '';

                    if (error.status === 400 && (error.error === null || error.error === undefined)
                        || error.status === 400 && errorDescription === errorDescriptions.nameUsedAndCanBeOverwrittenMessage) {
                        this.overwriteExistingClause(newClause).then((resultOverwrite: ClauseDTO) => {
                            resolve(resultOverwrite);
                        });
                        return;
                    } else if (error.status === 400 && errorDescription === errorDescriptions.nameUsedAndCanNotBeOverwrittenMessage) {
                        message = $localize`:@@NuevaClausulaErrorMismoNombreAlGuardarMensaje:Ya existe otra cláusula con el mismo nombre en el directorio. Introduce otro nombre, por favor.`;
                    } else if (error.status === 400) {
                        message = $localize`:@@NuevaClausulaErrorMalParametroAlGuardarMensaje:Se ha producido un error al guardar la cláusula. Utiliza solo caracteres alfanuméricos en el nombre.`;
                    } else {
                        message = $localize`:@@NuevaClausulaErrorAlGuardarMensaje:Se ha producido un error al guardar la cláusula.`;
                    }
                    this.genericDialogService.showMessage(message);
                    resolve(null);
                }, () => {
                    const message = $localize`:@@NuevaClausulaErrorAlGuardarMensaje:Se ha producido un error al guardar la cláusula.`;
                    this.genericDialogService.showMessage(message);
                    resolve(null);
                });
            });
        });
        return promiseResult;
    }

    private overwriteExistingClause(clause: ClauseDTO): Promise<ClauseDTO> {
        const promiseResult = new Promise<ClauseDTO>((resolve) => {
            let message = $localize`:@@NuevaClausulaErrorMismoNombreRemplazarlaGuardarMensaje:Ya existe otra cláusula con el mismo nombre en el directorio. ¿Quieres remplazarla?`;
            this.genericDialogService.showQuestion(message).afterClosed().subscribe((result) => {
                if (result !== true) {
                    resolve(null);
                    return;
                }
                this.clauseService.clausesSearchNameNameFolderIdFolderIdGet(clause.name, clause.clauseFolderId)
                    .subscribe((clauseWithSameName: ClauseDTO) => {
                        clauseWithSameName.description = clause.description;
                        this.clauseService.clausesPut(clauseWithSameName)
                            .subscribe(() => {
                                resolve(clauseWithSameName);
                            }, (errorPut: any) => {
                                message = $localize`:@@NuevaClausulaErrorAlGuardarMensaje:Se ha producido un error al guardar la cláusula.`;
                                this.genericDialogService.showMessage(message);
                                resolve(null);
                            });
                    }, (errorSearch: any) => {
                        message = $localize`:@@NuevaClausulaErrorAlGuardarMensaje:Se ha producido un error al guardar la cláusula.`;
                        this.genericDialogService.showMessage(message);
                        resolve(null);
                    });
            });
        });
        return promiseResult;
    }
}
