import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from './api-processor.service';
import { Observable } from 'rxjs';

@Injectable()
export class LegalAlertsContentCMCService {

    private basePath = '/api/manager-management/alerts-content';

    constructor(private readonly apiProcessorService: ApiProcessorService) { }

    public get(id: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.basePath}/${id}`;
        apiRequest.responseType = 'text';

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
