import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'ctbox-info',
    templateUrl: './ctbox-info.component.html',
    styleUrls: ['./ctbox-info.component.scss']
})
export class CtboxInfoComponent {

    @Input() public icon: string;
    @Input() public title: string;
    @Input() public subTitle: string;
    @Input() public message: any;

    public hasSeveralLines: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data
    ) {
        this.icon = data.icon;
        this.title = data.title;
        this.subTitle = data.subTitle;
        this.message = data.message;
        this.hasSeveralLines = Array.isArray(this.message) && this.message?.every(item => typeof item === 'string');
    }
}
