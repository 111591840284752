import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { DragAndDropComponent } from 'src/app/shared/components/drag-and-drop/drag-and-drop.component';

@Component({
    selector: 'app-ctbox-load-local-files',
    templateUrl: './ctbox-load-local-files.component.html'
})
export class CtboxLoadLocalFilesComponent implements OnInit {

    @ViewChild('dragAndDrop') dragAndDrop: DragAndDropComponent;
    @Input() description: string;
    @Input() maximunNumberFiles: number;


    public selectedFiles: any[] = [];
    public doCloseObservable = new BehaviorSubject<boolean>(false);
    public isValidSubscription = new BehaviorSubject<boolean>(false);

    constructor(@Inject(MAT_DIALOG_DATA) public data) {
        this.description = data.description;
        this.maximunNumberFiles = data.description;
    }

    public ngOnInit(): void {
        if (this.selectedFiles.length === 0) {
            this.isValidSubscription.next(false);
        } else {
            this.isValidSubscription.next(true);
        }

    }

    public upload(file) {
        if (file.length === 0) {
            return;
        }

        this.selectedFiles.push(file);

        if (this.selectedFiles.length === 0) {
            this.isValidSubscription.next(false);
        } else {
            this.isValidSubscription.next(true);
        }

    }

    public close() {
        this.doCloseObservable.next(true);
    }

}
