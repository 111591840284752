import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-document-clause-revision-send-to-firm',
    templateUrl: './document-clause-revision-send-to-firm.component.html',
    styleUrls: ['./document-clause-revision-send-to-firm.component.scss']
})
export class DocumentClauseRevisionSendToFirmComponent {

    @Input() expirationDays: number;
    @Input() expirationDate: Date;

    @Output() newExpirationDate = new EventEmitter<Date>();

    constructor(@Inject(MAT_DIALOG_DATA) public data) {
        this.expirationDays = data?.dataSource?.expirationDays;
        this.expirationDate = data?.dataSource?.expirationDate;
    }

}
