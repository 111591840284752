import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { Configuration } from './config/configuration';
import { CustomizeConfiguration } from './config/customisation';

if (environment.production) {
    enableProdMode();
}

export async function parseJsonResponse<T>(response: Response): Promise<T> {
    const textResponse = await response.text();
    return JSON.parse(textResponse) as T;
}

export async function fetchConfiguration<T>(filePath: string): Promise<T> {
    let configuration: T;
    await fetch(filePath)
        .then(async res => {
            configuration = await parseJsonResponse<T>(res);
        })
        .catch((err) => console.error(err));

    return configuration as T;
}

export async function generateConfiguration(): Promise<void> {
    const configuration = await fetchConfiguration<Configuration>('config/config.json');
    const customizeConfiguration = await fetchConfiguration<CustomizeConfiguration>('config/customisation.json');

    platformBrowserDynamic([
        { provide: Configuration, useValue: configuration },
        { provide: CustomizeConfiguration, useValue: customizeConfiguration },

    ])
        .bootstrapModule(AppModule)
        .catch((err: any) => console.error(err));
}

generateConfiguration();
