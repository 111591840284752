import { Command, DocumentFragment, Editor, Element, Writer } from "ckeditor5";
import { ClauseModel } from "../../models/clause/clause-model";
import { ClausesPlugin } from "../../plugins/clause/clauses-plugin";

export default class ClauseAddCommand extends Command {

    public override execute( value: ClauseModel ): void {
        const editor = this.editor;

        editor.model.change( (writer:Writer) => {

            const clause = this.createClauseElement(writer, value);

            const modelFragement = this.createModelFragment(editor, value);

            editor.model.insertObject(clause, null, null, { setSelection: 'after' });
            editor.model.insertContent(modelFragement, writer.createPositionAt(clause, 0));

        });
    }

    private createClauseElement(writer: Writer, value: ClauseModel): Element {
        return writer.createElement(ClausesPlugin.MODEL_ENTITIES.class.model, {
            'id': 'id_' + value.id,
            'description': value.description
        });
    }

    private createModelFragment(editor: Editor, value: ClauseModel): DocumentFragment  {
        const content = value.description;
        const viewFragment = editor.data.processor.toView(content!);
        return editor.data.toModel(viewFragment);
    }
}
