import { Injectable } from '@angular/core';
import { Editor, Model, Position, Range, ViewContainerElement, ViewDocumentFragment, ViewElement, ViewNode } from 'ckeditor5';
import type { PositionOptions } from 'ckeditor5/src/utils.js';

@Injectable({
    providedIn: 'root'
})
export class PluginUtilsService {

    constructor() { }

    public getSelectedElementWithClass(editor: Editor, classNameToIdentify: string): ViewContainerElement | null {
        const view = editor.editing.view;
        const selection = view.document.selection;
        const selectedElement = selection.getSelectedElement() || selection.getFirstPosition()?.parent;
        if (!selectedElement || selectedElement?.is('documentFragment')) {
            return null;
        }
        return this.findElementAncestorWithClass((selectedElement as ViewNode), classNameToIdentify);
    }

    public showFakeVisualSelection(editor: Editor, markerName: string): void {
        const model = editor.model;

        model.change(writer => {
            const range = model.document.selection.getFirstRange()!;

            model.markers.has(markerName) ?
                writer.updateMarker(markerName, { range }) :
                this.createMarker(writer, model, range, markerName);

        });
    }

    private createMarker(writer, model: Model, range: Range, markerName: string) {
        const startPosition = this.getStartPosition(range, model);

        writer.addMarker(markerName, {
            usingOperation: false,
            affectsData: false,
            range: writer.createRange(startPosition, range.end)
        });
    }

    private getStartPosition(range: Range, model: Model): Position {
        return  range.start.isAtEnd ?
            range.start.getLastMatchingPosition(({ item }) => !model.schema.isContent(item), { boundaries: range }) :
            range.start;
    }

    public hideFakeVisualSelection(editor: Editor, markerName: string): void {
        const model = editor.model;

        if (model.markers.has(markerName)) {
            model.change(writer => {
                writer.removeMarker(markerName);
            });
        }
    }

    public getBalloonPositionData(editor: Editor, classNameInElementToPosition: string, markerName: string): Partial<PositionOptions> {
        const view = editor.editing.view;
        const model = editor.model;
        const viewDocument = view.document;
        let target: PositionOptions['target'];

        if (model.markers.has(markerName)) {
            const markerViewElements = Array.from(editor.editing.mapper.markerNameToElements(markerName)!);
            const newRange = view.createRange(
                view.createPositionBefore(markerViewElements[0]),
                view.createPositionAfter(markerViewElements[markerViewElements.length - 1])
            );

            target = view.domConverter.viewRangeToDom(newRange);
        } else {
            target = () => {
                const targetSignature = this.getSelectedElementWithClass(editor, classNameInElementToPosition);

                return targetSignature ?
                    view.domConverter.mapViewToDom(targetSignature)! :
                    view.domConverter.viewRangeToDom(viewDocument.selection.getFirstRange()!);
            };
        }

        return { target };
    }

    private findElementAncestorWithClass(position: ViewElement | ViewNode | null, classNameToIdentify: string): ViewContainerElement | null {
        return position?.getAncestors({ includeSelf: true }).reverse().find((ancestor): ancestor is ViewContainerElement => this.isElementWithClass(ancestor, classNameToIdentify)) || null;
    }

    private isElementWithClass(node: ViewNode | ViewDocumentFragment, classNameToIdentify: string): boolean {
        return node.is('containerElement') && !!node.hasClass(classNameToIdentify);
    }
}
