import { Range, TreeWalkerValue, Writer } from 'ckeditor5';
import { RadioModel } from '../../models/radio/radio-model';
import { RadioCommand } from './radio-command';
import { RadioPlugin } from '../../plugins/radio/radio-plugin';

export default class RemoveOptionsCommand extends RadioCommand {

    public override execute(model: RadioModel, totalOptionsInRadioGroup: number): void {
        const editor = this.editor;

        editor.model.change((writer: Writer) => {
            const range = writer.createRangeIn(editor.model.document.getRoot()!);

            let radioModel: TreeWalkerValue;
            this.findRadioModel(range, model.id!, radioModel!);

            let optionToMove: any;
            let contentToMove: any;
            this.movePosition(writer, totalOptionsInRadioGroup, radioModel!, optionToMove, contentToMove);

            if (totalOptionsInRadioGroup > 0) {
                writer.setAttribute(RadioPlugin.MODEL_ENTITIES.optionCount.model, totalOptionsInRadioGroup, radioModel!.item);
            } else {
                writer.remove(radioModel!.item);
            }

        });
    }

    private movePosition(writer: Writer, totalOptionsInRadioGroup: number, radioModel: TreeWalkerValue, optionToMove: any, contentToMove: any): void {
        for (let positionToMove = totalOptionsInRadioGroup - 1; positionToMove >= 0; positionToMove--) {

            optionToMove = this.getOptionToMove(radioModel, positionToMove);
            contentToMove = this.getContentToMove(optionToMove);

            this.moveContentModelAfterRadioComponent(writer, contentToMove, radioModel);
            this.removeContentDivAndExtractChildrenToParent(writer, contentToMove);
            writer.remove(optionToMove);
        }
    }

    private moveContentModelAfterRadioComponent(writer: Writer, contentToMove: any, radioModel: any) {
        writer.insert(contentToMove, radioModel.item, 'after');
    }

    private removeContentDivAndExtractChildrenToParent(writer: Writer, contentToMove: any) {
        writer.unwrap(contentToMove);
    }


    private findRadioModel(range: Range, radioId: string, radioModel: any): void {
        for (const value of range.getWalker()) {
            if (value.item.hasAttribute('id') && value.item.getAttribute('id') === radioId) {
                radioModel = value;
                break;
            }
        }
    }

    private getContentToMove(optionToMove: any): any {
        for (const child of optionToMove.getChildren()) {
            if (child.name === RadioPlugin.MODEL_ENTITIES.content.model) {
                return child;
            }
        }
    }

    private getOptionToMove(radioModel: any, positionToMove: number): any {
        for (const child of radioModel.item.getChildren()) {
            if (child.hasAttribute(RadioPlugin.MODEL_ENTITIES.optionPosition.model) &&
                child.getAttribute(RadioPlugin.MODEL_ENTITIES.optionPosition.model) === positionToMove.toString()) {
                return child;
            }
        }
    }
}
