/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ClauseFolderDTO } from '../model/clauseFolderDTO';
import { ClauseFolderDTOOperation } from '../model/clauseFolderDTOOperation';
import { ErrorClauseFolderOperationDto } from '../model/errorClauseFolderOperationDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ClauseFolderService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param clauseFolderId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clauseFoldersClauseFolderIdPatch(clauseFolderId: string, body?: Array<ClauseFolderDTOOperation>, observe?: 'body', reportProgress?: boolean): Observable<ClauseFolderDTO>;
    public clauseFoldersClauseFolderIdPatch(clauseFolderId: string, body?: Array<ClauseFolderDTOOperation>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClauseFolderDTO>>;
    public clauseFoldersClauseFolderIdPatch(clauseFolderId: string, body?: Array<ClauseFolderDTOOperation>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClauseFolderDTO>>;
    public clauseFoldersClauseFolderIdPatch(clauseFolderId: string, body?: Array<ClauseFolderDTOOperation>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clauseFolderId === null || clauseFolderId === undefined) {
            throw new Error('Required parameter clauseFolderId was null or undefined when calling clauseFoldersClauseFolderIdPatch.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClauseFolderDTO>('patch',`${this.basePath}/clauseFolders/${encodeURIComponent(String(clauseFolderId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clauseFoldersDelete(id?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public clauseFoldersDelete(id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public clauseFoldersDelete(id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public clauseFoldersDelete(id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/clauseFolders`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clauseFoldersErrorDescriptionGet(observe?: 'body', reportProgress?: boolean): Observable<ErrorClauseFolderOperationDto>;
    public clauseFoldersErrorDescriptionGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ErrorClauseFolderOperationDto>>;
    public clauseFoldersErrorDescriptionGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ErrorClauseFolderOperationDto>>;
    public clauseFoldersErrorDescriptionGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ErrorClauseFolderOperationDto>('get',`${this.basePath}/clauseFolders/error-description`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clauseFoldersGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ClauseFolderDTO>>;
    public clauseFoldersGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ClauseFolderDTO>>>;
    public clauseFoldersGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ClauseFolderDTO>>>;
    public clauseFoldersGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ClauseFolderDTO>>('get',`${this.basePath}/clauseFolders`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clauseFoldersIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<ClauseFolderDTO>;
    public clauseFoldersIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClauseFolderDTO>>;
    public clauseFoldersIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClauseFolderDTO>>;
    public clauseFoldersIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling clauseFoldersIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ClauseFolderDTO>('get',`${this.basePath}/clauseFolders/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clauseFoldersPost(body?: ClauseFolderDTO, observe?: 'body', reportProgress?: boolean): Observable<ClauseFolderDTO>;
    public clauseFoldersPost(body?: ClauseFolderDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClauseFolderDTO>>;
    public clauseFoldersPost(body?: ClauseFolderDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClauseFolderDTO>>;
    public clauseFoldersPost(body?: ClauseFolderDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClauseFolderDTO>('post',`${this.basePath}/clauseFolders`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clauseFoldersPut(body?: ClauseFolderDTO, observe?: 'body', reportProgress?: boolean): Observable<ClauseFolderDTO>;
    public clauseFoldersPut(body?: ClauseFolderDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClauseFolderDTO>>;
    public clauseFoldersPut(body?: ClauseFolderDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClauseFolderDTO>>;
    public clauseFoldersPut(body?: ClauseFolderDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClauseFolderDTO>('put',`${this.basePath}/clauseFolders`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
