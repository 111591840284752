/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UserPermissionsOnClauseDTO { 
    canEdit?: boolean;
    canEditName?: boolean;
    canSave?: boolean;
    canDelete?: boolean;
    canSeeTemplateChanges?: boolean;
    canReviewTemplateChanges?: boolean;
}