
import { Element, Writer } from 'ckeditor5';
import { RadioElements } from '../../models/radio/radio-model';
import { RadioPlugin } from '../../plugins/radio/radio-plugin';
import { RadioCommand } from './radio-command';

export default class AddRadioCommand extends RadioCommand {

    private readonly helpTextOptionMessage = $localize`:@@TextoAyudaOpcionRadioPlugin:Texto ayuda Opción`;
    private readonly contentOptionMessage = $localize`:@@ContenidoOpcionRadioPlugin:Contenido Opción`;

    public override execute(value: number): void {
        const editor = this.editor;

        editor.model.change((writer: Writer) => {

            const guid = this.generarUUID();

            const radio = this.createRadio(writer, value, guid);

            this.setEachRadioConfig(writer, value, guid, radio);

            editor.model.insertObject(radio, null, null, { setSelection: 'after' });
        });
    }

    private setEachRadioConfig(writer: Writer, value: number, guid: string, radio: Element): void {
        for (let index = 0; index < value; index++) {

            const description = this.createDescription(writer);

            const content = this.createContent(writer);

            const option = this.createOption(writer, index);

            const check = this.createCheck(writer, guid);

            const radioElements: RadioElements = { description, content, option, check }

            this.addRadioText(writer, index, radioElements);

            this.addRadioToWriter(writer, radio, radioElements);
        }
    }

    private createRadio(writer: Writer, value: number, guid: string): Element {
        return writer.createElement(RadioPlugin.MODEL_ENTITIES.container.model, {
            'optionsCount': value.toString(),
            id: `ck-radio-id-${guid}`,
        });
    }

    private createCheck(writer: Writer, guid: string): Element {
        return writer.createElement(RadioPlugin.MODEL_ENTITIES.input.model, {
            'dataName': `ck-radio-id-${guid}`
        });
    }

    private createOption(writer: Writer, index: number): Element {
        return writer.createElement(RadioPlugin.MODEL_ENTITIES.option.model, {
            'position': index.toString()
        });
    }

    private createContent(writer: Writer): Element {
        return writer.createElement(RadioPlugin.MODEL_ENTITIES.content.model, {});
    }

    private createDescription(writer: Writer): Element {
        return writer.createElement(RadioPlugin.MODEL_ENTITIES.description.model, {});
    }

    private addRadioText(writer: Writer, index: number, radioElements: RadioElements) {
        const descriptionDefaultText = writer.createText(`(${this.helpTextOptionMessage} ${index + 1})`);
        const descriptionDefaultTextElement = writer.createElement('paragraph');
        const contentDefaultTextElement = writer.createElement('paragraph');
        const contentDefaultText = writer.createText(`${this.contentOptionMessage} ${index + 1}`);

        writer.append(descriptionDefaultText, descriptionDefaultTextElement);
        writer.append(descriptionDefaultTextElement, radioElements.description);
        writer.append(contentDefaultText, contentDefaultTextElement);
        writer.append(contentDefaultTextElement, radioElements.content);
    }

    private addRadioToWriter(writer: Writer, radio: Element, radioElements: RadioElements) {
        writer.append(radioElements.check, radioElements.option);
        writer.append(radioElements.description, radioElements.option);
        writer.append(radioElements.content, radioElements.option);
        writer.append(radioElements.option, radio);
    }

    private generarUUID(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}
