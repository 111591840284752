import { AbstractControl, ValidationErrors } from '@angular/forms';

const CUSTOM_EMAIL_REGEX = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*(?:\.(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?){2,})(\s*)$/;

function isInputValueEmpty(value: any): boolean {
    return value == null || value.length === 0;
}

export function emailValidator(control: AbstractControl): ValidationErrors | null {
    if (control === null || isInputValueEmpty(control.value)) {
        return null;
    }

    return CUSTOM_EMAIL_REGEX.test(control.value) ? null : { email: true};
}