import { View, ViewCollection, Locale, LabelView, } from 'ckeditor5';
import { Directive, Inject } from '@angular/core';

@Directive({
    selector: 'app-form-row-view',
})
export default class FormRowView extends View {

    public declare class: string | null;
    public children: ViewCollection;
    public declare _role: string | null;
    public declare _ariaLabelledBy: string | null;

    constructor(
        @Inject(Locale) locale?: Locale,
        @Inject(Object) options: { children?: Array<View>; class?: string; labelView?: LabelView; } = {}
    ) {
        super(locale);
        this.initializeProperties(options);
        this.initializeChildren(options.children);
        this.initializeAccessibility(options.labelView);
        this.initializeTemplate();
    }


    private initializeProperties(options: { class?: string }): void {
        this.set('class', options.class || null);
        this.children = this.createCollection();
    }

    private initializeChildren(children?: Array<View>): void {
        if (children) {
            children.forEach(child => this.children.add(child));
        }
    }

    private initializeAccessibility(labelView?: LabelView): void {
        this.set('_role', null);
        this.set('_ariaLabelledBy', null);

        if (labelView) {
            this.set({
                _role: 'group',
                _ariaLabelledBy: labelView.id
            });
        }
    }

    private initializeTemplate() {
        const bind = this.bindTemplate;
        this.setTemplate({
            tag: 'div',
            attributes: {
                class: [
                    'ck',
                    'ck-form__row',
                    bind.to('class')
                ],
                role: bind.to('_role'),
                'aria-labelledby': bind.to('_ariaLabelledBy')
            },
            children: this.children
        });
    }
}
