import { Injectable } from '@angular/core';
import { Editor } from 'ckeditor5';
import { SignaturePlugin } from '../../plugins/signature/signature-plugin';

@Injectable({
    providedIn: 'root'
})
export class SignatureDataViewToModelConverterService {

    public configureConverters(editor: Editor) {
        editor.conversion.for("upcast").elementToElement({
            view: {
                name: "div",
                classes: [SignaturePlugin.SIGNATURE_CLASS],
            },
            model: (viewElement, { writer: modelWriter }) => {
                const id = viewElement.getAttribute(SignaturePlugin.ID);
                const role = viewElement.getAttribute(SignaturePlugin.ATTRIBUTE_ROLE_IS_STORED);

                return modelWriter.createElement(SignaturePlugin.MODEL_ENTITIES.class.dataView,
                    { 'id': id, "signature-title": role, });
            },
        });
    }
}
