import { SelectionModel } from '@angular/cdk/collections';
import { Observable } from 'rxjs-compat';
import { ClauseFolderDTO } from 'src/app/api';
import { FileNode } from 'src/app/shared/components/ctbox-tree/models/file-node.model';
import { NodeTreeActionType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-action-type.enum';
import { HierarchySearchFileResult } from '../../../models/hierarchy-search-file-result.model';

export abstract class IClausesFolderTreeService {

    abstract getRootNodeName(): string;
    abstract getRootNodeId(): string;

    abstract setCurrentExpansionModel(selectionModel: SelectionModel<string>): void;
    abstract getCurrentExpansionModel(): Observable<SelectionModel<string>>;

    abstract createTreeFolder(folderDto: ClauseFolderDTO[], rootActions: NodeTreeActionType[],
                              nodeActions: NodeTreeActionType[]): FileNode[];

    abstract createTreeFolderSearchResult(folderDto: HierarchySearchFileResult[],
                                nodeActions: NodeTreeActionType[],
                                defaultCheckedNodes?: string[]): FileNode[];

}
