import { Component, Input, ChangeDetectorRef, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { formatBytes } from 'src/app/core/shared/utils/file-size-extension';
import { getInvalidCharacters } from 'src/app/core/shared/validators/invalidCharactersValidator';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FileValidationStatus } from 'src/app/core/shared/enums/file-validation-status.enum';
import { takeUntil } from 'rxjs/operators';
import { FileStepperDataService } from 'src/app/core/shared/services/file-stepper-data.service';
import { IFileStepper } from '../upload-file/file-stepper.model';

@Component({
  selector: 'app-uploaded-file-item',
    templateUrl: './uploaded-file-item.component.html',
    styleUrls: ['./uploaded-file-item.component.scss']
})
export class UploadedFileItemComponent implements OnInit, OnDestroy {
    @Input() public parentForm: UntypedFormGroup;
    @Input() public controlName: string;
    @Input() public maxMgFileSize: number;

    @Output() public notifyRemoveFile = new EventEmitter<IFileStepper>();
    @Output() public notifyCheckedVersionFile = new EventEmitter<IFileStepper>();

    public files: IFileStepper[] = [];

    public canGenerateVersion = false;
    public canNamesRepeat = false;

    private onDestroy$ = new EventEmitter<void>();

    public errorMessage = '';

    public constructor(private readonly changeDetector: ChangeDetectorRef,
                       private readonly fileStepperDataService: FileStepperDataService) {
    }

    public ngOnInit(): void {
        this.fileStepperDataService.getFilesSelected()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((files: IFileStepper[]) => {
            this.files = files;
            if (!this.childrenControls || !files) {
                return;
            }

            this.childrenControls.clear();
            files.forEach((file: IFileStepper) => {
                this.add(file);
            });
        });

        this.canGenerateVersion = this.fileStepperDataService.getCanGenerateVersionValue();
        this.canNamesRepeat = this.fileStepperDataService.getCanNamesRepeatValue();
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    public get childrenControls(): UntypedFormArray {
        if (!this.parentForm) {
            return null;
        }
        return this.parentForm.get(this.controlName) as UntypedFormArray;
    }

    private add(file: IFileStepper): void {
        this.childrenControls.push(new UntypedFormGroup({
            id: new UntypedFormControl(file.id),
            content: new UntypedFormControl(file.content),
            name: new UntypedFormControl(file.name, this.fileNameValidator(file.id)),
            size: new UntypedFormControl(file.size, this.fileValidator(file, 'hasValidSize')),
            extension: new UntypedFormControl(file.extension, this.fileValidator(file, 'hasValidExtension')),
            generateVersion: new UntypedFormControl(file.generateVersion),
            idd: new UntypedFormControl(file.idd, this.fileIddValidator(file.id)),
            error: new UntypedFormControl(file.error, this.fileErrorValidator(file.id)),
            warning: new UntypedFormControl(file.warning),
        }));

        this.changeDetector.detectChanges();
    }

    public formatBytesView(bytes: number): string{
        return formatBytes(bytes);
    }

    public remove(index: number): void {
        const item = this.childrenControls.at(index) as UntypedFormGroup;
        const fileName = item.get('name').value;

        const itemsWithSameFileName = this.childrenControls.controls.filter(control => {
            const formGroup = (control as UntypedFormGroup);
            return (formGroup.get('name').value === fileName && formGroup.get('id').value !== item.get('id').value);
        });

        if (itemsWithSameFileName.length === 1) {
            const indexControl = this.childrenControls.controls.indexOf(itemsWithSameFileName[0]);
            this.childrenControls.controls[indexControl].get('name').setErrors(null);
            this.childrenControls.updateValueAndValidity();
        }

        this.childrenControls.removeAt(index);
        this.changeDetector.detectChanges();
        this.notifyRemoveFile.emit(item.value);
    }

    public getInvalidCharactersControl(index: number): string {
        const form = this.childrenControls.at(index) as UntypedFormGroup;
        const errors = form.get('name').errors;

        if (!errors || !errors.fileNameInvalidCharacters || errors.fileNameInvalidCharacters === false) {
            return '';
        }

        return errors.invalidCharacters;
    }

    public hasUploadedFileItemError(index: number): boolean {
        const item = this.childrenControls.at(index) as UntypedFormGroup;
        return !item.valid;
    }

    public getFormControl(formControlName: string, index: number): AbstractControl {
        return this.childrenControls.controls[index].get(formControlName);
    }

    public fileNameAlreadyExists(index: number): boolean {
        if (this.canNamesRepeat) {
            return false;
        }
        const errors = this.getFormControl('name', index).errors;
        if (!errors) {
            return false;
        }
        return this.getFormControl('name', index).errors?.fileNameAlreadyExists === true;
    }

    public fileIdAlreadyExists(index: number): boolean {
        const errors = this.getFormControl('idd', index).errors;
        if (!errors) {
            return false;
        }
        return this.getFormControl('idd', index).errors?.fileIddAlreadyExists === true;
    }

    public getErrorMessage(index: number): string {
        const id = this.getFormControl('id', index).value;
        const messages: string[] = [];
        const file = this.files.find(f => f.id === id);

        if (file?.error) {
            file.error.forEach(errorNumber => {
                messages.push(errorNumber.message);
            });
        }
        
        return messages.join(' ');
    }

    public fileErrorExists(index: number): boolean {
        const errors = this.getFormControl('error', index).errors;
        if (!errors) {
            return false;
        }

        const isMessageEmpty = this.getErrorMessage(index).length === 0
        const existsErrors = this.getFormControl('error', index).errors?.fileErrorExists === true

        return existsErrors && !isMessageEmpty;
    }

    private hasFileErrorExists(id: string): boolean {
        const file = this.files.find(f => f.id === id);

        return file.error.length > 0;
    }

    private fileErrorValidator(id: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (this.hasFileErrorExists(id)) {
              return { fileErrorExists: true };
            }

            return null;
        };
    }


    public hasFileNameEmpty(index: number): boolean {
        const errors = this.getFormControl('name', index).errors;
        if (!errors) {
            return false;
        }
        return this.getFormControl('name', index).errors?.fileNameEmpty === true;
    }

    public hasFileNameInvalidCharacters(index: number): boolean {
        const errors = this.getFormControl('name', index).errors;
        if (!errors) {
            return false;
        }
        return this.getFormControl('name', index).errors?.fileNameInvalidCharacters === true;
    }

    public getInvalidCaracters(index: number): string {
        return $localize`:@@caracteresInvalidos:Caracteres inválidos introducidos: ` + this.getInvalidCharactersControl(index);
    }

    public hasFileNameMaxLength(index: number): boolean {
        const errors = this.getFormControl('name', index).errors;
        if (!errors) {
            return false;
        }
        return this.getFormControl('name', index).errors?.fileNameMaxLength === true;
    }

    public hasValidExtension(index: number): boolean {
        const errors = this.getFormControl('extension', index).errors;
        if (!errors) {
            return true;
        }
        return errors.hasValidExtension === true;
    }

    public hasValidSize(index: number): boolean {
        const errors = this.getFormControl('size', index).errors;
        if (!errors) {
            return true;
        }
        return errors.hasValidSize === true;
    }

    public onGenerateVersionChange(index: number, generateVersion: MatCheckboxChange): void {
        const item = this.childrenControls.at(index) as UntypedFormGroup;
        item.value.generateVersion = generateVersion.checked;

        this.changeDetector.detectChanges();
        this.notifyCheckedVersionFile.emit(item.value);
    }

    private fileValidator(file: IFileStepper, propertyName: string): ValidatorFn {
        return (): { [key: string]: any } | null => {
            if (file && file.hasOwnProperty(propertyName) && !file[propertyName]) {
                return { [propertyName]: false };
              }

            return null;
          };
    }

    private fileNameValidator(id: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const fileName: string = control.value;
            if (this.hasFileNameAlreadyExists(id, fileName)) {
              return { fileNameAlreadyExists: true };
            }

            if (this.getValueEmpty(fileName)) {
                return { fileNameEmpty: true };
            }

            const invalidCharacters = getInvalidCharacters(control.value);

            if (invalidCharacters !== null && invalidCharacters !== '') {
                return { fileNameInvalidCharacters: true, invalidCharacters };
            }

            if (fileName.length > 200) {
                return { fileNameMaxLength: true };
            }

            return null;
          };
    }

    private fileIddValidator(id: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const fileIdd: string = control.value;

            if (!fileIdd) {
                return null;
            }

            if (this.hasFileIddAlreadyExists(id)) {
              return { fileIddAlreadyExists: true };
            }

            const invalidCharacters = getInvalidCharacters(control.value);

            if (invalidCharacters !== null && invalidCharacters !== '') {
                return { fileNameInvalidCharacters: true, invalidCharacters };
            }

            return null;
        };
    }
    
    private getValueEmpty(value: string): boolean {
        const valueWithoutExtension = value.substring(0, value.indexOf('.'));
        return valueWithoutExtension.length === 0;
    }

    private hasFileNameAlreadyExists(id: string, fileName: string): boolean {
        return this.childrenControls.controls.some(control => {
            const form = control as UntypedFormGroup;
            return form.get('name').value === fileName && form.get('id').value !== id;
        });
    }

    private hasFileIddAlreadyExists(id: string): boolean {
        const errorFile = this.files.find(file => file.id === id)?.error;
        return errorFile.some(status => status.error === FileValidationStatus.DuplicateIdentifier);
    }
}
