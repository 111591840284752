import {
    Component, OnInit, HostBinding, Input, SimpleChanges, Inject, ElementRef, Output, EventEmitter, ViewChild, OnChanges, AfterViewInit,
} from '@angular/core';
import { PageScrollService } from 'ngx-page-scroll-core';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { ITransformCleanHtmlService } from 'src/app/core/shared/services/transform-clean-html/transform-clean-html.service.interface';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { IHtmlEventsService } from 'src/app/core/shared/services/html-events/html-events.service.interface';

declare function cargaCheck(): any;
declare function borrarLiteralesCheck(): any;
declare function Control(): any;

@Component({
    selector: 'app-html-viewer',
    templateUrl: './html-viewer.component.html'
})
export class HtmlViewerComponent implements AfterViewInit, OnChanges, OnInit {

    @Input() html: string;
    @Input() markClauseWithId: string;
    @Input() showAsReadOnly: boolean;
    @Input() addScrollSm: boolean;
    @Input() addScrollMd: boolean;
    @Input() addScrollLg: boolean;
    @Input() readOnly: boolean;
    @Input() replaceFilledInput: boolean;
    @Input() showMandatoryFields: boolean;
    @Input() className: string;
    @Input() emitWhenDone: boolean;
    @Input() emitWhenClickOnLinks = false;

    @Output() htmlContentChange = new EventEmitter<{ rawHtml: string, cleanedHtmlWithHeader: string; }>();
    @Output() completedForm = new EventEmitter<boolean>();
    @Output() progressBar = new EventEmitter<{ progressBarValue: string, progressBarDone: string; }>();
    @Output() redirect = new EventEmitter<string>();

    @ViewChild('htmlContainer') htmlContainer: ElementRef;
    @HostBinding('class') componentClass: string;

    public showButtonTop = false;
    public copiedDocument: Document;
    public copiedDocumentRawFilled: Document;

    private lockMandatoryFields = false;
    private readonly maximumReached = $localize`:@@MaximoAlcanzado:Máximo alcanzado`;
    private readonly minimumReached = $localize`:@@MinimoAlcanzado:Mínimo alcanzado`;

    constructor(
        private genericDialogService: GenericDialogService,
        private pageScrollService: PageScrollService,
        private transformCleanHtmlService: ITransformCleanHtmlService,
        private htmlEventService: IHtmlEventsService,
        @Inject(DOCUMENT) private document: any,
        private elRef: ElementRef
    ) {
        this.showMandatoryFields = false;
    }

    public ngOnInit(): void {
        this.componentClass = this.className ? 'html-viewer-main' + ' ' + this.className : 'html-viewer-main';
        if (!this.html) {
            this.html = '';
        }
    }

    public ngAfterViewInit(): void {
        this.addEventChangeDocument();
        const currentViewerNode: Element = document.getElementById('currentViewer');
        if (this.emitWhenClickOnLinks) {
            this.transformCleanHtmlService.transformLinks(currentViewerNode, this.clickOnLink);
        }
        const content = document.querySelector('app-templates-text-tab');
        const MARGIN_WITH_TOP = 100;
        Observable.fromEvent(content, 'scroll')
            .map(() => content.scrollTop)
            .subscribe(x => {
                this.showButtonTop = x >= MARGIN_WITH_TOP;
            });
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.addControl();

        const currentViewerNode: Element = document.getElementById('currentViewer');

        if (!currentViewerNode) {
            return;
        }

        this.transformCleanHtmlService.rBoxHideContent(currentViewerNode);
        this.scrollToTopElement();
    }

    public getCurrentHtml(): string {
        return document.getElementById('currentViewer').innerHTML;
    }

    public getCurrentNodeContent(): HTMLElement {
        return document.getElementById('currentViewer');
    }

    public markClause(markClauseWithId: string): void {
        if (markClauseWithId !== undefined && markClauseWithId !== null && markClauseWithId !== '') {
            const hElement: HTMLElement = this.elRef.nativeElement;
            const allClasses = hElement.getElementsByClassName('clause-in-editor');
            const idWithExtraText = 'id_' + markClauseWithId;

            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < allClasses.length; i++) {
                if (allClasses[i].id === idWithExtraText) {
                    allClasses[i].className = allClasses[i].className.replace(' mark-clause', '');
                    allClasses[i].className = allClasses[i].className + ' mark-clause';
                    return;
                }
            }
        }
    }

    private clickOnLink = (anchorElement: Element) => {
        const params = anchorElement.getAttribute('redirectparams');
        this.redirect.emit(params);
    };

    private addControl() {
        setTimeout(() => {
            this.markClause(this.markClauseWithId);
            Control();
            cargaCheck();
            borrarLiteralesCheck();
            this.eventComment();
            const currentViewerNode: Element = document.getElementById('currentViewer');
            this.addListenersToElements(currentViewerNode);

        });
    }

    private eventComment() {
        this.htmlEventService.eventComment(document, 'click');
    }

    private addListenersToElements(currentViewerNode: Element) {
        if (currentViewerNode) {
            this.transformCleanHtmlService.cleanLinkFootNotes(currentViewerNode);
        }

        const hElement: HTMLElement = this.elRef.nativeElement;

        this.addListenersEventsToElement(hElement);

        this.rBoxHideContent();
    }

    private addListenersEventsToElement(element: Element) {
        const links = element.getElementsByClassName('footNoteClassElement');
        const inputs = element.getElementsByTagName('input');
        const selects = element.getElementsByTagName('select');
        const addPlumis = element.querySelectorAll('.opt > a[title=\'Añadir un campo más al formulario\']');
        const suppPlumis = element.querySelectorAll('.opt > a[title=\'Eliminar un campo del formulario\']');

        this.addEventLinks(links);

        this.addEventInputs(inputs);

        this.addEventSelects(selects);

        this.addEventAddPlumis(addPlumis);

        this.addEventSuppPlumis(suppPlumis);
    }

    private addEventLinks(links: HTMLCollectionOf<Element>) {
        for (let i = 0; i < links.length; i++) {
            links[i].addEventListener('change', () => {
                const tag = 'tag';
                this.navigate(links[i].attributes[tag].value);
            });
        }
    }

    private addEventInputs(inputs: HTMLCollectionOf<HTMLInputElement>) {
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener('change', () => {
                this.inputChange();
            });
        }
    }

    private addEventSelects(selects: HTMLCollectionOf<HTMLSelectElement>) {
        for (let i = 0; i < selects.length; i++) {
            selects[i].addEventListener('focusout', () => {
                this.inputChange();
            });
        }
    }

    private addEventAddPlumis(addPlumis: NodeListOf<Element>) {
        for (let i = 0; i < addPlumis.length; i++) {
            addPlumis[i].addEventListener('click', () => {
                const id = addPlumis[i].parentElement.lastElementChild.id;
                this.plumisGroupAddEvent(id.replace('_0', ''));
            }, false);
        }
    }

    private addEventSuppPlumis(suppPlumis: NodeListOf<Element>) {
        for (let i = 0; i < suppPlumis.length; i++) {
            suppPlumis[i].addEventListener('click', () => {
                const id = suppPlumis[i].parentElement.lastElementChild.id;
                this.plumisGroupSuppEvent(id.replace('_0', ''));
            }, false);
        }
    }

    private navigate(element: string) {
        this.pageScrollService.scroll({
            document: this.document,
            scrollTarget: '#' + element,
        });
    }

    private rBoxHideContent() {
        const currentViewerNode: Element = document.getElementById('currentViewer');
        if (!currentViewerNode) {
            return;
        }

        const radioElements = currentViewerNode.querySelectorAll('[class="rBox"]');
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < radioElements.length; i++) {
            radioElements[i].className = radioElements[i].className + ' wHelp';
        }
    }

    private inputChange() {
        this.copiedDocument = new Document();
        this.copiedDocumentRawFilled = new Document();
        const DO_NOT_REPLACE_IN_RAW = false;

        const currentViewerNode = document.getElementById('currentViewer');
        if (!currentViewerNode) {
            return;
        }

        this.copiedDocumentRawFilled = (document.cloneNode(false) as Document);
        this.copiedDocumentRawFilled.getRootNode().appendChild(currentViewerNode.cloneNode(true));

        const raw = this.transformCleanHtmlService.moveValuesTextService(this.copiedDocumentRawFilled, DO_NOT_REPLACE_IN_RAW);

        this.copiedDocument = (document.cloneNode(false) as Document);
        this.copiedDocument.getRootNode().appendChild(currentViewerNode.cloneNode(true));

        const transformHtmlWithHeader = this.transformCleanHtmlService
            .transformHtmlFillInputsToTextService(this.copiedDocument, this.replaceFilledInput, false);

        if (this.showMandatoryFields) {
            const domParser = new DOMParser();
            const titleValue = $localize`:@@CampoObligatorio:Campo obligatorio`;
            const documentCleanToSearch = domParser.parseFromString(transformHtmlWithHeader, 'text/html');
            const anySelectEmpty = documentCleanToSearch.querySelectorAll('select');
            const anyRadioEmpty = documentCleanToSearch.querySelectorAll('input[type=radio]');
            this.transformCleanHtmlService.addMandatorySelectInputs(document, Array.from(anySelectEmpty), titleValue);
            this.transformCleanHtmlService.addMandatoryRadioInputs(document, Array.from(anyRadioEmpty), titleValue);
        }

        this.htmlContentChange.emit({ rawHtml: raw, cleanedHtmlWithHeader: transformHtmlWithHeader });
    }

    private addEventChangeDocument() {
        const observer = new MutationObserver((mutations) => {

            if (this.emitWhenDone) {
                this.checkIfItIsDoneAfterDocumentLoaded(mutations);
                this.checkIfItIsDoneAfterElementChanged(mutations);
                this.checkProgress(mutations);
            }

            if (this.lockMandatoryFields === true) {
                return;
            }
            this.checkInputs();
        });

        observer.observe(this.htmlContainer.nativeElement, {
            attributes: true, characterData: true,
            childList: true, subtree: true,
            attributeOldValue: true, characterDataOldValue: true
        });
    }

    private checkProgress(mutations: MutationRecord[]) {
        const mutationsTotalElement = mutations.find(m => (m.target instanceof HTMLElement) && (m.target as HTMLElement).id === 'pContestadas');
        const mutationsTotalDoneElement = mutations.find(m => (m.target instanceof HTMLElement) && (m.target as HTMLElement).id === 'pContestar');

        if (!mutationsTotalElement || !mutationsTotalDoneElement) {
            return;
        }

        const mutationsTotal = mutationsTotalElement.target.textContent;
        const mutationsTotalDone = mutationsTotalDoneElement.target.textContent;

        this.progressBar.emit({ progressBarValue: mutationsTotal, progressBarDone: mutationsTotalDone });
    }

    private checkIfItIsDoneAfterDocumentLoaded(mutations: MutationRecord[]) {
        const mutationsToCheck = mutations.find(m => (m.target instanceof HTMLElement) && (m.target as HTMLElement).id === 'currentViewer');
        if (!mutationsToCheck) {
            return;
        }

        if ((mutationsToCheck.target as HTMLElement).querySelector('#tBody').classList.contains('done')) {
            this.completedForm.emit(true);
        } else {
            this.completedForm.emit(false);
        }
    }

    private checkIfItIsDoneAfterElementChanged(mutations: MutationRecord[]) {

        const mutationsToCheck = mutations.find(m => (m.target instanceof HTMLElement) &&
            (m.target as HTMLElement).id === 'tBody' &&
            (m.target as HTMLElement).classList.contains('FO'));

        if (!mutationsToCheck) {
            return;
        }

        const NOT_FOUND = -1;

        if (mutationsToCheck.oldValue.search('\bdone\b') && !(mutationsToCheck.target as HTMLElement).classList.contains('done')) {
            this.completedForm.emit(false);
        } else if (mutationsToCheck.oldValue.search('\bdone\b') === NOT_FOUND &&
            (mutationsToCheck.target as HTMLElement).classList.contains('done')) {
            this.completedForm.emit(true);
        }
    }

    private checkInputs() {
        this.lockMandatoryFields = true;
        this.inputChange();
        setTimeout(() => { this.unlockMandatoryFields(); });
    }

    private unlockMandatoryFields() {
        this.lockMandatoryFields = false;
    }

    private scrollToTopElement(): void {
        const element = document.querySelector('.html-viewer-main');
        element.scrollIntoView(true);
        element.parentElement.scrollIntoView(true);
    }

    private plumisGroupAddEvent(id: string) {
        const hElement: HTMLElement = this.elRef.nativeElement;
        const elementToCopy = hElement.querySelector('#' + id + '_1');
        const parent = elementToCopy.parentElement;
        if (parent.children.length >= 10) {
            this.showMessage(this.maximumReached);
            return;
        }
        const countNext = parent.children.length + 1;
        const cloneObject = this.cloneNodeWithDiferentId(id, elementToCopy, countNext);
        parent.appendChild(cloneObject);

        this.addListenersEventsToElement(cloneObject);
    }

    private plumisGroupSuppEvent(id: string) {
        const hElement: HTMLElement = this.elRef.nativeElement;
        const parent = hElement.querySelector('#' + id + '_0');
        if (parent.children.length <= 1) {
            this.showMessage(this.minimumReached);
            return;
        }

        const lastChildToRemove = parent.lastElementChild;
        lastChildToRemove.remove();
    }

    private cloneNodeWithDiferentId(id: string, nodo: any, count: number) {
        const clon = nodo.cloneNode(true) as HTMLElement;
        clon.id = id + '_' + count;
        clon.querySelectorAll('[id]').forEach((elementoHijo: any) => {
            elementoHijo.id = elementoHijo.id + '_' + count;
            elementoHijo.name = elementoHijo.name + '_' + count;
            elementoHijo.setAttribute('data-cke-saved-name', elementoHijo.name);
        });

        return clon;
    }

    private showMessage(message: string): void {
        this.genericDialogService.showMessage(message);
    }
}
