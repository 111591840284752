import { Injectable } from "@angular/core";
import { ButtonView, DecoupledEditor, View, } from "ckeditor5";
import { SignaturePlugin } from "../custom-plugins/plugins/signature/signature-plugin";
import { EditorPluginConfig } from "../models/editor-plugin-config";

@Injectable({
    providedIn: "root",
})
export class EditorUtilsService {

    private DISABLE_PLUGIN_LOCK = 'fb445a1b-6bb9-47d0-9249-8ba5b8e3419c';

    constructor() {}

    public enableDisablePlugins(editorInstance: DecoupledEditor, configuration: EditorPluginConfig): void {
        this.enableDisableSignaturePlugins(editorInstance, configuration);
    }

    public showToolbarButton(editorInstance: DecoupledEditor, label: string): void {
        this.setToolbarButtonVisibility(editorInstance, label, true);
    }

    public hideToolbarButton(editorInstance: DecoupledEditor, label: string): void {
        this.setToolbarButtonVisibility(editorInstance, label, false);
    }

    private setToolbarButtonVisibility(editorInstance: DecoupledEditor, label: string, isVisible: boolean): void {
        const button = this.getToolbarButton(editorInstance, label);
        if (button) {
            button.isVisible = isVisible;
        }
    }

    private enableDisableSignaturePlugins(editorInstance: DecoupledEditor,  configuration: EditorPluginConfig) {
        const signature = editorInstance?.plugins.get(
            SignaturePlugin.pluginName
        );
        const signaturePlugin = signature as SignaturePlugin;

        if (!signaturePlugin) {
            return;
        }

        if (configuration?.showSignature) {
            signaturePlugin?.clearForceDisabled(this.DISABLE_PLUGIN_LOCK);
            this.showToolbarButton(editorInstance, SignaturePlugin.toolbarButtonName);
        } else {
            signaturePlugin.forceDisabled(this.DISABLE_PLUGIN_LOCK);
            this.hideToolbarButton(editorInstance, SignaturePlugin.toolbarButtonName);
        }
    }

    private finderButtonByLabel = (view: View, label: string): boolean => {
        const button = view as ButtonView;
        return button?.label === label;
    };

    private getToolbarButton(editorInstance: DecoupledEditor, label: string): ButtonView | null {
        const toolbarElement = editorInstance.ui.view.toolbar.items.find(
            (item: View<HTMLElement>) => this.finderButtonByLabel(item, label)
        );
        return toolbarElement as ButtonView || null;
    }
}
