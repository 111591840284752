import { Injectable } from "@angular/core";
import { clickOutsideHandler, ContextualBalloon, Editor, Plugin, View } from "ckeditor5";
import { PluginUtilsService } from "../../utils/plugin-utils.service";

@Injectable({
    providedIn: 'root'
  })
export class UserInterfaceService {

    protected pluginUtils: PluginUtilsService;

    constructor() {
        this.pluginUtils = new PluginUtilsService();
    }

    public addUI(editor: Editor, balloon: ContextualBalloon, balloonView: View,
        editionViewClass: string, visualSelectionMarker: string ): void
    {

        if (balloon.hasView(balloonView)) {
            balloon.remove(balloonView!);
        }

        balloon.add({
            view: balloonView!,
            position: this.pluginUtils.getBalloonPositionData(editor, editionViewClass, visualSelectionMarker),
        });
    }

    public enableUserBalloonInteractions(
        editor: Editor, balloon: ContextualBalloon, balloonView: View, visualSelectionMarker: string, plugin: Plugin
    ): void {
        this.moveWithTabInBalloon(editor, balloon, balloonView);
        this.closeBallonOnEscKey(editor, balloon, balloonView, visualSelectionMarker, plugin)
        this.configureCloseBallonWithOutsideClick(editor, balloon, balloonView, visualSelectionMarker, plugin);
    }

    public hideUI(editor: Editor, balloon: ContextualBalloon, balloonView: any, visualSelectionMarker: string, plugin: Plugin) : void {
        if (this.isBalloonInPanel(balloon, balloonView)) {
            balloon.remove(balloonView);
        }

        this.removeBalloonObservers(editor, balloon, plugin);

        editor.editing.view.focus();

        this.removeBalloonView(editor, balloon, balloonView, visualSelectionMarker);

        if (balloon.hasView(balloonView)) {
            balloon.remove(balloonView!);
        }

        this.hideFakeVisualSelection(editor, visualSelectionMarker);
    }

    public isBalloonInPanel(balloon: ContextualBalloon, balloonView: View): boolean {
        return !!balloonView && balloon.hasView(balloonView);
    }

    public removeBalloonView(editor: Editor, balloon: ContextualBalloon, balloonView: any, visualSelectionMarker: string): void {
        if (!this.isBalloonInPanel(balloon, balloonView)) {
            return;
        }

        balloonView!.resetFormStatus();
        balloon.remove(balloonView!);
        editor.editing.view.focus();
        this.hideFakeVisualSelection(editor, visualSelectionMarker);
    }


    public hideFakeVisualSelection(editor: Editor, visualSelectionMarker: string): void {
        const model = editor.model;

        if (model.markers.has(visualSelectionMarker)) {
            model.change((writer) => {
                writer.removeMarker(visualSelectionMarker);
            });
        }
    }

    public removeBalloonObservers(editor: Editor, balloon: ContextualBalloon, plugin: Plugin): void {
        plugin.stopListening(editor.ui, "update");
        plugin.stopListening(balloon, "change:visibleView");
    }

    public areActionsVisible(balloon: ContextualBalloon, balloonView: View): boolean {
        return !!balloon && balloon.visibleView === balloonView;
    }

    public configureCloseBallonWithOutsideClick(editor: Editor, balloon: ContextualBalloon, balloonView: View, visualSelectionMarker: string, plugin: Plugin) : void {
        clickOutsideHandler({
            emitter: balloonView!,
            activator: () => this.isBalloonInPanel(balloon, balloonView),
            contextElements: () => [balloon.view.element!],
            callback: () => this.hideUI(editor, balloon, balloonView, visualSelectionMarker, plugin),
        });
    }

    public moveWithTabInBalloon(editor: Editor, balloon: ContextualBalloon, balloonView: any): void {
        editor.keystrokes.set("Tab", (_, cancel) => {
            if (this.areActionsVisible(balloon, balloonView) && !balloonView.focusTracker.isFocused) {
                balloonView!.focus();
                cancel();
            }
        }, { priority: "high" });
    }

    private closeBallonOnEscKey(editor: Editor, balloon: ContextualBalloon, balloonView: View, visualSelectionMarker: string, plugin: Plugin): void {
        editor.keystrokes.set("Esc", (data, cancel) => {
            if (this.areActionsVisible(balloon, balloonView)) {
                this.hideUI(editor, balloon, balloonView, visualSelectionMarker, plugin);
                cancel();
            }
        });
    }

}
