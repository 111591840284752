import { Observable } from 'rxjs';
import { TemplateFolderDTOOperation } from 'src/app/api/model/templateFolderDTOOperation';
import { FolderItemInformationDto, TemplateFolderDto } from 'src/app/api';

export abstract class ITemplatesFolderService {

    abstract setCurrentFolderName(folderName: string): void;
    abstract getCurrentFolderName(): string;

    abstract getCorrectedFolderName(originalFolderName: string): string;

    abstract setCurrentFolderId(folderId: string): void;
    abstract getCurrentFolderId(): Observable<string>;
    abstract getCurrentFolderIdValue(): string;

    abstract isCurrentFolderValid(nodeId: string);

    abstract getTemplateFoldersNeedToBeReloadSubscription(): Observable<void>;
    abstract notifyTemplateFoldersNeedToBeReload(): void;

    abstract getTemplatesListNeedToBeReloadSubscription(): Observable<void>;
    abstract notifyTemplatesListNeedToBeReload(): void;

    abstract getOperationReplaceTemplateFolder(valueToChange: string, newValue: any ): TemplateFolderDTOOperation[];
    abstract getFileIdsInFolderRecusively(folderId: string, folders: TemplateFolderDto[]): FolderItemInformationDto[];
}
