import { Directive } from '@angular/core';
import { EditorPermissionConfig } from '../wysiwyg-editor/models/editor-permission-config';
import { EditorPluginConfig } from '../wysiwyg-editor/models/editor-plugin-config';

@Directive()
export abstract class BaseWithEditorDirective {

    public permissionConfig: EditorPermissionConfig = new EditorPermissionConfig();
    public pluginConfig: EditorPluginConfig = new EditorPluginConfig();
    public isReadOnly = false;
    public resetUnsavedChangesNotifier = false;

    protected configureEditionWithPermission(permissions: any) {
        if (permissions.canEdit) {
            this.enableEdition();
        } else {
            this.disableEdition();
        }
    }

    protected enableEdition() {
        const permissionConfig = new EditorPermissionConfig();
        permissionConfig.canEdit = true;

        this.permissionConfig = permissionConfig;
    }

    protected disableEdition() {
        const permissionConfig = new EditorPermissionConfig();
        permissionConfig.canEdit = false;

        this.permissionConfig = permissionConfig;
    }

    protected enableReadOnly() {
        this.isReadOnly = true;
    }

    protected disableReadOnly() {
        this.isReadOnly = false;
    }

    protected notifyResetUnsavedChanges(resetUnsavedChanges: boolean) {
        this.resetUnsavedChangesNotifier = resetUnsavedChanges;
    }

    protected configurePluginsDocument(permissions: any): void {
        this.pluginConfig.showSignature = permissions.canEdit;
        this.pluginConfig.showAddClause = permissions.canAddClauses;

        this.pluginConfig = JSON.parse(JSON.stringify(this.pluginConfig));
    }

    protected configurePluginsTemplate(permissions: any): void {
        this.pluginConfig.showSignature = permissions.canEdit;
        this.pluginConfig.showAddClause = permissions.canAddClauses;

        this.pluginConfig = JSON.parse(JSON.stringify(this.pluginConfig));
    }

    protected configurePluginsClause(): void {
        this.pluginConfig.showSignature = false;
        this.pluginConfig.showAddClause = false;

        this.pluginConfig = JSON.parse(JSON.stringify(this.pluginConfig));
    }

}
