import { Command, Position } from 'ckeditor5';

export abstract class BaseCommand extends Command {

    public abstract labelDataModel: string;

    public override refresh(): void {
        const model = this.editor.model;
        const selection = model.document.selection;
        const position: Position | null = selection.getFirstPosition();
        if (!position) {
            return;
        }
        const allowedIn = model.schema.findAllowedParent(position, this.labelDataModel);
        this.isEnabled = allowedIn !== null;
    }
}
