import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs/Observable';

export interface ComponentUnlockDeactivate {
    unlock: () => boolean | Observable<boolean>;
}

@Injectable()
    export class UserFileLockGuard implements CanDeactivate<ComponentUnlockDeactivate> {

    public canDeactivate(component: ComponentUnlockDeactivate): boolean | Observable<boolean> {
        if (component == null) {
            return true;
        }

        return component.unlock();
    }
  }
