import { Observable } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ITreeStandardService } from 'src/app/shared/components/ctbox-tree/service/tree-standard-service.interface';
import { FileFlatNode } from '../models/file-flat-node.model';
import { MenuItem } from 'src/app/shared/components/ctbox-menu/models/menu-item.model';
import { Component, EventEmitter, HostBinding, Input, Output, SimpleChanges, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { NodeTreeAction } from '../models/node-tree-action.model';
import { NodeTreeActionType } from '../enums/node-tree-action-type.enum';
import { NodeTreeUserAction } from '../enums/node-tree-user-action.enum';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'ctbox-tree-actions',
  templateUrl: './ctbox-tree-actions.component.html'
})
export class CtboxTreeActionsComponent implements OnInit, OnDestroy, OnChanges {

    @Input() actions: NodeTreeUserAction[];
    @Input() menuList: MenuItem[];
    @Input() menuIcon = 'more_vert';
    @Input() node: FileFlatNode;
    @Input() isTreeLocked: boolean;
    @Input() updateNodeObservable: Observable<void>;

    @Output() public onNodeAction: EventEmitter<NodeTreeAction> = new EventEmitter<NodeTreeAction>(null);

    @HostBinding('className') componentClass: string;

    public menuTitleLocked = $localize`:@@ArbolTituloMenuArbolBloqueado:Bloqueo por edición`;
    public action: NodeTreeUserAction;
    public nodeInfo = '';

    public get NodeTreeUserAction(): typeof NodeTreeUserAction {
        return NodeTreeUserAction;
    }

    private onDestroy$ = new EventEmitter<void>();

    constructor(private treeService: ITreeStandardService) {
        this.componentClass = 'ctbox-tree-actions';
    }

    ngOnInit(): void {
        this.setAction();
        this.componentClass = this.componentClass + ' ' + this.action;

        if (this.updateNodeObservable) {
            this.updateNodeObservable.pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
                this.setAction();
            });
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    ngOnChanges(changes: SimpleChanges): void {
       if (changes.actions || changes.node) {
            this.setAction();
       }
    }

    public nodeChange(checkToggle: MatSlideToggleChange) {
        const isChecked = checkToggle.checked;
        const additionalParams = this.treeService.createAdditionalParamWithIsChecked(isChecked);
        this.node.isChecked = isChecked;
        const actionEvent = this.treeService.createNodeTreeActionWithAdditionalParam(this.node,
            NodeTreeActionType.Checked, additionalParams);
        this.onNodeAction.emit(actionEvent);
    }

    public checkAll() {
        const isChecked = true;
        this.toogleCheckAction(isChecked);
    }

    public uncheckAll() {
        const isChecked = false;
        this.toogleCheckAction(isChecked);
    }

    public selectNodeButton() {
        const actionButton = this.treeService.createNodeTreeAction(this.node, NodeTreeActionType.NodeButton);
        this.onNodeAction.emit(actionButton);
    }

    private setAction() {
        this.nodeInfo = '';
        if (this.actions.includes(NodeTreeUserAction.Menu) &&  this.node.permissions.some(a => a === NodeTreeActionType.Create ||
                    a ===  NodeTreeActionType.Move ||
                    a ===  NodeTreeActionType.Delete ||
                    a === NodeTreeActionType.Download)) {
            this.action = NodeTreeUserAction.Menu;
            return;
        }

        if (this.actions.includes(NodeTreeUserAction.ButtonsCheckedAll) &&  this.node.permissions.includes(NodeTreeActionType.CheckedAll)) {
            this.action = NodeTreeUserAction.ButtonsCheckedAll;
            return;
        }

        if (this.actions.includes(NodeTreeUserAction.Slider) &&  this.node.permissions.includes(NodeTreeActionType.Checked)) {
            this.action = NodeTreeUserAction.Slider;
            return;
        }

        if (this.actions.includes(NodeTreeUserAction.Button) &&  this.node.permissions.includes(NodeTreeActionType.NodeButton)) {
            this.action = NodeTreeUserAction.Button;
            return;
        }

        if (this.actions.includes(NodeTreeUserAction.ImageCheck)) {
            this.action = NodeTreeUserAction.ImageCheck;
            return;
        }

        if (this.actions.includes(NodeTreeUserAction.Info)) {
            this.action = NodeTreeUserAction.Info;
            this.nodeInfo = this.node.additionalData;
            return;
        }

        this.action = null;
    }

    private toogleCheckAction(isChecked: boolean): void {
        if (this.isTreeLocked) {
            return;
        }

        const additionalParams = this.treeService.createAdditionalParamWithIsChecked(isChecked);
        const actionEvent = this.treeService
            .createNodeTreeActionWithAdditionalParam(this.node, NodeTreeActionType.CheckedAll, additionalParams);

        this.onNodeAction.emit(actionEvent);
    }
}
