import { OptionalFragmentCheckPlugin } from './../../plugins/optional-fragment-check/optional-fragment-check-plugin';
import { Injectable } from '@angular/core';
import { Conversion, UpcastDispatcher, ViewElement } from 'ckeditor5';

@Injectable({
    providedIn: 'root'
})
export class FragmentCheckDataViewToModelConverterService {


    constructor() {
    }

    public configureConverters(conversion: Conversion) {
        this.containerAndContentConversion(conversion);
        this.inputConversion(conversion);
        this.descriptionConversion(conversion);
    }

    private containerAndContentConversion(conversion: Conversion) {
        conversion.for("upcast").add((dispatcher: UpcastDispatcher) => {
            dispatcher.on('element:div', (_evt, data, conversionApi) => {
                const {
                    consumable, writer, safeInsert, convertItem, convertChildren, updateConversionResult
                } = conversionApi;

                const { viewItem } = data;

                const containerToConvert = { name: true, classes: OptionalFragmentCheckPlugin.containerClass };
                const checkViewElement = { name: true, 'type': 'checkbox' };
                const spanDescElement = {name: true, classes: OptionalFragmentCheckPlugin.labelCheckClass};


                if (!consumable.test(viewItem, containerToConvert)) {
                    return;
                }

                if (viewItem.childCount < 3) {
                    return;
                }

                const firstChildItem = viewItem.getChild(0);

                if (!firstChildItem.is('element', 'input') || !consumable.test(firstChildItem, checkViewElement)) {
                    return;
                }

                const isChecked = viewItem.getAttribute('checked') !== 'undefined';
                const id = firstChildItem.getAttribute('id');

                const containerModelElement = writer.createElement(OptionalFragmentCheckPlugin.labelModel, {
                  'checked': isChecked,
                  'id': id
                });

                if (!safeInsert(containerModelElement, data.modelCursor)) {
                    return;
                }

                consumable.consume(viewItem, containerModelElement);

                const checkObject = convertItem(firstChildItem, containerModelElement);
                consumable.consume(firstChildItem, checkObject);


                const descriptionViewElement = { name: true, classes: 'hdesc' };

                const secondChildItem = viewItem.getChild(1);
                let descriptionObject: any;
                if(secondChildItem.is('element', 'span') && consumable.test(secondChildItem, spanDescElement)) {
                    consumable.consume(secondChildItem, secondChildItem);
                } else if (secondChildItem.is('element', 'div') && consumable.test(secondChildItem, descriptionViewElement)) {
                    descriptionObject = convertItem(secondChildItem, checkObject.modelCursor);
                    consumable.consume(secondChildItem, secondChildItem);
                }


                const thirdChildItem = viewItem.getChild(2);
                if (thirdChildItem.is('element', 'div') && consumable.test(thirdChildItem, descriptionViewElement)) {
                    descriptionObject = convertItem(thirdChildItem, checkObject.modelCursor);
                    consumable.consume(thirdChildItem, secondChildItem);
                }

                const contentElement = writer.createElement(OptionalFragmentCheckPlugin.labelContentModel);
                writer.append( contentElement, containerModelElement);

                convertChildren(viewItem, contentElement);

                updateConversionResult(containerModelElement, data);
            }, { priority: 'high' });
        });
    }

    private inputConversion(conversion) {
        conversion.for("upcast").elementToElement({
            view: {
                name: "input",
            },
            model: (viewElement: ViewElement, { writer }) => {
                const isCheckType = viewElement.getAttribute('type') === 'checkbox';
                if(!isCheckType) {
                    return;
                }

                const isChecked = viewElement.getAttribute('checked') !== 'undefined';
                const id = viewElement.getAttribute('id');
                const name = viewElement.getAttribute('name');

                return writer.createElement(OptionalFragmentCheckPlugin.labelCheckModel, {
                    'checked': isChecked,
                    'id': id,
                    'data-name': name
                });
            },
        }, {priority: "high"});
    }

    private descriptionConversion(conversion: Conversion) {
        conversion.for("upcast").elementToElement({
            view: {
                name: "div",
                classes: ['hdesc'],
            },
            model: (_viewElement: ViewElement, { writer }) => {
                return writer.createElement(OptionalFragmentCheckPlugin.labelDescriptionModel) ;
            },
        });
    }
}
