import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';

@Injectable({
    providedIn: 'root'
})
export class SendDataService {

    private subject = new Subject<any>();
    private loadDocument = new BehaviorSubject<boolean>(false);

    constructor() { }

    sendLoadDocument(element: boolean) {
        this.loadDocument.next(element);
    }

    getLoadDocument(): BehaviorSubject<boolean> {
        return this.loadDocument;
    }

    getLoadDocumentValue(): boolean {
        return this.loadDocument.getValue();
    }

    sendData(element: any) {
        this.subject.next(element);
    }

    clearData() {
        this.subject.next();
    }

    getData(): Observable<any> {
        return this.subject.asObservable();
    }
}
