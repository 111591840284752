import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { ClauseDTO, ClauseService } from 'src/app/api';

@Component({
    selector: 'app-list-clause-modal',
    templateUrl: './list-clause-modal.component.html',
    styleUrls: ['./list-clause-modal.component.scss']
})
export class ListClauseModalComponent implements OnInit {

    public clauses: ClauseDTO[] = [];
    public clausesList: ClauseDTO[] = [];
    public clausesListObs = new Subject<ClauseDTO[]>();
    public selectedClauses = [];
    public isLoadingFirstTime = true;
    public searchForm: UntypedFormGroup;
    public resultSearch: boolean;
    public errorMessage: string;
    public isSelectedClauses: boolean;

    public currentSearchValue = '';

    private isValidSubscription = new BehaviorSubject<boolean>(false);

    constructor(private api: ClauseService) { }

    public ngOnInit(): void {
        this.clausesListObs.asObservable().subscribe(c => {
            this.clausesList = c;
        });

        this.isLoadingFirstTime = false;
        this.initClausesList();
        this.initFormModel();
    }

    public initClausesList() {
        this.api.clausesGet().subscribe((cl) => {
            this.clausesListObs.next(cl.sort((a, b) => a.name.localeCompare(b.name)));
            this.clauses = cl.sort((a, b) => a.name.localeCompare(b.name));
        });
    }

    public OnCheckboxSelect(clause: ClauseDTO, event) {

        if (event.checked === true) {
            this.selectedClauses.push(clause);
        }

        if (event.checked === false) {
            this.selectedClauses = this.selectedClauses.filter((item) => item.id !== clause.id);
        }
        this.isSelectedClauses = this.selectedClauses.length !== 0;

        this.isValidSubscription.next(this.isSelectedClauses);
    }

    public isASingleLine(description: string): boolean {
        if (description.length > 87) {
            return true;
        }
        return false;
    }

    private initFormModel() {
        this.searchForm = new UntypedFormGroup(
            {
                searchTermInput: new UntypedFormControl()
            }
        );
    }

    public onSearchButtonClick() {
        this.currentSearchValue = this.searchForm.controls.searchTermInput.value;
        if (this.currentSearchValue && this.currentSearchValue.trim() !== '') {
            const resultClause = this.clauses.filter(clauseToCheck => clauseToCheck.name.trim().toLowerCase().indexOf(this.currentSearchValue) > -1);
            this.clausesListObs.next(resultClause);
            this.errorMessage = resultClause.length !== 0 ? '' : $localize`:@@NoHayResultadosClausulas:No hay cláusulas en tu Biblioteca que cumplan tus criterios de búsqueda. Te aconsejamos que los revises y realices una nueva búsqueda para encontrar la cláusula o modelo que necesitas.`;
        }
    }

    public clearSearchField() {
        this.currentSearchValue = '';
        this.searchForm.controls.searchTermInput.setValue(this.currentSearchValue);
        this.initClausesList();
    }

    public onSearchKeyPress(event: KeyboardEvent) {
        this.onSearchButtonClick();
    }

}
