import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs-compat';
import { ISmartformParamsService } from './smartform-params.service.interface';

@Injectable({
  providedIn: 'root'
})

export class SmartformParamsService extends ISmartformParamsService{

    private tocTemplateId = new BehaviorSubject<string>('');

    constructor() {
        super();
    }

    public getTocTemplateId(): string {
        return this.tocTemplateId.getValue();
    }

    public setTocTemplateId(tocTemplateId: string): void {
        this.tocTemplateId.next(tocTemplateId);
    }
}
