import { Injectable } from "@angular/core";
import { Schema } from "ckeditor5";
import { OptionalFragmentCheckPlugin } from "../../plugins/optional-fragment-check/optional-fragment-check-plugin";
import { ALLOW_CONTENT_OF_VALUES, INHERIT_ALL_FROM_VALUES } from "../schema-values";

@Injectable({
    providedIn: 'root'
})
export class OptionalFragmentCheckSchemaService {

    constructor() {
    }

    public defineSchema(schema: Schema): void {
        this.registerContainer(schema);
        this.registerCheck(schema);
        this.registerDescription(schema);
        this.registerContent(schema);
    }

    private registerContainer(schema: Schema) {
        schema.register(OptionalFragmentCheckPlugin.labelModel, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.CONTAINER,
            allowAttributes: ['id','checked'],
            allowContentOf: ALLOW_CONTENT_OF_VALUES.ROOT,
            isObject: true
        });
    }

    private registerCheck(schema: Schema) {
        schema.register(OptionalFragmentCheckPlugin.labelCheckModel, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.BLOCK_OBJECT,
            allowAttributes: ['id', 'data-name', 'checked'],
            allowContentOf: ALLOW_CONTENT_OF_VALUES.ROOT,
            allowIn: ['optional-fragment-check']
        });
    }

    private registerDescription(schema: Schema) {
        schema.register( OptionalFragmentCheckPlugin.labelDescriptionModel, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.BLOCK_OBJECT,
            allowContentOf: ALLOW_CONTENT_OF_VALUES.TEXT,
            allowIn: ['optional-fragment-check'],
        });
    }

    private registerContent(schema: Schema) {
        schema.register(OptionalFragmentCheckPlugin.labelContentModel, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.BLOCK_OBJECT,
            allowContentOf: ALLOW_CONTENT_OF_VALUES.ROOT,
        });
    }
}
