import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompareHtmlVersionResultDTO } from 'src/app/api/model/compareHtmlVersionResultDTO';
import { VersionInfo } from 'src/app/api/model/versionInfo';
import { ViewEncapsulation } from '@angular/core';
import { WysiwygEditorComponent } from '../../wysiwyg-editor/wysiwyg-editor.component';


@Component({
    selector: 'version-comparator',
    templateUrl: './version-comparator.component.html',
    styleUrls: ['./version-comparator.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CtboxVersionComparatorComponent  {

    @ViewChild('documentEditor') documentEditor: WysiwygEditorComponent;

    public compareHtmlVersionResult: CompareHtmlVersionResultDTO;
    public latestVersion: VersionInfo;
    public currentVersionSelected: VersionInfo;

    constructor(@Inject(MAT_DIALOG_DATA) public data
    ) {
        this.compareHtmlVersionResult = data.compareHtmlVersionResult;
        this.latestVersion = data.latestVersion;
        this.currentVersionSelected = data.currentVersionSelected;
    }
}
