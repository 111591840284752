import { OptionalFragmentCheckPlugin } from './../../plugins/optional-fragment-check/optional-fragment-check-plugin';
import {  Writer } from 'ckeditor5';
import { OptionalFragmentCommand } from './optional-fragment-command';


export default class DeleteOptionalFragmentCommand extends OptionalFragmentCommand {

    public override execute(id: string) {
        const editor = this.editor;

        editor.model.change((writer: Writer) => {
            const range = writer.createRangeIn(editor.model.document.getRoot()!);

            let optionalFragmentModel: any;
            for (const value of range.getWalker()) {
                if (value.item.hasAttribute('id') && value.item.getAttribute('id') === id) {
                    optionalFragmentModel = value.item;
                    break;
                }
            }

            let contentToMove: any;
            for (const child of optionalFragmentModel.getChildren()) {
                if (child.name === OptionalFragmentCheckPlugin.labelContentModel) {
                    contentToMove = child;
                    break;
                }
            }

            this.moveContentModelAfterOptionalFragmentComponent(writer, contentToMove, optionalFragmentModel);
            this.removeContentDivAndExtractChildrenToParent(writer, contentToMove);
            writer.remove(optionalFragmentModel);

        });
    }


    private moveContentModelAfterOptionalFragmentComponent(writer: Writer, contentToMove: any, containerModel: any) {
        writer.insert(contentToMove, containerModel, 'after');
    }

    private removeContentDivAndExtractChildrenToParent(writer: Writer, contentToMove: any) {
        writer.unwrap(contentToMove);
    }
}
