<div class="list-search">
    <form [formGroup]="searchForm" class="list-search-form">
        <mat-form-field class="ctbox-search-form">
            <mat-label i18n="@@ListadoDeClausulasModalMensajeTooltipBuscador">Buscar en la biblioteca</mat-label>
            <input #searchTermInput matInput name="searchTermInput" formControlName="searchTermInput" (keypress)="onSearchKeyPress($event)">
            <ctbox-button buttonIcon="close" buttonColor="secondary" (buttonClick)="clearSearchField()" *ngIf="currentSearchValue !==''" matSuffix></ctbox-button>
            <ctbox-button buttonIcon="search" buttonColor="secondary" (buttonClick)="onSearchButtonClick()" *ngIf="currentSearchValue ===''" matSuffix></ctbox-button>
        </mat-form-field>
    </form>
</div>
<div class="list-body">
    <ng-container *ngIf="clausesList.length > 0">
        <mat-accordion class="list-clauses">
            <mat-expansion-panel class="list-clauses mat-elevation-z" *ngFor="let clauses of clausesList">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-checkbox value="{{clauses.id}}" (change)="OnCheckboxSelect(clauses, $event)">
                            <label>{{clauses.name}}</label>
                        </mat-checkbox>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-panel-description>
                    <div class="item-html" [innerHTML]="clauses.description | sanitizeHtml"></div>
                </mat-panel-description>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>
    <ng-container *ngIf="isLoadingFirstTime && clausesList.length === 0">
        <p class="not-found" i18n="@@NoTieneClausulas">No tienes ninguna cláusula creada, por favor pulsa "Crear cláusula" y guárdala en la Biblioteca</p>
    </ng-container>
    <ng-container *ngIf="errorMessage">
        <p class="list-error">{{errorMessage}}</p>
    </ng-container>
</div>