export const environment = {
    production: false,
    apiBaseURL: 'https://cmcapi-test.laleynext.es',
    myAccountExternalLoginUrl: 'https://miespacio-qa.laleynext.es/Account/MyAccountExternalLoginWithoutRegister',
    myAccountExternalLoginUrlWithRegister: 'https://miespacio-qa.laleynext.es/Account/MyAccountExternalLogin',
    myAccountLogoutUrl: 'https://miespacio-qa.laleynext.es/Account/LogOff',
    myAccountExternalLink: 'https://miespacio-qa.laleynext.es',
    myAccountOpenMyLicenses: 'https://miespacio-qa.laleynext.es/Licenses/LicensesList#/user-list',
    laleyUrl: 'https://www.laley.es/',
    laleydigitalUrl: 'https://laleydigital-qa.laleynext.es/Content/Formularios.aspx',
    laleydigitalLanding: 'https://laleydigital-qa.laleynext.es/Content/Inicio.aspx',
    laleydigitalAllContent:
        'https://laleydigital-qa.laleynext.es/Content/Busqueda.aspx?params=H4sIAAAAAAAEAMssdiwoKMovS02xNTIwMjSwNDI2NDY0BQAfePjdFwAAAA==WKE',
    laleydigitalDocument: 'https://laleydigital-qa.laleynext.es/Content/Documento.aspx',
    eloquaformURL: 'https://wke-qa.wkcols.com/forms/uniform/form.php?product=111&utm_source=Producto Contractbox&utm_campaign=Visit',
    vimeoURL: 'https://player.vimeo.com/video/',
    LLDTemplatesTC: 'TC0000047195',
    clientId: 'CMC',
    maxFilesInFolderToExport : 100,
    myAccountLoginSamlUrl: 'https://miespacio-qa.externalnaw.es/Account/SamlLogin?idp={0}&platform=SANTANDER&returnUrl={1}',
    clientUrl: 'https://cmc-test.laleynext.es/es/home/login',
    showChatButton: true
};

