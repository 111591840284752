import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ISmartformParamsService } from 'src/app/core/shared/services/smartform/smartform-params/smartform-params.service.interface';
import { DocumentCreationType } from 'src/app/core/standard/enums/documents/document-creation-params.enum';
import { BehaviorSubject, Observable } from 'rxjs-compat';
import { IDocumentsNavigationService } from './documents-navigation.service.interface';
import { ModuleType } from 'src/app/core/shared/enums/module-type.enum';
import { DocumentStatusCodes, DocumentStatusValues } from 'src/app/core/standard/enums/documents/document-status.enum';
import { DocumentEditionTabs } from 'src/app/core/standard/enums/documents/document-edition-tabs.enum';
import { DocumentTabsList } from 'src/app/core/standard/enums/documents/document-tabs-list.enum';
import { DocumentListService } from '../document-list/document-list.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentsNavigationService implements IDocumentsNavigationService {

    private documentId = new BehaviorSubject<string>('');
    private templateId = new BehaviorSubject<string>('');
    private tocTemplateId = new BehaviorSubject<string>('');
    private parentDocumentId = new BehaviorSubject<string>('');
    private documentCreationType = new BehaviorSubject<DocumentCreationType>(DocumentCreationType.empty);
    private tabEdition = new BehaviorSubject<string>('');
    private originModule = new BehaviorSubject<ModuleType>(ModuleType.Documents);

    constructor(private router: Router,
        private smartformParamsService: ISmartformParamsService,
        private documentListService: DocumentListService) {
        this.clearOriginPageFromTemplate();
    }

    public getDocumentId(): Observable<string> {
        return this.documentId.asObservable();
    }

    public getTabInEdition(): Observable<string> {
        return this.tabEdition.asObservable();
    }

    public getTocTemplateId(): Observable<string> {
        return this.tocTemplateId.asObservable();
    }

    public setTocTemplateId(tocTemplateId: string): void {
        this.tocTemplateId.next(tocTemplateId);
    }

    public getParentDocumentId(): Observable<string> {
        return this.parentDocumentId.asObservable();
    }

    public getOriginModule(): Observable<ModuleType> {
        return this.originModule.asObservable();
    }

    public getTemplateId(): Observable<string> {
        return this.templateId.asObservable();
    }

    public getDocumentCreationType(): Observable<DocumentCreationType> {
        return this.documentCreationType.asObservable();
    }

    public goToCreateFromSmartform(tocTemplateId: string, originModule: ModuleType): void {
        this.smartformParamsService.setTocTemplateId(tocTemplateId);
        this.setOriginModule(originModule);
        this.navigateToCreateDocumentFromSmartForm(originModule);
    }

    public createDocumentFromGeneratedSmartform(): void {
        this.clearDocumentId();
        this.setDocumentCreationType(DocumentCreationType.fromSmartform);
        this.navigateToCreateDocument();
    }

    public createDocumentFromLldTemplate(tocTemplateId: string, originModule: ModuleType): void {
        this.clearDocumentId();
        this.setTocTemplateId(tocTemplateId);
        this.setDocumentCreationType(DocumentCreationType.fromLld);
        this.setOriginModule(originModule);
        this.navigateToCreateDocument();
    }

    public createDocumentFromSmartformDocument(tocDocumentId: string): void {
        this.smartformParamsService.setTocTemplateId(tocDocumentId);
        this.navigateToCreateDocumentFromSmartForm(ModuleType.Documents);
    }

    public goToDocumentEditionTab(documentId: string, tab: DocumentEditionTabs): void {
        this.setDocumentId(documentId);
        this.goToDocumentEditionInTab(tab);
    }

    public goToDocumentEditionInTab(tab: DocumentEditionTabs): void {
        this.setDocumentCreationType(DocumentCreationType.edit);
        this.router.navigate([`/main/contracts-library/edition-document/${tab}`]);
    }

    public goToCreateDocumentFromFile(): void {
        this.setDocumentCreationType(DocumentCreationType.fromFile);
        this.navigateToCreateDocument();
    }

    public goToCreateDocumentFromTemplate(templateId: string, originModule: ModuleType): void {
        this.configToCreateFromTemplate(templateId, originModule);
        this.navigateToCreateDocument();
    }

    public goToCreateAnnexedFromFile(parentDocumentId: string): void {
        this.clearAll();
        this.setDocumentCreationType(DocumentCreationType.fromFile);
        this.setParentDocumentId(parentDocumentId);
        this.navigateToCreateAnnexed();
    }

    public goToCreateEmptyDocument(): void {
        this.clearAll();
        this.setDocumentCreationType(DocumentCreationType.empty);
        this.navigateToCreateDocument();
    }

    public goToNewDocumentSelection(): void {
        this.router.navigate(['/main/contracts-library/create-new-document-selection']);
    }

    public goToFirstDocumentSelection(): void {
        this.router.navigate(['/main/contracts-library/create-first-document-selection']);
    }

    public goToCreateEmptyAnnexed(parentDocumentId: string): void {
        this.clearDocumentId();
        this.clearTocTemplateId();
        this.setParentDocumentId(parentDocumentId);
        this.navigateToCreateAnnexed();
    }

    public goToUploadFileToCreateAnnexed(parentDocumentId: string): void {
        this.setParentDocumentId(parentDocumentId);
        this.router.navigate(['/main/contracts-library/create-annexed-from-local-document']);
    }

    public goToUploadFileToCreateDocument(): void {
        this.router.navigate(['/main/contracts-library/create-document-from-local-document']);
    }

    public goToCreateDocumentNotEditable(): void {
        this.router.navigate(['/main/contracts-library/create-document-no-editable']);
    }

    public goToCreateAnnexedNotEditable(parentDocumentId: string): void {
        this.clearTocTemplateId();
        this.clearDocumentId();
        this.setParentDocumentId(parentDocumentId);
        this.router.navigate(['/main/contracts-library/create-annexed-no-editable']);
    }

    public goToMyTemplatesToC(): void {
        this.clearAll();
        this.router.navigate(['/main/contracts-library/create-document-from-template/my-templates']);
    }

    public goToSmartformsToC(): void {
        this.clearAll();
        this.router.navigate(['/main/contracts-library/create-document-from-template/templates-smart-forms']);
    }

    public goToLldToC(): void {
        this.clearAll();
        this.router.navigate(['/main/contracts-library/create-document-from-template/templates-laley']);
    }

    public goToDocumentsMain(): void {
        this.router.navigate(['/main/contracts-library/documents']);
    }

    public goToDocumentStateList(state: DocumentStatusCodes, tab?: DocumentTabsList): void {
        const sectiontoNavitate = this.getDocumentStateList(state);
        if (sectiontoNavitate === this.documentListService.all()) {
            this.goToDocumentsMain();
            return;
        }

        this.router.navigate(['/main/contracts-library/' + sectiontoNavitate.toLowerCase() + '-documents', { activeTab: tab }]);
    }

    public goToDocumentsSearch() {
        this.router.navigate(['/main/contracts-library/search-documents']);
    }

    public getDocumentStateList(state: DocumentStatusCodes): string {
        if (!state) {
            return this.documentListService.all();
        }
        return DocumentStatusValues[state];
    }

    private setDocumentCreationType(documentCreationType: DocumentCreationType): void {
        this.documentCreationType.next(documentCreationType);
    }

    private configToCreateFromTemplate(templateId: string, originModule: ModuleType): void {
        this.clearAll();
        this.setTemplateId(templateId);
        this.setOriginModule(originModule);
        this.setDocumentCreationType(DocumentCreationType.fromTemplate);
    }

    private navigateToCreateDocumentFromSmartForm(originModule: ModuleType): void {
        this.router.navigate(['/main/contracts-library/create-from-smart-form'], { state: { originModule } });
    }

    private clearAll(): void {
        this.clearDocumentId();
        this.clearTemplateId();
        this.clearParentDocumentId();
        this.clearTocTemplateId();
    }

    private navigateToCreateDocument(): void {
        this.clearDocumentId();
        this.router.navigate(['/main/contracts-library/create-document']);
    }

    private navigateToCreateAnnexed(): void {
        this.clearDocumentId();
        this.router.navigate(['/main/contracts-library/create-annexed']);
    }

    private clearDocumentId(): void {
        this.documentId.next('');
    }

    private clearTemplateId(): void {
        this.templateId.next('');
    }

    private clearParentDocumentId(): void {
        this.parentDocumentId.next('');
    }

    private clearTocTemplateId() {
        this.tocTemplateId.next('');
    }

    private setDocumentId(documentId: string): void {
        this.documentId.next(documentId);
    }

    private setParentDocumentId(parentDocumentId: string): void {
        this.parentDocumentId.next(parentDocumentId);
    }

    private setTemplateId(templateId: string): void {
        this.templateId.next(templateId);
    }

    private setOriginModule(originModule: ModuleType): void {
        return this.originModule.next(originModule);
    }

    private clearOriginPageFromTemplate(): void {
        this.originModule.next(ModuleType.Documents);
    }
}
