import { Injectable } from "@angular/core";
import { Schema } from "ckeditor5";
import { RadioPlugin } from "../../plugins/radio/radio-plugin";
import { ALLOW_CONTENT_OF_VALUES, INHERIT_ALL_FROM_VALUES } from "../schema-values";

@Injectable({
    providedIn: 'root'
})
export class RadioSchemaService {

    public defineSchema(schema: Schema): void {
        this.registerContainerModel(schema);
        this.registerOptionDataModel(schema);
        this.registerInputDataModel(schema);
        this.registerDescriptionDataModel(schema);
        this.registerContentDataModel(schema);
    }

    private registerContainerModel(schema: Schema) {
        schema.register(RadioPlugin.MODEL_ENTITIES.container.model, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.CONTAINER,
            allowAttributes: ["id", RadioPlugin.MODEL_ENTITIES.optionCount.model],
            allowContentOf: ALLOW_CONTENT_OF_VALUES.ROOT,
            isObject: true
        });
    }

    private registerOptionDataModel(schema: Schema) {
        schema.register(RadioPlugin.MODEL_ENTITIES.option.model, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.CONTAINER,
            allowAttributes: [RadioPlugin.MODEL_ENTITIES.optionPosition.model],
            allowContentOf: ALLOW_CONTENT_OF_VALUES.ROOT,
            allowIn: [RadioPlugin.MODEL_ENTITIES.container.model]
        });
    }

    private registerInputDataModel(schema: Schema) {
        schema.register(RadioPlugin.MODEL_ENTITIES.input.model, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.BLOCK_OBJECT,
            allowAttributes: [RadioPlugin.MODEL_ENTITIES.groupName.model, RadioPlugin.ATTRIBUTE_CHECKED],
            allowContentOf: ALLOW_CONTENT_OF_VALUES.TEXT,
            allowIn: [RadioPlugin.MODEL_ENTITIES.option.model],
            disallowChildren: [RadioPlugin.MODEL_ENTITIES.container.model]
        });
    }

    private registerDescriptionDataModel(schema: Schema) {
        schema.register(RadioPlugin.MODEL_ENTITIES.description.model, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.BLOCK_OBJECT,
            allowContentOf: ALLOW_CONTENT_OF_VALUES.ROOT,
            allowIn: [RadioPlugin.MODEL_ENTITIES.option.model],
            disallowChildren: ['imageInline', 'imageBlock', 'table']
        });
    }

    private registerContentDataModel(schema: Schema) {
        schema.register(RadioPlugin.MODEL_ENTITIES.content.model, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.BLOCK_OBJECT,
            allowContentOf: ALLOW_CONTENT_OF_VALUES.ROOT,
        });
    }
}
