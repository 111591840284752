import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat';
import { DocumentFolderDto } from 'src/app/api';
import { FileNode } from 'src/app/shared/components/ctbox-tree/models/file-node.model';
import { NodeTreeActionType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-action-type.enum';

@Injectable({
    providedIn: 'root'
})

export abstract class IDocumentsFolderTreeService {
    abstract getMyDocumentsNodeId(): string;
    abstract getMyDocumentsNodeName(): string;

    abstract getOurDocumentsNodeId(): string;
    abstract getOurDocumentsNodeName(): string;

    abstract getSharedNodeId(): string;
    abstract getSharedNodeName(): string;

    abstract setCurrentExpansionModel(selectionModel: SelectionModel<string>): void;
    abstract getCurrentExpansionModel(): Observable<SelectionModel<string>>;

    abstract createMyDocumentsNode(myDocuments: DocumentFolderDto[], rootNodeActions: NodeTreeActionType[],
                                   nodeActions: NodeTreeActionType[], userId: string): FileNode;
    abstract createOurDocumentsNode(ourDocuments: DocumentFolderDto[], rootNodeActions: NodeTreeActionType[],
                                    nodeActions: NodeTreeActionType[]): FileNode;
    abstract createCompleteTree(myDocuments: FileNode, ourDocuments: FileNode, sharedNodeActions: NodeTreeActionType[]): FileNode[];
}
