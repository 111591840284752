import { LegalAlertsCMCService } from './legalAlertsCMC.service';
import { AdministratorTemplatesFoldersService } from './administrator-templates-folders.service';
export * from './administrator-templates-folders.service';
export * from './api-processor.service';
import { ApiProcessorService } from './api-processor.service';
export * from './annexed.service';
import { AnnexedService } from './annexed.service';
export * from './clause.service';
import { ClauseService } from './clause.service';
export * from './clauseAppliedRegulation.service';
import { ClauseAppliedRegulationService } from './clauseAppliedRegulation.service';
export * from './clauseFolder.service';
import { ClauseFolderService } from './clauseFolder.service';
export * from './clausesWK.service';
import { ClausesWKService } from './clausesWK.service';
export * from './contract.service';
import { ContractService } from './contract.service';
export * from './contractShared.service';
import { ContractSharedService } from './contractShared.service';
export * from './contractVersion.service';
import { ContractVersionService } from './contractVersion.service';
export * from './dashboard.service';
import { DashboardService } from './dashboard.service';
export * from './document.service';
import { DocumentService } from './document.service';
export * from './documentAdvanceNoticeExtensionTypes.service';
import { DocumentAdvanceNoticeExtensionTypesService } from './documentAdvanceNoticeExtensionTypes.service';
export * from './documentClause.service';
import { DocumentClauseService } from './documentClause.service';
export * from './documentDurationAdvanceNoticeTypes.service';
import { DocumentDurationAdvanceNoticeTypesService } from './documentDurationAdvanceNoticeTypes.service';
export * from './documentDurationTypes.service';
import { DocumentDurationTypesService } from './documentDurationTypes.service';
export * from './documentExpirations.service';
import { DocumentExpirationsService } from './documentExpirations.service';
export * from './documentExtensionPeriodTypes.service';
import { DocumentExtensionPeriodTypesService } from './documentExtensionPeriodTypes.service';
export * from './documentFolder.service';
import { DocumentFolderService } from './documentFolder.service';
export * from './documentFolderLocked.service';
import { DocumentFolderLockedService } from './documentFolderLocked.service';
export * from './documentShared.service';
import { DocumentSharedService } from './documentShared.service';
export * from './documentSignature.service';
import { DocumentSignatureService } from './documentSignature.service';
export * from './documentStatus.service';
import { DocumentStatusService } from './documentStatus.service';
export * from './documentType.service';
import { DocumentTypeService } from './documentType.service';
export * from './documentWorkflow.service';
import { DocumentWorkflowService } from './documentWorkflow.service';
export * from './documents.service';
import { DocumentsService } from './documents.service';
export * from './error.service';
import { ErrorService } from './error.service';
export * from './expirationNotices.service';
import { ExpirationNoticesService } from './expirationNotices.service';
export * from './expirations.service';
import { ExpirationsService } from './expirations.service';
export * from './fOWKModifiedNotifyUser.service';
import { FOWKModifiedNotifyUserService } from './fOWKModifiedNotifyUser.service';
export * from './htmlToWmlConverter.service';
import { HtmlToWmlConverterService } from './htmlToWmlConverter.service';
export * from './inForceTypes.service';
import { InForceTypesService } from './inForceTypes.service';
export * from './lastModificationFolders.service';
import { LastModificationFoldersService } from './lastModificationFolders.service';
export * from './notification.service';
import { NotificationService } from './notification.service';
export * from './originTemplateVersion.service';
import { OriginTemplateVersionService } from './originTemplateVersion.service';
export * from './permissionDocumentSharedType.service';
import { PermissionDocumentSharedTypeService } from './permissionDocumentSharedType.service';
export * from './permissionUser.service';
import { PermissionUserService } from './permissionUser.service';
export * from './signatureNotify.service';
import { SignatureNotifyService } from './signatureNotify.service';
export * from './signatureUser.service';
import { SignatureUserService } from './signatureUser.service';
export * from './smartform.service';
import { SmartformService } from './smartform.service';
export * from './tableOfContents.service';
import { TableOfContentsService } from './tableOfContents.service';
export * from './inquireTemplatesFolders.service';
import { InquireTemplatesFoldersService } from './inquireTemplatesFolders.service';
export * from './templateAppliedRegulation.service';
import { TemplateAppliedRegulationService } from './templateAppliedRegulation.service';
export * from './template.service';
import { TemplateService } from './template.service';
export * from './templateDocument.service';
import { TemplateDocumentService } from './templateDocument.service';
export * from './templateDownload.service';
import { TemplateDownloadService } from './templateDownload.service';
export * from './templateFolder.service';
import { TemplateFolderService } from './templateFolder.service';
export * from './templateWorkflow.service';
import { TemplateWorkflowService } from './templateWorkflow.service';
export * from './test.service';
import { TestService } from './test.service';
export * from './timeUnitTypes.service';
import { TimeUnitTypesService } from './timeUnitTypes.service';
export * from './trazability.service';
import { TrazabilityService } from './trazability.service';
export * from './userInfo.service';
import { UserInfoService } from './userInfo.service';
export * from './users.service';
import { UsersService } from './users.service';
export * from './wmlToHtmlConverter.service';
import { WmlToHtmlConverterService } from './wmlToHtmlConverter.service';
export * from './workflowPermissionTypes.service';
import { WorkflowPermissionTypesService } from './workflowPermissionTypes.service';
export * from './workflowStatus.service';
import { WorkflowStatusService } from './workflowStatus.service';
export * from './workflowTypes.service';
import { WorkflowTypesService } from './workflowTypes.service';
import { PermissionUserCMCService } from './permissionUserCMC.service';
import { ManagerTemplatesService } from './managerTemplate.service';
export * from './managerTemplate.service';
import { ManagerTemplateVersionService } from './managerTemplateVersion.service';
export * from './managerTemplateVersion.service';
import { ManagerTemplatesFoldersService } from './managerTemplatesFolders.service';
export * from './managerTemplatesFolders.service';
import { ManagerLockedTemplatesService } from './managerLockedTemplates.service';
export * from './managerLockedTemplates.service';
import { ManagerLockedClausesService } from './managerLockedClauses.service';
export * from './managerLockedClauses.service';
import { TemplateFolderSearchService } from './templateFolderSearch.service';
export * from './templateFolderSearch.service';
import { ClauseFolderSearchService } from './clauseFolderSearch.service';
export * from './templateFolderSearch.service';
import { TemplateSearchService } from './templateSearch.service';
export * from './templateFolderSearch.service';
import { ClauseSearchService } from './clauseSearch.service';
import { ManagerTemplatesDownloadService } from './managerTemplatesDownload.service';
export * from './clauseFolderSearch.service';
import { AuthenticationService } from './authentication.service';
import { TemplateAutomaticLegalAlertsService } from './templateAutomaticLegalAlerts.service';
import { TemplateUserLegalAlertsService } from './templateUserLegalAlerts.service';
import { LegalAlertsService } from './legalAlerts.service';
import { LegalAlertsContentCMCService } from './legalAlertsContentCMC.service';
import { LegalAlertsContentService } from './legalAlertsContent.service';
export * from './authentication.service';

export const APIS = [AdministratorTemplatesFoldersService, ApiProcessorService, AnnexedService, AuthenticationService,
    ClauseService, ClauseAppliedRegulationService, ClauseFolderSearchService,
    ClauseFolderService, ClauseSearchService, ClausesWKService, ContractService, ContractSharedService, ContractVersionService,
    DashboardService, DocumentService, DocumentAdvanceNoticeExtensionTypesService, DocumentClauseService,
    DocumentDurationAdvanceNoticeTypesService, DocumentDurationTypesService, DocumentExpirationsService,
    DocumentExtensionPeriodTypesService, DocumentFolderService, DocumentFolderLockedService, DocumentSharedService,
    DocumentSignatureService, DocumentStatusService, DocumentTypeService, DocumentWorkflowService, DocumentsService,
    ErrorService, ExpirationNoticesService, ExpirationsService, FOWKModifiedNotifyUserService, HtmlToWmlConverterService,
    InForceTypesService, LastModificationFoldersService, LegalAlertsCMCService, LegalAlertsService, LegalAlertsContentCMCService, LegalAlertsContentService,
    ManagerTemplatesService, ManagerTemplatesFoldersService, ManagerLockedTemplatesService, NotificationService, OriginTemplateVersionService,
    PermissionDocumentSharedTypeService, PermissionUserService, SignatureNotifyService, PermissionUserCMCService,
    SignatureUserService, SmartformService, TableOfContentsService, TemplateAppliedRegulationService,
    TemplateFolderSearchService, TemplateSearchService, TemplateService, TemplateDocumentService,
    TemplateDownloadService, InquireTemplatesFoldersService, TemplateFolderService, TemplateWorkflowService,
    TestService, TimeUnitTypesService, TrazabilityService, UserInfoService, UsersService, ManagerTemplateVersionService,
    WmlToHtmlConverterService, WorkflowPermissionTypesService, WorkflowStatusService, WorkflowTypesService, ManagerLockedClausesService,
    ManagerTemplatesDownloadService, TemplateAutomaticLegalAlertsService, TemplateUserLegalAlertsService];
