import { Injectable } from '@angular/core';
import {
    TemplateDTO, UserDTO, UserPermissionsOnClauseDTO, UserPermissionsOnDocumentDTO, WarningChangesDTO
} from 'src/app/api/model/models';

@Injectable({
    providedIn: 'root'
})
export abstract class ITemplateListService {
    abstract getAuthorSurnameToOrder(surname: UserDTO): string;
    abstract showTemplatesWarnings(userPermissions: UserPermissionsOnDocumentDTO, warningChanges: WarningChangesDTO,
                                   showCanEditWarning: boolean): boolean;
    abstract getActionToOrder(userPermissions: UserPermissionsOnClauseDTO): number;
    abstract getAlertsToOrder(template: TemplateDTO): number;
}
