import { Injectable } from '@angular/core';
import { ClauseFolderDTO } from 'src/app/api';

@Injectable()
export class ClauseFolderStorage {
    private folders: ClauseFolderDTO[];

    public setFolders(folders: ClauseFolderDTO[]): void {
        this.folders = folders;
    }

    public getClauseFolders(): ClauseFolderDTO[] {
        return this.folders;
    }
}
