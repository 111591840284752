import { Directive, Inject, OnDestroy } from '@angular/core';
import {
    View, LabeledFieldView, ButtonView, submitHandler, ViewCollection, FocusableView, Locale, FocusTracker, FocusCycler, KeystrokeHandler
} from 'ckeditor5';

@Directive()
export default class BaseView extends View implements OnDestroy {

    public readonly keystrokes = new KeystrokeHandler();
    public readonly focusTracker = new FocusTracker();
    protected items: ViewCollection;

    protected readonly focusables = new ViewCollection<FocusableView>();
    protected readonly focusCycler: FocusCycler;

    protected readonly submitButtonMessage = $localize`:@@AceptarCambiosRadioPlugin:Aceptar`;
    protected readonly cancelButtonMessage = $localize`:@@CancelarCambiosRadioPlugin:Cancelar`;

    constructor(
        @Inject(Locale) locale?: Locale,
    ) {
        super(locale);
        this.focusCycler = this.createFocusCycler();
    }

    public ngOnDestroy(): void {
        super.destroy();

        this.focusTracker.destroy();
        this.keystrokes.destroy();
    }

    public override render() {
        super.render();

        submitHandler({
            view: this
        });

        this.items.forEach(v => {
            if (v instanceof LabeledFieldView || v instanceof ButtonView) {
                this.focusables.add(v!);
                this.focusTracker.add(v.element!);
            }
        });

        this.keystrokes.listenTo(this.element!);
    }

    public focus() {
        this.focusCycler.focusFirst();
    }

    protected setTemplateForm(aditionalClass?: string): void {
        const classes: string[] = ['ck'];
        if(!!aditionalClass) {
            classes.push(aditionalClass);
        }
        this.setTemplate({
            tag: "form",
            attributes: {
                class: classes,
                tabindex: "-1",
            },
            children: this.items
        });
    }

    protected createFocusCycler(): FocusCycler {
        return  new FocusCycler({
            focusables: this.focusables,
            focusTracker: this.focusTracker,
            keystrokeHandler: this.keystrokes,
            actions: {
                focusPrevious: "shift + tab",
                focusNext: "tab",
            },
        });
    }

    protected createButton(label: string, className?: string, icon?: string, withText?: boolean): ButtonView {
        const button = new ButtonView();

        button.set({
            label,
            icon,
            tooltip: true,
            class: className,
            withText: !icon || withText
        });

        return button;
    }
}
