import { TemplateFolderSearchService } from './api/templateFolderSearch.service';
import { PermissionUserCMCService } from 'src/app/api/api/permissionUserCMC.service';
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';
import { ApiProcessorService } from './api/api-processor.service';
import { AnnexedService } from './api/annexed.service';
import { ClauseService } from './api/clause.service';
import { ClauseFolderService } from './api/clauseFolder.service';
import { ClausesWKService } from './api/clausesWK.service';
import { ContractService } from './api/contract.service';
import { ContractSharedService } from './api/contractShared.service';
import { ContractVersionService } from './api/contractVersion.service';
import { DashboardService } from './api/dashboard.service';
import { DocumentService } from './api/document.service';
import { DocumentAdvanceNoticeExtensionTypesService } from './api/documentAdvanceNoticeExtensionTypes.service';
import { DocumentClauseService } from './api/documentClause.service';
import { DocumentDurationAdvanceNoticeTypesService } from './api/documentDurationAdvanceNoticeTypes.service';
import { DocumentDurationTypesService } from './api/documentDurationTypes.service';
import { DocumentExpirationsService } from './api/documentExpirations.service';
import { DocumentExtensionPeriodTypesService } from './api/documentExtensionPeriodTypes.service';
import { DocumentFolderService } from './api/documentFolder.service';
import { DocumentFolderLockedService } from './api/documentFolderLocked.service';
import { DocumentSharedService } from './api/documentShared.service';
import { DocumentSignatureService } from './api/documentSignature.service';
import { DocumentStatusService } from './api/documentStatus.service';
import { DocumentTypeService } from './api/documentType.service';
import { DocumentWorkflowService } from './api/documentWorkflow.service';
import { DocumentsService } from './api/documents.service';
import { ErrorService } from './api/error.service';
import { ExpirationNoticesService } from './api/expirationNotices.service';
import { ExpirationsService } from './api/expirations.service';
import { FOWKModifiedNotifyUserService } from './api/fOWKModifiedNotifyUser.service';
import { HtmlToWmlConverterService } from './api/htmlToWmlConverter.service';
import { InForceTypesService } from './api/inForceTypes.service';
import { LastModificationFoldersService } from './api/lastModificationFolders.service';
import { NotificationService } from './api/notification.service';
import { OriginTemplateVersionService } from './api/originTemplateVersion.service';
import { PermissionDocumentSharedTypeService } from './api/permissionDocumentSharedType.service';
import { PermissionUserService } from './api/permissionUser.service';
import { SignatureNotifyService } from './api/signatureNotify.service';
import { SignatureUserService } from './api/signatureUser.service';
import { SmartformService } from './api/smartform.service';
import { TableOfContentsService } from './api/tableOfContents.service';
import { TemplateService } from './api/template.service';
import { TemplateDocumentService } from './api/templateDocument.service';
import { TemplateDownloadService } from './api/templateDownload.service';
import { TemplateFolderService } from './api/templateFolder.service';
import { TemplateWorkflowService } from './api/templateWorkflow.service';
import { TestService } from './api/test.service';
import { TimeUnitTypesService } from './api/timeUnitTypes.service';
import { TrazabilityService } from './api/trazability.service';
import { UserInfoService } from './api/userInfo.service';
import { UsersService } from './api/users.service';
import { WmlToHtmlConverterService } from './api/wmlToHtmlConverter.service';
import { WorkflowPermissionTypesService } from './api/workflowPermissionTypes.service';
import { WorkflowStatusService } from './api/workflowStatus.service';
import { WorkflowTypesService } from './api/workflowTypes.service';
import { TemplateSearchService } from './api/templateSearch.service';
import { InquireTemplatesFoldersService } from './api/inquireTemplatesFolders.service';
import { ManagerTemplatesFoldersService } from './api/managerTemplatesFolders.service';
import { ManagerTemplateVersionService } from './api/managerTemplateVersion.service';
import { ManagerTemplatesService } from './api/managerTemplate.service';
import { AdministratorTemplatesFoldersService } from './api/administrator-templates-folders.service';
import { TemplateInquireService } from './api/templateInquire.service';
import { ManagerLockedTemplatesService } from './api/managerLockedTemplates.service';
import { TemplateAppliedRegulationService } from './api/templateAppliedRegulation.service';
import { TemplateFolderStorage } from '../core/shared/services/templates/templates-folder-storage/template-folder-storage';
import { ManagerClausesFoldersService } from './api/managerClausesFolders.service';
import { ClauseFolderStorage } from '../core/shared/services/clauses/clause-folder-storage/clause-folder-storage';
import { ManagerClauseService } from './api/managerClause.service';
import { RequestQueryParamsService } from './api/requestQueryParams.service';
import { ExportFormatsService } from './api/exportFormats.service';
import { TemplateSheetService } from './api/templateSheet.service';
import { ManagerLockedClausesService } from './api/managerLockedClauses.service';
import { ClauseVersionService } from './api/clauseVersion.service';
import { ClauseFolderSearchService } from './api/clauseFolderSearch.service';
import { ManagerTemplatesDownloadService } from './api/managerTemplatesDownload.service';
import { ReportsService } from './api/reports.service';
import { InquireTemplatesService } from './api/inquireTemplates.service';
import { LegalAlertsCMCService } from './api/legalAlertsCMC.service';
import { DocumentsConverterService } from './api/documentsConverter.service';
import { ClauseSearchService } from './api/clauseSearch.service';
import { AuthenticationService } from './api/authentication.service';
import { ManagerClausesDownloadService } from './api/managerClausesDownload.service';
import { ClauseAppliedRegulationService } from './api/clauseAppliedRegulation.service';
import { TemplateUserLegalAlertsService } from './api/templateUserLegalAlerts.service';
import { TemplateAutomaticLegalAlertsService } from './api/templateAutomaticLegalAlerts.service';
import { LegalAlertsService } from './api/legalAlerts.service';
import { ClausesService } from './api/clauses.service';
import { LegalAlertsContentService } from './api/legalAlertsContent.service';
import { LegalAlertsContentCMCService } from './api/legalAlertsContentCMC.service';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [
        AdministratorTemplatesFoldersService,
        ApiProcessorService,
        AnnexedService,
        AuthenticationService,
        ClauseAppliedRegulationService,
        ClauseFolderSearchService,
        ClauseFolderService,
        ClauseFolderStorage,
        ClauseSearchService,
        ClauseService,
        ClausesService,
        ClausesWKService,
        ClauseVersionService,
        ContractService,
        ContractSharedService,
        ContractVersionService,
        DashboardService,
        DocumentService,
        DocumentAdvanceNoticeExtensionTypesService,
        DocumentClauseService,
        DocumentDurationAdvanceNoticeTypesService,
        DocumentDurationTypesService,
        DocumentExpirationsService,
        DocumentExtensionPeriodTypesService,
        DocumentFolderService,
        DocumentFolderLockedService,
        DocumentSharedService,
        DocumentSignatureService,
        DocumentStatusService,
        DocumentTypeService,
        DocumentWorkflowService,
        DocumentsService,
        DocumentsConverterService,
        ErrorService,
        ExpirationNoticesService,
        ExpirationsService,
        FOWKModifiedNotifyUserService,
        HtmlToWmlConverterService,
        InForceTypesService,
        InquireTemplatesService,
        InquireTemplatesFoldersService,
        TemplateAppliedRegulationService,
        LastModificationFoldersService,
        LegalAlertsCMCService,
        LegalAlertsService,
        LegalAlertsContentCMCService,
        LegalAlertsContentService,
        ManagerLockedTemplatesService,
        ManagerLockedClausesService,
        ManagerTemplatesService,
        ManagerTemplatesFoldersService,
        ManagerTemplatesDownloadService,
        ManagerClauseService,
        ManagerClausesFoldersService,
        ManagerClausesDownloadService,
        ReportsService,
        RequestQueryParamsService,
        NotificationService,
        OriginTemplateVersionService,
        PermissionDocumentSharedTypeService,
        PermissionUserService,
        PermissionUserCMCService,
        SignatureNotifyService,
        SignatureUserService,
        SmartformService,
        TableOfContentsService,
        TemplateService,
        TemplateInquireService,
        TemplateDocumentService,
        TemplateDownloadService,
        TemplateFolderService,
        TemplateFolderSearchService,
        TemplateSearchService,
        TemplateWorkflowService,
        TemplateSheetService,
        TestService,
        ExportFormatsService,
        TemplateFolderStorage,
        TimeUnitTypesService,
        TrazabilityService,
        UserInfoService,
        UsersService,
        ManagerTemplateVersionService,
        WmlToHtmlConverterService,
        WorkflowPermissionTypesService,
        WorkflowStatusService,
        WorkflowTypesService,
        TemplateAutomaticLegalAlertsService,
        TemplateUserLegalAlertsService]
})
export class ApiModule {

    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [{ provide: Configuration, useFactory: configurationFactory }]
        };
    }

    constructor(
        @Optional() @SkipSelf() parentModule: ApiModule,
        @Optional() http: HttpClient
    ) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
                'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
