import { Observable } from 'rxjs-compat';
import { ClauseFolderDTOOperation, FolderItemInformationDto } from 'src/app/api';
import { ClauseFolderDto } from 'src/app/api/model/cmc/clauseFolderDto';

export abstract class IClausesFolderService {

    abstract setCurrentFolderName(folderName: string): void;
    abstract getCurrentFolderName(): string;
    abstract getCorrectedFolderName(originalFolderName: string): string;

    abstract setCurrentFolderId(folderId: string): void;
    abstract getCurrentFolderId(): Observable<string>;

    abstract getOperationReplaceClauseFolder(valueToChange: string, newValue: any ): ClauseFolderDTOOperation[];

    abstract isCurrentFolderValid(nodeId: string);
    abstract getFileIdsInFolderRecusively(folderId: string, folders: ClauseFolderDto[]): FolderItemInformationDto[];

}
