import { Component, Inject, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { invalidCharactersValidator } from 'src/app/core/shared/validators/invalidCharactersValidator';
import { valueNotInGroupValidator } from 'src/app/core/shared/validators/value-not-in-group-validator';
import { noOnlyWhiteSpacesValidator } from 'src/app/core/shared/validators/noWhiteSpacesValidator';
import { SignatureModel } from './models/signature-model';
import { Subscription } from 'rxjs/Subscription';
import { BehaviorSubject } from 'rxjs-compat';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-signature-configuration',
    templateUrl: './signature-configuration.component.html'
})
export class SignatureConfigurationComponent implements OnInit, OnDestroy {

    @Input() defaultRole: string;
    @Input() rolCanBeRepeated: string;
    @Input() currentRoles: string[];
    @Input() signatureId: string;

    public signatureForm: UntypedFormGroup;
    public signature: SignatureModel = { id: '', role: '' };

    public isValidSubscription = new BehaviorSubject<boolean>(false);
    public doSaveVersionObserver = new BehaviorSubject<boolean>(false);

    private nameChangeSubscription: Subscription;

    constructor(@Inject(MAT_DIALOG_DATA) public data) {
        this.defaultRole = data.defaultRole;
        this.rolCanBeRepeated = data.rolCanBeRepeated;
        this.currentRoles = data.currentRoles;
        this.signatureId = data.signatureId;
    }

    public ngOnInit(): void {

        this.signature.role = this.defaultRole;
        if (this.signatureId) {
            this.signature.id = this.signatureId;
        }
        this.initFormModel();
    }

    public ngOnDestroy(): void {
        if (this.nameChangeSubscription) {
            this.nameChangeSubscription.unsubscribe();
        }
    }

    public isValidRole() {

        if (!this.signatureForm.controls.role.valid || this.signatureForm.get('role').hasError('valueNotInGroup') ||
            this.signatureForm.get('role').hasError('invalidCharacters') || this.signatureForm.get('role').hasError('maxlength')) {

            this.isValidSubscription.next(false);
        } else {
            this.isValidSubscription.next(true);
        }

    }

    public initFormModel() {
        this.signatureForm = new UntypedFormGroup({
            role: new UntypedFormControl(null,
                [Validators.required, Validators.maxLength(50), invalidCharactersValidator, noOnlyWhiteSpacesValidator,
                valueNotInGroupValidator(this.currentRoles, this.rolCanBeRepeated)])
        });

        this.nameChangeSubscription = this.signatureForm.controls.role.valueChanges.subscribe((signature: SignatureModel) => {
            signature = { id: this.signatureId, role: this.signatureForm.controls.role.value }
            this.isValidRole();
        });
    }

}
