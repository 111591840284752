import { AbstractControl } from '@angular/forms';

export function emptyNoWhiteSpacesValidator(control: AbstractControl) {
    const textInControl = control.value as string;
    if (textInControl == null || textInControl.length === 0) {
        return null;
    }
    const hasOnlyWhiteSpaces = /^\s*$/.test(textInControl);

    return hasOnlyWhiteSpaces ? { onlyWhitespaces: { value: control.value } } : null;
}
