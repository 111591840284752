import { OptionalFragmentCheckPlugin } from './../../plugins/optional-fragment-check/optional-fragment-check-plugin';

import { Writer } from 'ckeditor5';
import { OptionalFragmentCommand } from './optional-fragment-command';

export default class AddOptionalFragmentCommand extends OptionalFragmentCommand {

    private readonly helpTextOptionMessage = $localize`:@@TextoAyudaFragmentoOpcionalPlugin:(Chequea si deseas incluir este fragmento)`;

      public override execute(value: number) {
        const editor = this.editor;

        editor.model.change((writer: Writer) => {

            const guid = this.generarUUID();
            const id = `id-${guid}`;
            const name = id;
            const container = writer.createElement(OptionalFragmentCheckPlugin.labelModel, {
                 id,
                 name,
                 checked: false
            });

            const check = writer.createElement(OptionalFragmentCheckPlugin.labelCheckModel, {
                id,
                checked: false
            });

            const description = writer.createElement(OptionalFragmentCheckPlugin.labelDescriptionModel, {
            });

            const content = writer.createElement(OptionalFragmentCheckPlugin.labelContentModel, {

            });

            const descriptionDefaultText = writer.createText(`${this.helpTextOptionMessage}`);
            const descriptionDefaultTextElement = writer.createElement('paragraph');

            const contentDefaultText = writer.createText(``);
            const contentDefaultTextElement = writer.createElement('paragraph');

            writer.append(descriptionDefaultText, descriptionDefaultTextElement);
            writer.append(descriptionDefaultTextElement, description);

            writer.append(contentDefaultText, contentDefaultTextElement);
            writer.append(contentDefaultTextElement, content);

            writer.append(check, container);
            writer.append(description, container);
            writer.append(content, container);


            editor.model.insertObject(container, null, null, { setSelection: 'after' });
        });
    }

    private generarUUID(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}
